(() => {
    'use strict';
    angular
        .module('App')
        .directive('landingPageContent', () => {
            return {
                restrict: 'E',
                template: (el, attr) => {
                    const isList = attr.isListView === "true";
                    return require('./Templates/landingpage-content' + (isList ? '-list' : '') + '.tpl.html');
                },
                controller: ['$scope', '$element', '$attrs', 'Page', '$http', 'TimeLocale', '$rootScope', '$state', landingPageContentController]
            };
        });

    function landingPageContentController($scope, $element, $attrs, PageService, $http, TimeLocale, $rootScope, $state) {
        // Init
        InitDirective();

        function InitDirective() {
            $scope.loading = true;
            $scope.headline = '';
            $scope.contentData = {};
            $scope.limit = 5;
            $scope.Time = TimeLocale;
            $scope.swiperParams = {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            };

            // Get item limit/count
            if ($scope.tile.Settings && $scope.tile.Settings.ArticleCount) {
                var articleCount = $scope.tile.Settings.ArticleCount;
                if (articleCount > 0) {
                    $scope.limit = articleCount;
                }
            }

            // Load Posts
            LoadPosts();
        }

        // Methods
        function LoadPosts() {
            if ($scope.tile.AppLink?.PathToken?.length) {
                const showBadges = !$scope.tile.HideBadge;

                $http.get(`/contentModule/GetLiveTileData/${$scope.tile.AppLink.PathToken}?limit=${$scope.limit}&showBadges=${showBadges}`)
                    .then(({data}) => {
                        $scope.contentData = data;
                        $scope.headline = data.PageTitle;
                        $scope.settings = data.Settings;

                        $scope.contentData.Items = data.Items.map(item => ({
                            ...item,
                            _currentCulture: getCurrentCulture(item.TranslatableEntity)
                        }));
                        $scope.allowCreation = data.AllowCreation;
                        $scope.loading = false;

                        if ($scope.contentData.Categories && $scope.contentData.Categories.length) {
                            $scope.contentData.Categories[0].selected = true;
                            $scope.contentData.Categories = $scope.contentData.Categories.map(category => ({
                                ...category,
                                _currentCulture: getCurrentCulture(category.TranslatableEntity)
                            }))
                            $scope.selectedCategoryToken = $scope.contentData.Categories[0].ContentCategoryToken;
                        }
                    })
                    .catch(() => {
                        $scope.loading = false
                    });
            } else {
                $scope.loading = false;
            }
        }

        function getCurrentCulture(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function getTransition() {
            var trans = null;

            if (!$rootScope.IsFrontPage) {
                trans = new StateTransitionClass($state.current.name, $state.params.token, $state.params.extra);
            }

            return trans;
        }

        function ShowContent(contentToken) {
            const tile = $scope.tile;

            if (contentToken) {
                PageService.stateGo('contentItem', contentToken, `?AccountModuleToken=${$scope.contentData.AccountModuleToken}`);
            } else {
                PageService.stateGo('content', tile.AppLink.PathToken, $scope.selectedCategoryToken ?? tile.AppLink.PathExtra);
            }
        }

        function OpenCreatePost() {
            const tile = $scope.tile,
                state = tile.AppLink.PathExtra?.length ? 'content' : 'contentOverview';
            PageService.stateGo(state, tile.AppLink.PathToken, tile.AppLink.PathExtra,{
                openCreatePopup: true
            });
        }

        function selectCategory(category) {
            $scope.contentData.Categories.find(category => {
                return category.selected === true
            }).selected = false;
            category.selected = true
            $scope.selectedCategoryToken = category.ContentCategoryToken;
            $scope.categoryLoading = true;
            $http.post('/ContentModule/GetContents', {
                token: $scope.tile.AppLink.PathToken,
                categoryToken: category.ContentCategoryToken,
                offset: 0,
                limit: 20
            }).then(resp => {
                $scope.contentData.NextOffset = resp.data.NextOffset;
                $scope.contentData.Items = resp.data.Items.map(item => ({
                    ...item,
                    _currentCulture: getCurrentCulture(item.TranslatableEntity)
                }));
            }).finally(() => $scope.categoryLoading = false);
        }

        function isNewArticle(item) {
            if (!$scope.settings.ShowUnreadLabel) {
                return false;
            }

            if ($scope.settings.SwipeToRead && !$scope.settings.SeenByEnabled) {
                return !item.IsSwiped
            } else if (!$scope.settings.SwipeToRead && $scope.settings.SeenByEnabled) {
                return !item.IsSeen
            } else if ($scope.settings.SwipeToRead && $scope.settings.SeenByEnabled) {
                return !item.IsSwiped
            } else {
                return false
            }
        }

        // Bind scope
        $scope.showContent = ShowContent;
        $scope.openCreatePost = OpenCreatePost;
        $scope.selectCategory = selectCategory;
        $scope.isNewArticle = isNewArticle;
    }
})();