(() => {
    angular.module('App')
        .component('userMention', {
            template: `<ng-transclude ng-click="$ctrl.openProfile()"></ng-transclude>`,
            transclude: true,
            bindings: {
                token: '@'
            },
            controller: ['ProfileViewerService', UserMentionController]
        })
        .component('departmentMention', {
            template: `<ng-transclude ng-click="$ctrl.openDepartment()"></ng-transclude>`,
            transclude: true,
            bindings: {
                token: '@'
            },
            controller: ['DepartmentViewerService', DepartmentMentionController]
        })
        .component('hashTag', {
            template: `<ng-transclude ng-click="$ctrl.openHashTag()"></ng-transclude>`,
            transclude: true,
            bindings: {
                token: '@'
            },
            controller: ['$stateParams', '$transclude', 'Page', HashTagController]
        })

    function UserMentionController(ProfileViewerService) {
        const ctrl = this;

        ctrl.openProfile = () => {
            if (ctrl.token) {
                ProfileViewerService.showProfileCard(ctrl.token);
            }
        }
    }

    function DepartmentMentionController(DepartmentViewerService) {
        const ctrl = this;

        ctrl.openDepartment = () => {
            if (ctrl.token) {
                DepartmentViewerService.showDepartmentCard(ctrl.token);
            }
        }
    }

    function HashTagController($stateParams, $transclude, Page) {
        const ctrl = this;

        ctrl.openHashTag = openHashTag;

        function openHashTag() {
            $transclude((transclude, scope) => {
                Page.stateGo(
                    'socialHashtags',
                    $stateParams.token,
                    transclude.text().replace('#', ''));
            })
        }
    }
})();