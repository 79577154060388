(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('childLanding', {
                        parent: 'base',
                        url: '/childLanding/:token',
                        template: require('./ChildLanding.html'),
                        params: {
                            backState: null,
                            data: null,
                        },
                        resolve: {
                            landingPageToken: ['$q', '$stateParams', function ($q, $stateParams) {
                                const deferred = $q.defer();

                                if ($stateParams.token) {
                                    deferred.resolve($stateParams.token);
                                } else {
                                    deferred.reject();
                                }

                                return deferred.promise;
                            }]
                        },
                        controller: 'ChildLandingController as ctrl'
                    })
                    .state('landingpage', {
                        parent: 'base',
                        url: '/landingpage/:token',
                        template: require('./ChildLanding.html'),
                        params: {
                            data: null
                        },
                        resolve: {
                            landingPageToken: ['$http', '$stateParams', function ($http, $stateParams) {
                                return $http.get('/LandingPage/GetLandingPage/?token=' + $stateParams.token)
                                    .then(resp => {
                                        return resp.data.LandingPageToken
                                    });
                            }]
                        },
                        controller: 'ChildLandingController as ctrl'
                    });
            }
        ])

        .controller('ChildLandingController', ['Page', 'Menu', 'landingPageToken', '$stateParams',
            function (Page, Menu, landingPageToken, $stateParams) {
                const ctrl = this;

                ctrl.page = Menu.landingPages().find(lp => lp.LandingPageToken === landingPageToken);

                Page.setTitle(ctrl.page.Title);
                Page.showBackButton();
            }]);
})();