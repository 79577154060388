angular
    .module('App.Survey')
    .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
            .state('directReports', {
                parent: 'base', 
                url: '/direct-reports/:token?tab?expired',
                template: `
                        <survey-page
                            class="survey view-page"
                            survey-data="surveyData"
                            is-single-department="true"
                            is-direct-reports="true"
                        ></survey-page>`,
                params: {
                    backState: null,
                    data: {}
                },
                resolve: {
                    surveyData: ['$stateParams', '$http', function ($stateParams, $http) {
                        if ($stateParams.data && !_.isEmpty($stateParams.data) && $stateParams.data.SurveyToken) {
                            return {
                                data: $stateParams.data
                            };
                        } else {
                            const url = `/Survey/GetSurvey/${$stateParams.token}${$stateParams.expired !== undefined ? ('?isExpired=' + $stateParams.expired) : ''}`
                            return $http.get(url);
                        }
                    }]
                },
                controller: 'SurveySingleDepartmentPageController'
            });
    }])
    .controller(['$scope', '$stateParams', 'events', 'Page', 'surveyData', DirectReportsController]);

function DirectReportsController($scope, $stateParams, events, Page, surveyData) {
    $scope.surveyToken = $stateParams.token;
    $scope.surveyData = surveyData.data;
    
    if ($scope.surveyData.PageTitle) {
        Page.setTitle($scope.surveyData.PageTitle);
    }
    
    Page.showBackButton();
    $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());
}