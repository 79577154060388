(function () {
    'use strict';

    angular
        .module('App')
        .component('contentWrapper', {
            template: require('./ContentWrapperComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$rootScope', '$scope', '$timeout', '$filter', '$state', 'Page', 'ContentWrapperService',
                'HeaderButtonsFactory', 'ActionSheetService', 'CookieService', 'ResponsiveService', 'ToastFactory', 'ContentSortingService', ContentWrapperController],
            bindings: {
                contentData: '<',
                openCreatePopup: '<',
                openComments: '=?'
            }
        });

    function ContentWrapperController($stateParams, $rootScope, $scope, $timeout, $filter, $state, Page, ContentWrapperService,
                                      HeaderButtonsFactory, ActionSheetService, CookieService, ResponsiveService, ToastFactory, ContentSortingService) {
        const ctrl = this;
        let searchStringListener, filtersWatcher;

        ctrl.searchItemsReceived = false;
        ctrl.searchQuery = {};
        ctrl.lastSearchedText = '';
        ctrl.filterOptions = [];

        initWatchers();

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onContentOpened = onContentOpened;
        ctrl.onSearch = onSearch;
        ctrl.search = search;
        ctrl.paging = paging;
        ctrl.onDateFilterSelected = onDateFilterSelected;
        ctrl.onToggleFilterSelected = onToggleFilterSelected;
        ctrl.isSearchSticky = isSearchSticky;
        ctrl.isFilterActive = isFilterActive;
        ctrl.isMultipleFiltersActive = isMultipleFiltersActive;
        ctrl.resetFilters = resetFilters;
        ctrl.goToFeed = goToFeed;

        function init() {
            ctrl.categories = ctrl.contentData.Categories;
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.searchResultsOptions = ctrl.contentData.SearchResultsSortingOptions?.length
                ? ContentSortingService.getOptions(false, ctrl.contentData.SearchResultsSortingOptions)
                : [];
            ctrl.feedOptions = ctrl.contentData.SortingOptions?.length
                ? ContentSortingService.getOptions(true, ctrl.contentData.SortingOptions)
                : [];

            const cookieAccountModuleId = CookieService.get('accountModuleId');
            if (Number(cookieAccountModuleId) !== ctrl.contentData.AccountModuleId) {
                CookieService.set('accountModuleId', ctrl.contentData.AccountModuleId, 2);
                resetAllSearch();
                ctrl.showSearch = false;
            }
            
            Page.showBackButtonFunction(() => {
                if (ctrl.searchQuery?.searchText) {
                    resetAllSearch();
                    ctrl.showSearch = false;
                    Page.stateGo($state.current?.name, $state.params?.token, $state.params?.extra,null, false);
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                } else {
                    Page.stateGoBack();
                }
            })

            if (!ctrl.contentData.IsSingleView) {
                ctrl.searchPlaceholder = getSearchPlaceholder();
                if (ContentWrapperService.getSearchState().searchText || $stateParams.data?.showSearch) {
                    ctrl.hideResults = true;
                    ctrl.searchQuery = ContentWrapperService.getSearchState().searchText ? ContentWrapperService.getSearchState() : $stateParams.data?.searchQuery;
                    ctrl.endDate = ctrl.searchQuery.endDate;
                    ctrl.startDate = ctrl.searchQuery.startDate;
                    ctrl.showSearch = true;
                    ctrl.includeFuturePosts = ctrl.searchQuery.includeFuturePosts;
                    ctrl.searchItemsReceived = true;
                    ctrl.archivePostsOnly = ctrl.searchQuery.archivePostsOnly;
                    $timeout(() => {
                        $timeout(() => {
                            document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                            ctrl.hideResults = false;
                        })
                    }, 300);
                } 

                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                getContent();
            }

            if (ctrl.contentData.Settings.FuturePostsFromAppEnabled && ctrl.contentData.AllowCreation) {
                ctrl.filterOptions.push(
                    {
                        title: 'CONTENT.FILTER.SHOW_FUTURE_POSTS',
                        selected: ctrl.searchQuery.includeFuturePosts,
                        icon: 'bookmark-expired',
                        paramName: 'includeFuturePosts'
                    }
                )
            }

            if (ctrl.contentData.Settings.AccountArchiveEnabled && ctrl.contentData.Settings.ArchiveEnabled) {
                ctrl.filterOptions.push(
                    {
                        title: 'CONTENT.FILTERS.ARCHIVED_POSTS',
                        selected: ctrl.searchQuery.archivePostsOnly,
                        icon: 'calendar-plus',
                        paramName: 'archivePostsOnly'
                    }
                )
            }
        }

        function onContentOpened(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            ContentWrapperService.setSearchState(ctrl.searchQuery);
            Page.stateGoLinkV2(item.EntityLink);
        }

        function initWatchers() {
            searchStringListener = $scope.$watch('ctrl.searchQuery.searchText', (newValue, oldValue) => {
                if (newValue !== oldValue && ctrl.searchQuery?.searchText?.length === 0) {
                    document.querySelector('html').scrollTop = 0;
                    ctrl.searchItemsReceived = false;
                    ctrl.searchQuery.sortType = 5; // most relevant
                    if (isFilterActive()) {
                        getContent();
                    }
                }
            });

            filtersWatcher = $scope.$watch(
                '[ctrl.searchQuery.includeFuturePosts,ctrl.searchQuery.sortType,ctrl.searchQuery.startDate,ctrl.searchQuery.endDate,ctrl.searchQuery.archivePostsOnly]',
                (newVal, oldVal) => {
                    if (newVal !== oldVal && ctrl.searchQuery?.searchText?.length) {
                        onSearch();
                    }
                })
        }

        function getContent() {
            const sortTypeId = CookieService.get('sortTypeId-' + ctrl.contentData.AccountModuleToken);
            ctrl.sortType = sortTypeId ? !isNaN(Number(sortTypeId)) ? Number(sortTypeId) : null : null;
            ctrl.contentLoading = true;
            ContentWrapperService.getContents({
                token: ctrl.contentData.AccountModuleToken,
                categoryToken: ctrl.contentData.CategoryToken,
                sortType: ctrl.sortType,
                startTime: ctrl.searchQuery.startDate,
                endTime: ctrl.searchQuery.endDate,
                includeFuturePosts: ctrl.searchQuery.includeFuturePosts,
                archivePostsOnly: ctrl.searchQuery.archivePostsOnly
            }).then(resp => {
                ctrl.contentData.Items = resp.Items;
                ctrl.nextOffset = resp.NextOffset;
                ctrl.contentLoading = false;
                ctrl.processing = false;
            })
        }

        function getSearchPlaceholder() {
            let placeholder = $filter('translate')('CONTENT.SEARCH_IN');

            if (ctrl.contentData.PageSubTitle) {
                placeholder += ' ' + $filter('translate')(ctrl.contentData.PageSubTitle);
            } else {
                placeholder += ctrl.contentData.Categories?.length ?
                    (' ' + $filter('translate')('CONTENT.ALL')) : " " + ctrl.contentData.PageTitle;
            }

            return placeholder;
        }

        function getHeaderButtons() {
            const buttons = [];

            if (ctrl.contentData.ShowCategoryView) {
                if (ctrl.isDesktop) {
                    buttons.push({
                        place: 'title',
                        icon: 'arrow-bottom',
                        activeIcon: 'arrow-top',
                        items: [
                            {
                                title: 'CONTENT.FEED',
                                active: true,
                                onClick: () => {}
                            },
                            {
                                title: 'CONTENT.CATEGORY_OVERVIEW',
                                onClick: () => {
                                    goToOverview();
                                }
                            }
                        ]
                    });
                } else {
                    buttons.push({
                        place: 'right',
                        icon: 'grid-plus',
                        onClick: () => {
                            goToOverview();
                        }
                    })
                }
            }

            if (!ctrl.isDesktop) {
                buttons.push({
                    place: 'right',
                    icon: 'search',
                    active: ctrl.showSearch,
                    activeIcon: 'close',
                    onClick: () => {
                        ctrl.showSearch = !ctrl.showSearch;
                        resetAllSearch();
                    }
                });
            }

            function goToOverview() {
                Page.stateGo('contentOverview', ctrl.contentData.AccountModuleToken, null,{previousCategory: ctrl.contentData.CategoryToken}
                )
            }

            return buttons;
        }

        function onDateFilterSelected(selectedDateFilterId, {startDate, endDate} = {}) {
            ctrl.startDate = startDate;
            ctrl.endDate = endDate;
            if (selectedDateFilterId) {
                ctrl.searchQuery = {...ctrl.searchQuery, startDate, endDate, selectedDateFilterId};
            } else {
                delete ctrl.searchQuery.startDate;
                delete ctrl.searchQuery.endDate;
                delete ctrl.searchQuery.selectedDateFilterId;
            }
        }

        function onToggleFilterSelected(option) {
            ctrl.searchQuery[option.paramName] = option.selected;
            if (option.paramName === 'archivePostsOnly') {
                ctrl.searchQuery.includeFuturePosts = false;
            }

            if (option.paramName === 'includeFuturePosts') {
                ctrl.searchQuery.archivePostsOnly = false;
            }
        }

        function onSearch() {
            document.querySelector('html').scrollTop = 0;
            if (ctrl.searchQuery?.searchText?.length > 0) {
                if (!ctrl.searchItemsReceived) {
                    ctrl.contentLoading = true;
                } else {
                    ctrl.processing = true;
                }
                search()
                    .then(({BatchLastEntityId, Items}) => {
                        ctrl.searchQuery = {
                            ...ctrl.searchQuery,
                            searchResultsList: Items,
                            batchLastEntityId: BatchLastEntityId,
                        }
                        ctrl.lastSearchedText = ctrl.searchQuery.searchText;
                        ctrl.searchItemsReceived = true;
                        ctrl.contentLoading = false;
                        ctrl.processing = false;
                        
                        Page.stateGo($state.current?.name, $state.params?.token, $state.params?.extra,{showSearch: true,  searchQuery: ctrl.searchQuery}, false);
                    })
                    .catch(err => {
                        if (err.status !== -1) {
                            ToastFactory.errorTranslated();
                        }
                    })
            }
        }

        function search(isPaging) {
            return ContentWrapperService.getSearchContentResults({
                searchText: ctrl.searchQuery.searchText,
                accountModuleId: ctrl.contentData.AccountModuleId,
                categoryId: ctrl.contentData.CategoryId,
                sortType: ctrl.searchQuery.sortType ? ctrl.searchQuery.sortType : 5, // 5 - most relevant
                afterEntityId: isPaging && ctrl.searchQuery.batchLastEntityId,
                startDate: ctrl.searchQuery.startDate ? moment(ctrl.searchQuery.startDate).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                endDate: ctrl.searchQuery.endDate ? moment(ctrl.searchQuery.endDate).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                selectedDateFilterId: ctrl.searchQuery.selectedDateFilterId,
                includeFuturePosts: ctrl.searchQuery.includeFuturePosts,
                archivePostsOnly: ctrl.searchQuery.archivePostsOnly,
                limit: isPaging ? 10 : 20
            })
        }

        function paging() {
            ctrl.layzyLoading = true;
            search(true)
                .then(({BatchLastEntityId, Items}) => {
                    ctrl.searchQuery = {
                        ...ctrl.searchQuery,
                        batchLastEntityId: BatchLastEntityId,
                        searchResultsList: [...ctrl.searchQuery.searchResultsList, ...Items]
                    };
                })
                .catch(() => {
                    ToastFactory.errorTranslated();
                })
                .finally(() => {
                    ctrl.layzyLoading = false;
                })
        }

        function isSearchSticky() {
            return ctrl.startDate || ctrl.endDate || ctrl.searchQuery.includeFuturePosts ||
                ctrl.searchQuery.archivePostsOnly || ctrl.searchQuery?.searchText?.length || ResponsiveService.isMobile();
        }

        function isFilterActive() {
            return ctrl.startDate || ctrl.endDate || ctrl.searchQuery.includeFuturePosts || ctrl.searchQuery.archivePostsOnly;
        }

        function isMultipleFiltersActive() {
            return ctrl.searchQuery.includeFuturePosts && ctrl.startDate || ctrl.searchQuery.includeFuturePosts && ctrl.endDate ||
                ctrl.searchQuery.archivePostsOnly && ctrl.startDate || ctrl.searchQuery.archivePostsOnly && ctrl.endDate ||
                ctrl.searchQuery.includeFuturePosts && ctrl.searchQuery.archivePostsOnly;
        }

        function resetFilters() {
            const searchString = ctrl.searchQuery.searchText;
            document.querySelector('html').scrollTop = 0;
            if (ctrl.searchItemsReceived) {
                ctrl.processing = true;
            }
            ctrl.startDate = null;
            ctrl.endDate = null;
            ctrl.searchQuery = {};
            ctrl.resetDateFilter && ctrl.resetDateFilter();
            ctrl.resetToggleFilter && ctrl.resetToggleFilter();
            ctrl.searchQuery.searchText = searchString;
        }

        function resetAllSearch() {
            ctrl.searchQuery = {};
            document.querySelector('html').scrollTop = 0;
            ContentWrapperService.cleanSearchState();
            ctrl.searchItemsReceived = false;
            if (ctrl.resetDateFilter) {
                ctrl.resetFilters();
            }

            if (ctrl.resetToggleFilter) {
                ctrl.resetFilters();
            }
        }

        function goToFeed(post) {
            resetAllSearch();
            Page.stateGo('content', ctrl.contentData.AccountModuleToken, post.ContentCategoryToken);
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            searchStringListener();
            filtersWatcher();

            if ($state.current.name !== 'contentItem' && !ctrl.contentData.IsSingleView) {
                ContentWrapperService.cleanSearchState();
            }
        }
    }
})();
