(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('scormcloudCourse', {
                    parent: 'base',
                    url: '/scormcloud/:token/course/:extra',
                    templateUrl: '/Scripts/Components/ScormCloud/CoursePage/ScormCloudCoursePage.html',
                    params: {
                        data: {}
                    },
                    resolve: {
                        courseData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/ScormCloud/GetIframe/' + $stateParams.extra);
                            }
                        }]
                    },
                    controller: 'ScormCloudLessonPageController as ctrl'
                });
        }])
        .controller('ScormCloudLessonPageController', ['$scope', '$sce', '$stateParams', 'courseData', 'events', 'Page',
            function ($scope, $sce, $stateParams, courseData, events, Page) {
                var ctrl = this;
                ctrl.Page = Page;

                Page.setTitle(courseData.data.Title);
                Page.showBackButton();

                Page.showMenuButton(!courseData.data.HideMenuButton);
                Page.setTitle(courseData.data.Title);

                // bind data
                $scope.iframeUrl = $sce.trustAsResourceUrl(courseData.data.Url);
                $scope.url = courseData.data.Url;
                $scope.background = courseData.data.BackgroundImage;
                $scope.title = courseData.data.Title;
                $scope.urlPreview = courseData.data.UrlPreview;
                $scope.iframeToken = courseData.data.IframeToken;

                if (courseData.data.LockVerticalScroll) {
                    angular.element('body').addClass('lock-vertical-scrolling')
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }]);
})();