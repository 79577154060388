(function () {
    'use strict';

    angular
        .module('App')
        .factory('PolicyViewService', ['$http', '$filter', 'ToastFactory', 'TimeLocale', 'ConfirmPopupService', 'Page', PolicyViewService]);

    function PolicyViewService($http, $filter, ToastFactory, TimeLocale, ConfirmPopupService, Page) {

        return {
            getPolicies: getPolicies,
            revokePolicy: revokePolicy,
            confirmAndRevokePolicy: confirmAndRevokePolicy,
            getPolicy: getPolicy,
            getAcceptedDate: getAcceptedDate,
            agreeWithPolicy: agreeWithPolicy,
            getNewPolicies: getNewPolicies
        };

        function agreeWithPolicy(policyToken) {
            return $http.post('/Policy/ApprovePolicy/' + policyToken)
                .then((response) => {
                    return response;
                }).catch(error => {
                    ToastFactory.errorTranslated('ERROR.GENERAL');
                    throw error;
                })
        }

        function revokePolicy(policyToken) {
            return $http.post('/Policy/RevokePolicy/' + policyToken).then(response => {
                ToastFactory.successTranslated('POLICY.REVOKE.IN_PROGRESS');
                return response;
            }).catch(error => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
                throw error;
            });
        }

        function confirmAndRevokePolicy(policyToken) {
            const opts = {
                title: $filter('translate')('POLICY.REVOKE_CONSENT.TITLE'),
                message: $filter('translate')('POLICY.REVOKE_CONSENT.MESSAGE'),
                icon: '/Content/Graphics/Emoji/svg/1f97a.svg',
                yesText: $filter('translate')('POLICY.REVOKE_CONSENT.REVOKE'),
                noText: $filter('translate')('POLICY.REVOKE_CONSENT.GO_BACK'),
                class: 'revoke-consent-confirmation-popup',
                onClose: () => {}
            };
    
            return ConfirmPopupService.open(opts).then(() => {
                return revokePolicy(policyToken).then(() => {
                    Page.stateGo('logout');
                });
            });
        }

        function getAcceptedDate(dateAccepted) {
            const dateString = TimeLocale.dateTimeFormat(dateAccepted, 'LL');
            const translation = $filter('translate')('POLICY.VIEW.DATE_APPROVED');
            return translation.replace('[DATE]', dateString);
        }

        function getPolicy(policyToken) {
            return $http.get('/Policy/LoadPolicy/' + policyToken).then(response => {
                return response.data;
            }).catch(error => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
                throw error;
            });
        }
        
        function getPolicies() {
            return $http.get('/Policy/GetPolicies').then(response => {
                return response.data;
            }).catch(error => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
                throw error;
            });
        }

        function getNewPolicies() {
            return $http.get('/Walkthrough/NewPolicies').then(response => {
                return response.data;
            }).catch(error => {
                ToastFactory.errorTranslated('ERROR.GENERAL');
                throw error;
            });
        }
    }
})();