(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('christmasCalendar', {
                    parent: 'base',
                    url: '/christmascalendar/:token',
                    params: {
                        extra: null
                    },
                    templateUrl: '/Scripts/Controllers/Modules/ChristmasCalendar/Templates/ChristmasCalendar.tpl.html',
                    resolve: {
                        pageData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/ChristmasCalendar/GetChristmasCalendar/?token=' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'ChristmasCalendarController as ChristmasCalendarCtrl'
                });
        }
    ]);

    app.controller('ChristmasCalendarController', ['Page', '$scope', 'pageData', '$http', '$timeout', 'toastr', '$translate', 'ModalService', 'BasicHelper', '$interval', '$stateParams', '$element', '$cookies', '$rootScope', 'events',
        function (PageService, $scope, pageData, $http, $timeout, toastr, $translate, ModalService, BasicHelper, $interval, $stateParams, $element, $cookies, $rootScope, events) {

            // Init
            InitController();
            function InitController() {
                PageService.showBackButton();
                PageService.setTitle(pageData.data.PageTitle);

                $scope.introHidden = true;
                $scope.shortIntro = [];
                $scope.containerStyle = {};
                $scope.tileStyle = {};
                $scope.openTileStyle = {};

                $scope.root = $rootScope;

                var christmasCalendar = pageData.data.ChristmasCalendar;
                $scope.settings = christmasCalendar.Settings;
                $scope.dayList = christmasCalendar.DayList;
                $scope.childAccountModuleList = pageData.data.ChildAccountModuleList;

                if ($scope.settings.ShortIntro.length > 0) {
                    $scope.shortIntro.push($scope.settings.ShortIntro);
                }

                // Set Icon Urls
                for (var i = 0; i < $scope.dayList.length; i++) {
                    var day = $scope.dayList[i];

                    if (day.IconMediaId === null) {
                        day.iconUrl = '/Content/Graphics/ChristmasCalendar/' + day.DayNumber + '.png';
                    } else {
                        day.iconUrl = day.IconMediaId.ImageFormats.W100;
                    }
                }

                // Set Styles
                var _style = $('style', $element);
                if ($scope.settings.BackgroundColor.length > 0) {
                    $scope.containerStyle = {
                        backgroundColor: $scope.settings.BackgroundColor
                    };
                    $scope.openTileStyle = $scope.containerStyle;
                }
                if ($scope.settings.BorderColor.length > 0) {
                    $scope.tileStyle = {
                      borderColor: $scope.settings.BorderColor,
                      color: $scope.settings.BorderColor
                    };
                    $scope.containerStyle.color = $scope.settings.BorderColor;
                }
                
                // Set Backgrounds and start slideshow
                var backgroundLength = $scope.settings.BackgroundMedia.Children.length;
                if (backgroundLength > 0) {
                    $scope.settings.BackgroundMedia.Children[0].active = true;
                    if (backgroundLength > 1) {
                        StartSlideshow();
                    }
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  PageService.stateReload();
                });

                if ($stateParams.extra) {
                    for (var i = 0; i < $scope.dayList.length; i++) {
                        var day = $scope.dayList[i];

                        if (day.ChristmasCalendarDayId == $stateParams.extra) {
                            OpenDay(day);
                        }
                    }
                }
            }

            // Methods
            function StartSlideshow() {
                var index = 0;
                var length = $scope.settings.BackgroundMedia.Children.length;
                $interval(function () {
                    $scope.settings.BackgroundMedia.Children[index].active = false;
                    index = (index + 1 === length) ? 0 : (index + 1);
                    $scope.settings.BackgroundMedia.Children[index].active = true;
                }, 5000);
            }

						function ShowIntro() {
							if ($scope.introHidden && $scope.settings.LongIntro.length && $scope.settings.ShortIntro.length) {
                    $scope.introHidden = false;
                    ModalService.showModal({
                        templateUrl: '/Scripts/Controllers/Modules/ChristmasCalendar/Templates/ChristmasCalendar.Intro.tpl.html',
                        controller: 'ChristmasCalendarIntroController',
                        inputs: { intro: $scope.settings }
                    }).then(function (modal) {
                        // Returns the modal for further options if needed 
                        modal.scope.$on('$destroy', function () {
                            $scope.introHidden = true;
                        });
                    });
                }
            }

            function OpenDay(day) {
                if (day.IsActive) {
                    if (!day.IsAnswered) {
                        day.IsAnswered = true;

                        if (day.ContentToken !== null && day.SurveyToken !== null) {
                            // Show Picker
                            ShowPicker(day);
                        } else if (day.ContentToken !== null) {
                            // Show Content
                            ShowContent(day);
                        } else if (day.SurveyToken !== null) {
                            // Show Survey
                            ShowSurvey(day);
                        } else {
                            // Register day has been seen
                            RegisterDay(day).then(function () {
                                // Everything went okay
                            }, function () {
                                // Something went wrong
                                day.IsAnswered = false;
                                $translate('ERROR.GENERAL').then(function (translation) {
                                    toastr.error(translation);
                                });
                            });

                            // Show Popup with a message
                            BasicHelper.translateStrings(['CHRISTMASCALENDAR.DAY_DONE_HEADLINE', 'CHRISTMASCALENDAR.DAY_DONE_TEXT']).then(function (translations) {
                                PageService.showMessagePopup({
                                    headline: translations[0],
                                    text: translations[1],
                                    icon: 'mdi mdi-pine-tree'
                                }, function () {

                                });
                            });
                        }
                    } else {
                        if (day.ContentToken !== null && day.SurveyToken !== null) {
                            // Show Picker
                            ShowPicker(day);
                        } else if (day.ContentToken !== null) {
                            // Show Content
                            ShowContent(day);
                        } else if (day.SurveyToken !== null) {
                            // Show Survey
                            ShowSurvey(day);
                        } else {
                            $translate('CHRISTMASCALENDAR.NOTHING').then(function (translation) {
                                toastr.info(translation);
                            });
                        }
                    }
                } else {
                    $translate('CHRISTMASCALENDAR.DAY_INACTIVE').then(function (translation) {
                        toastr.info(translation);
                    });
                }   
            }

            function ShowPicker(day) {
                RegisterDay(day);
                PageService.stateGo('christmasCalendarDay', day.ChristmasCalendarDayId);
            }

            function ShowContent(day) {
                RegisterDay(day);
                PageService.stateGo('contentItem', day.ContentToken);
            }

            function ShowSurvey(day) {
                RegisterDay(day);
                PageService.stateGo('survey', null, day.SurveyToken);
            }
            
            function RegisterDay(day) {
                return $http.post('/ChristmasCalendar/RegisterDay', { ChristmasCalendarDayId: day.ChristmasCalendarDayId });
            }

            function GetTileOpenStyle(day) {
                if (day.IsAnswered) {
                    return $scope.openTileStyle;
                }
                return null;
            }

            // Bindings
            $scope.showIntro = ShowIntro;
            $scope.openDay = OpenDay;
            $scope.getTileOpenStyle = GetTileOpenStyle;
        }
    ]);
})();