(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('stickyTopClass', ['$window', '$document', 
        function ($window, $document) {

        return {
            restrict: 'A', 
            link: function (scope, element, attrs) {
                const $win = angular.element($window); // wrap window object as jQuery object
                const $doc = angular.element($document);

                const topClass = attrs.stickyTopClass,  // get CSS class from directive's attribute value
                    headerHeight = element.height();
                let savedState = $win.scrollTop() >= headerHeight;

                if (attrs.stickyTopParent) {
                    $win = angular.element(attrs.stickyTopParent);
                }

                $win.on('scroll', _.throttle(function (e) {
                    const scrollTop = element.hasClass('fixed-top') ? 
                        $win.scrollTop() + (headerHeight - angular.element('.head').height()) : 
                        $win.scrollTop();

                    const currentState = scrollTop >= headerHeight;
                    const tolerance = 50;
                    const collapsableHeaderHeight = headerHeight - 50; // 50 is min-height after collapse
                    const canHeaderCollapse = ($doc.height() - collapsableHeaderHeight) > $win.height() + tolerance;

                    if (savedState !== currentState) {
                        const collapseThresholdReached = currentState;
                        if (collapseThresholdReached && !canHeaderCollapse) return;

                        savedState = currentState;
                        element.toggleClass(topClass, savedState)
                    }
                }, 50));
            }
        };
    }]);
})();
