
(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('learningbank', {
                    parent: 'base',
                    url: '/learningbank/:token',
                    templateUrl: '/Scripts/Controllers/Modules/LearningBank/Templates/LearningBankOverview.tpl.html',
                    controller: 'LearningBankOverviewController as LearningBankOverviewCtrl'
                });
        }
    ]);

    app.controller('LearningBankOverviewController', ['$scope', '$stateParams', '$timeout', '$http', '$window', 'ToastFactory', 'Page', 'base', 'events',
        function ($scope, $stateParams, $timeout, $http, $window, ToastFactory, Page, base, events) {
            $scope.loading = true;
            $scope.showError = false;
            $scope.learningbankData = {};
            $scope.openLearningbank = openLearningbank;
            $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());

            init();

            function init() {
                getModuleData()
                    .then(data => {
                        $scope.learningbankData = data.data;
                        setPageData();
                    }).catch(()=> displayError())
                    .finally(() => $scope.loading = false);
            }

            function getModuleData() {
                if (base) {
                    return $http.get('/LearningBank/GetModule/?token=' + $stateParams.token);
                } return;
            }

            function setPageData() {
                Page.showBackButton();    
                Page.setTitle($scope.learningbankData.Title);
            }

            function displayError() {
                $scope.showError = true;
                ToastFactory.errorTranslated('ERROR.GENERAL');
            }

            function openLearningbank(item) {
                const moduleToken = $stateParams.token;
                const itemToken = item.LearningBankItemToken;

                if ($scope.learningbankData.Settings?.OpenInNewTab) {
                    openInNewTab(itemToken, moduleToken)
                    return;
                }

                openInSameTab(itemToken, moduleToken);
            }

            function openInNewTab(itemToken, moduleToken) {

                $http.get('/LearningBank/Get/' + itemToken + '?accountModuleToken=' + moduleToken)
                    .then(response => {
                        if (response.data.ErrorMessage) {
                            ToastFactory.errorTranslated(response.data.ErrorMessage);
                            return;
                        }

                        $timeout(function () {
                            if (NativeHelper.isIOS()) {
                                let a = document.createElement("a");
                                a.href = response.data.Url;
                                a.target = "_blank";
                                a.click();
                            } else {
                                $window.open(response.data.Url, "_blank");
                            }
                        })
                    }).catch(()=> {
                        ToastFactory.errorTranslated('ERROR_GENERAL');
                    })
            }

            function openInSameTab(itemToken, moduleToken) {
                Page.stateGo(
                    'learningbankitem',
                    itemToken,
                    moduleToken
                );
            }
        }
    ]);
})();