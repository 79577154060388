'use strict';

angular
    .module('App.Training')
    .component('learningPathItem', {
        template: require('./LearningPathItemComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$stateParams','$state', 'Page', 'TrainingService', LearningPathItemController],
        bindings: {
            path: '<',
            showTranslated: '<',
        }
    })

function LearningPathItemController($stateParams,$state, Page, TrainingService) {
    const ctrl = this;
    ctrl.getStatus = TrainingService.getItemStatus;
    ctrl.getStatusClass = TrainingService.getItemStatusClass;
}