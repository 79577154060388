(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('learningPath', {
                    parent: 'base',
                    url: '/training/learningPath/:token',
                    template: '<learning-path-page class="view-page" learning-path="ctrl.learningPath" courses="ctrl.courses" performance-ranges="ctrl.performanceRanges" learning-path-token="ctrl.learningPathToken"></learning-path-page>',
                    resolve: {
                        trainingData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/TrainingV2/Training/GetLearningPath/' + $stateParams.token)
                                    .then(resp => resp.data);
                            }
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    controller: 'LearningPathPageController as ctrl'
                });
        }])
        .controller('LearningPathPageController', ['$scope', '$stateParams', 'trainingData', 'events', 'Page',
            function ($scope, $stateParams, trainingData, events, Page) {
                var ctrl = this;

                ctrl.courses = trainingData.Courses;
                ctrl.learningPath = trainingData.LearningPath;
                ctrl.performanceRanges = trainingData.PerformanceRanges;
                ctrl.moduleToken = trainingData.AccountModuleToken;
                ctrl.learningPathToken = $stateParams.token;

                Page.setTitle(trainingData.PageTitle);

                Page.showBackButton();

                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateGoBack('training', trainingData.AccountModuleToken);
                });
            }]);
})();