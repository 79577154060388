(function () {
    'use strict';

    angular
        .module('App')
        .component('event', {
            template: require('./EventComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', EventController],
            bindings: {
                event: '<',
                isBig: '<'
            }
        });

    function EventController($stateParams, Page) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.openEventDetails = openEventDetails;

        function init() {
            if (ctrl.isBig) {
                ctrl.currentdDate = {
                    day: moment().format('DD'),
                    month: moment().format('MMM')
                }
            }
        }

        function openEventDetails() {
            Page.stateGo('eventPopup', $stateParams.token, '?CalendarEventId=' + ctrl.event.CalendarEventId, null);
        }

    }
})();
