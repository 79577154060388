(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('NotificationFactory', ['$rootScope', '$compile', '$http', '$q', '$interval', '$translate', 'events', 'Page', 'TrackingService',
        function ($rootScope, $compile, $http, $q, $interval, $translate, events, Page, TrackingService) {
            var viewerOpen = false, isLoaded = false, unseenAmount = 0, unreadAmount = 0, notifications = [],
                isLoading = false,
                recheckNotificationsIntervalTime = (1000 * 60 * 3),
                recheckNotificationsInterval = null,
                dropdownVisibility = false,
                nativeBadgeCount = {
                    ModuleBadgeCount: 0,
                    NotificationCenterBadgeCount: 0,
                    UnreadChatCount: 0
                };

            // Start an interval to check every 3 minutes
            startIntervalForNotificationRecheck();

            // Listen for events
            $rootScope.$on(events.DEPARTMENT_CHANGED, () => {
                // Reload our counter
                notifications = [];
                loadNotifications();
            });

            $rootScope.$on(events.APP_UNHIDE, startIntervalForNotificationRecheck);
            $rootScope.$on(events.APP_HIDE, pauseIntervalForNotificationRecheck);
            $rootScope.$on(events.PINCODE_DIDSHOW, pauseIntervalForNotificationRecheck);
            $rootScope.$on(events.PINCODE_DIDHIDE, startIntervalForNotificationRecheck);

            function pauseIntervalForNotificationRecheck() {
                if (recheckNotificationsInterval) {
                    $interval.cancel(recheckNotificationsInterval);
                }
            }

            function startIntervalForNotificationRecheck() {
                if (recheckNotificationsInterval) {
                    $interval.cancel(recheckNotificationsInterval);
                }

                recheckNotificationsInterval = $interval(function () {
                    loadUnseenCount();
                }, recheckNotificationsIntervalTime);
            }

            function loadUnseenCount() {
                if (Page.isPinCodePageVisible() == false) {
                    $http.get('/Notification/GetCounts').then(function (resp) {
                        unseenAmount = resp.data.NotificationCenterCount;
                        $rootScope.$broadcast(events.BADGE_UPDATED, {
                            badgeCount: resp.data.Total
                        });
                    });
                }
            }

            // Load Notifications
            var loadNotificationsDefer;

            function loadNotifications(limit, cursor) {
                var d = $q.defer();

                // only happens when you want to load notifications all over again
                var isInitialLoad = !_.isNumber(limit);
                if (isInitialLoad) {
                    if (loadNotificationsDefer) {
                        return loadNotificationsDefer.promise;
                    }
                    limit = 10;
                    loadNotificationsDefer = d;
                }

                var url = '/Notification/GetList?limit=' + limit;
                if (cursor) {
                    url += '&lastDate=' + cursor.LastDate + '&lastNotificationId=' + cursor.LastNotificationId;
                }

                $http.get(url)
                    .then(function (response) {
                        // Data loaded
                        var data = response.data.Notifications;
                        if (_.isArray(data) === false) {
                            data = [];
                        }

                        var result = [];

                        if (isInitialLoad) {
                            notifications = [];
                        }

                        for (var i = 0; i < data.length; i++) {
                            var notification = data[i];
                            if (!_.find(notifications, function (o) {
                                return o.NotificationId === notification.NotificationId
                            })) {
                                translateNotification(notification);
                                notifications.push(notification);
                                result.push(notification);
                            }
                        }

                        response.data.Notifications = result;
                        if (response.data.Cursor && response.data.Cursor.LastDate) {
                            response.data.Cursor.LastDate = new Date(parseInt(response.data.Cursor.LastDate.substr(6))).toISOString();
                        }

                        // Set a new unread count
                        updateAmounts();

                        if (isInitialLoad) {
                            loadNotificationsDefer = null;
                        }
                        isLoaded = true;
                        d.resolve(response.data);

                    }, function () {
                        // Failed to load
                        if (isInitialLoad) {
                            loadNotificationsDefer = null;
                        }
                        d.reject();
                    });

                return d.promise;
            }

            // Method for translating fields
            function translateNotification(notification) {
                translateField(notification.Title).then(function (value) {
                    notification.Title = value;
                });
                translateFieldMultiple(notification.Message).then(function (value) {
                    notification.Message = value;
                });
                translateField(notification.ModuleName).then(function (value) {
                    notification.ModuleName = value;
                });
            }

            function translateField(value) {
                var d = $q.defer();

                var matches = value.match(/\[(.*?)\]/);
                if (matches) {
                    $translate(matches[1]).then(function (translation) {
                        // Replace value
                        value = value.replace(matches[0], translation);
                        d.resolve(value);

                        // Attempt another translate
                        //translateField(value);
                    });
                }
                return d.promise;
            }

            function translateFieldMultiple(value) {
                var d = $q.defer();

                var matches = value.match(/\[(.*?)\]/g);
                if (matches) {
                    $q.all(matches.map(function (match) {
                        return $translate(match.replace(/[\[\]]/g, '')).then(function (d) {
                            return d;
                        }, function (er) {
                            return undefined;
                        })
                    })).then(function (results) {
                        results.map(function (res, i) {
                            value = res ? value.replace(matches[i], res) : value;
                        });

                        d.resolve(value);
                    }).catch(function (er) {
                        d.resolve(value);
                    })
                }

                return d.promise;


            }

            // Get notification
            function getNotification(notificationId) {
                return _.find(notifications, function (o) {
                    return o.NotificationId === notificationId;
                });
            }

            function updateAmounts() {
                // unread
                unreadAmount = _.filter(notifications, function (o) {
                    return (o.IsRead === false);
                }).length;

                // unseen
                unseenAmount = _.filter(notifications, function (o) {
                    return (o.IsRead === false && o.IsSeen === false);
                }).length;
                nativeBadgeCount.NotificationCenterBadgeCount = unseenAmount;

                // update native
                updateNativeBadgeCount();
            }

            function updateNativeBadgeCount() {
                $rootScope.$broadcast(events.BADGE_UPDATED,
                    {
                        badgeCount: nativeBadgeCount.NotificationCenterBadgeCount + nativeBadgeCount.UnreadChatCount
                    });
            }

            function adjustModuleBadgeCount(ModuleBadgeCount) {
                nativeBadgeCount.ModuleBadgeCount = nativeBadgeCount.ModuleBadgeCount + ModuleBadgeCount;
                nativeBadgeCount.ModuleBadgeCount = nativeBadgeCount.ModuleBadgeCount > 0 ?
                    nativeBadgeCount.ModuleBadgeCount : 0;
                updateNativeBadgeCount();
            }

            function setNativeBadgeCount(ModuleBadgeCount, NotificationCenterBadgeCount, UnreadChatCount) {
                if (ModuleBadgeCount) {
                    nativeBadgeCount.ModuleBadgeCount = ModuleBadgeCount > 0 ? ModuleBadgeCount : 0;
                }
                if (NotificationCenterBadgeCount) {
                    nativeBadgeCount.NotificationCenterBadgeCount = NotificationCenterBadgeCount > 0 ?
                        NotificationCenterBadgeCount : 0;
                }
                if (UnreadChatCount !== undefined) {
                    nativeBadgeCount.UnreadChatCount = UnreadChatCount;
                }
                updateNativeBadgeCount();
            }

            function getDropdownVisibility() {
                return dropdownVisibility;
            }

            function toggleDropdownVisibility(visible) {
                if(_.isBoolean(visible)){
                    dropdownVisibility = visible;
                }else{
                    dropdownVisibility = !dropdownVisibility;
                }

                if (dropdownVisibility) {
                    // Now that data has been reloaded, we will mark them all as seen
                    f.markAllAsSeen();
                    // we will also track the opening as it doesn't make a state change
                    TrackingService.trackUrlPageView('/notifications');
                }
            }

            function markNotifications(data, saveEvent) {
                if (_.isObject(data) && _.isArray(data.ReadNotificationIds) && _.isArray(data.SeenNotificationIds)) {
                    markAsSeen(data.SeenNotificationIds, saveEvent);
                    markAsRead(data.ReadNotificationIds, saveEvent);
                }
            }

            function markAsSeen(notificationIds, saveEvent) {
                var d = $q.defer();

                if (!_.isArray(notificationIds)) {
                    if (_.isNumber(notificationIds)) {
                        // Turn into array
                        notificationIds = [notificationIds];
                    } else {
                        notificationIds = [];
                    }
                }

                // only continue if any ids are present
                if (notificationIds.length > 0 && !isLoading) {
                    // Update values in list
                    var selectedNotifications = [];
                    $.each(notificationIds, function (i, elem) {
                        var noti = getNotification(elem);
                        if (noti && noti.IsSeen !== true) {
                            noti.IsSeen = true;
                            selectedNotifications.push(noti);
                        }
                    });

                    // Re count
                    updateAmounts();

                    if (saveEvent === true) {
                        isLoading = true;
                        // Save to database
                        $http.post('/Notification/MarkAsSeen', notificationIds).then(function () {
                            // Success
                            updateAmounts();
                            isLoading = false;
                            d.resolve();
                            $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                        }, function () {
                            // Failed
                            $.each(notificationIds, function (i, elem) {
                                var noti = getNotification(elem);
                                if (noti) {
                                    noti.IsSeen = false;
                                }
                            });
                            updateAmounts();
                            isLoading = false;
                            d.reject();
                        });
                    } else {
                        d.resolve();
                        $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                    }
                } else {
                    d.resolve();
                }

                return d.promise;
            }

            function markAsRead(notificationIds, saveEvent, reloadCount) {
                var d = $q.defer();

                if (!_.isArray(notificationIds)) {
                    if (_.isNumber(notificationIds)) {
                        // Turn into array
                        notificationIds = [notificationIds];
                    } else {
                        notificationIds = [];
                    }
                }

                // only continue if any ids are present
                if (notificationIds.length > 0 && !isLoading) {
                    // Update values in list
                    var selectedNotifications = [];
                    $.each(notificationIds, function (i, elem) {
                        var noti = getNotification(elem);
                        if (noti && noti.IsRead !== true) {
                            noti.IsRead = true;
                            noti._oldIsSeen = noti.IsSeen;
                            noti.IsSeen = true;
                            selectedNotifications.push(noti);
                        }
                    });

                    // Re count
                    updateAmounts();

                    if (saveEvent === true) {
                        isLoading = true;
                        // Save to database
                        $http.post('/Notification/MarkAsRead', notificationIds).then(function () {
                            isLoading = false;
                            
                            // Success
                            if(reloadCount === true){
                                loadUnseenCount();
                                d.resolve();
                            }else{
                                updateAmounts();
                                d.resolve();
                                $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                            }
                        }, function () {
                            // Failed
                            $.each(notificationIds, function (i, elem) {
                                var noti = getNotification(elem);
                                if (noti) {
                                    noti.IsRead = false;
                                    noti.IsSeen = noti._oldIsSeen;
                                }
                            });
                            updateAmounts();
                            isLoading = false;
                            d.reject();
                        });
                    } else {
                        d.resolve();
                        $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                    }
                } else {
                    d.resolve();
                }

                return d.promise;
            }

            function markAsUnRead(notificationIds, saveEvent) {
                var d = $q.defer();

                if (!_.isArray(notificationIds)) {
                    if (_.isNumber(notificationIds)) {
                        // Turn into array
                        notificationIds = [notificationIds];
                    } else {
                        notificationIds = [];
                    }
                }

                // only continue if any ids are present
                if (notificationIds.length > 0 && !isLoading) {
                    // Update values in list
                    var selectedNotifications = [];
                    $.each(notificationIds, function (i, elem) {
                        var noti = getNotification(elem);
                        if (noti && noti.IsRead === true) {
                            // Set to true
                            noti.IsRead = false;
                            noti._oldIsSeen = noti.IsSeen;
                            noti.IsSeen = true;
                            selectedNotifications.push(noti);
                        }
                    });

                    // Re count
                    updateAmounts();

                    if (saveEvent === true) {
                        isLoading = true;
                        // Save to database
                        $http.post('/Notification/MarkAsUnRead', notificationIds).then(function () {
                            // Success
                            updateAmounts();
                            isLoading = false;
                            d.resolve();
                            $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                        }, function () {
                            // Failed
                            $.each(notificationIds, function (i, elem) {
                                var noti = getNotification(elem);
                                if (noti) {
                                    noti.IsRead = true;
                                    noti.IsSeen = noti._oldIsSeen;
                                }
                            });
                            updateAmounts();
                            isLoading = false;
                            d.reject();
                        });
                    } else {
                        d.resolve();
                        $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, selectedNotifications);
                    }
                } else {
                    d.resolve();
                }

                return d.promise;
            }

            function markAllAsSeen() {
                var d = $q.defer();

                if (!isLoading) {
                    isLoading = true;

                    try {
                        // Update values in list
                        $.each(notifications, function (i, noti) {
                            noti._oldIsSeen = noti.IsSeen;
                            noti.IsSeen = true;
                        });

                        $http.post('/Notification/MarkAllAsSeen').then(function () {
                            // Success
                            updateAmounts();
                            isLoading = false;
                            d.resolve();
                            $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, notifications);
                        }, function () {
                            // Failed
                            $.each(notifications, function (i, noti) {
                                noti.IsSeen = noti._oldIsSeen;
                            });
                            updateAmounts();
                            isLoading = false;
                            d.reject();
                        });
                    } catch {
                        isLoading = false;
                        d.resolve();
                    }

                } else {
                    d.resolve();
                }

                return d.promise;
            }

            function markAllAsRead() {
                var d = $q.defer();

                if (!isLoading) {
                    isLoading = true;

                    try {
                        // Update values in list
                        $.each(notifications, function (i, noti) {
                            noti._oldIsRead = noti.IsRead;
                            noti._oldIsSeen = noti.IsSeen;
                            noti.IsRead = true;
                            noti.IsSeen = true;
                        });

                        $http.post('/Notification/MarkAllAsRead').then(function () {
                            // Success
                            updateAmounts();
                            isLoading = false;
                            d.resolve();
                            $rootScope.$broadcast(events.NOTIFICATIONS_CHANGED, notifications);
                        }, function () {
                            // Failed
                            $.each(notifications, function (i, noti) {
                                noti.IsSeen = noti._oldIsSeen;
                                noti.IsRead = noti._oldIsRead;
                            });
                            updateAmounts();
                            isLoading = false;
                            d.reject();
                        });
                    } catch {
                        isLoading = false;
                        d.resolve();
                    }
                } else {
                    d.resolve();
                }

                return d.promise;
            }

            var f = {
                openViewer: function () {
                    if (!viewerOpen) {
                        viewerOpen = true;
                        var newScope = $rootScope.$new(true);

                        var $viewer = $compile("<notification-viewer></notification-viewer>")(newScope);
                        $('body').append($viewer);

                        newScope.$on('$destroy', function () {
                            viewerOpen = false;
                        });
                    }
                },
                loadNotifications: function (limit, cursor) {
                    return loadNotifications(limit, cursor);
                },
                hasUnread: function () {
                    return (unreadAmount > 0);
                },
                unreadCount: function () {
                    return unreadAmount;
                },
                hasUnseen: function () {
                    return (unseenAmount > 0);
                },
                unseenCount: function () {
                    return unseenAmount;
                },
                isLoaded: function () {
                    return isLoaded;
                },
                getNotifications: function () {
                    return notifications;
                },
                markNotifications: markNotifications,
                markAsRead: markAsRead,
                markAsUnRead: markAsUnRead,
                markAllAsSeen: markAllAsSeen,
                markAllAsRead: markAllAsRead,
                delete: function (notificationId) {
                    var notification = getNotification(notificationId);
                    if (notification) {
                        var index = notifications.indexOf(notification);
                        if (index >= 0) {
                            notifications.splice(index, 1);
                            // Send to API
                            $http.post('/Notification/Delete/' + notificationId);
                        }
                    }
                },
                setNativeBadgeCount: setNativeBadgeCount,
                adjustModuleBadgeCount: adjustModuleBadgeCount,
                getDropdownVisibility: getDropdownVisibility,
                toggleDropdownVisibility: toggleDropdownVisibility,
                loadUnseenCount: loadUnseenCount
            };

            return f;
        }
    ]);
})();