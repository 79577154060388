(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
    function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
        $stateProvider
            .state('todoHighscore', {
                parent: 'base',
                url: '/todo/highscore/:token',
                templateUrl: '/Templates/Modules/ToDo/ToDoHighscore.tpl.html?v=1.0',
                resolve: {
                    toDoData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                        if (base) {
                            return $http.get('/Module/ToDo/GetHighscorePage/' + $stateParams.token);
                        } return;
                    }]
                },
                controller: 'ToDoHighscoreController'
            });
    }]);

    app.controller('ToDoHighscoreController', ['$rootScope', '$scope', '$state', 'Page', 'toDoData', '$translate', 'TimeLocale', '$timeout', '$compile', '$http', '$stateParams', 'toastr', '$q', 'events',
        function ($rootScope, $scope, $state, Page, toDoData, $translate, TimeLocale, $timeout, $compile, $http, $stateParams, toastr, $q, events) {
            
            // Init
            InitController();
            function InitController() {
                // Bind Outside Data
                $scope.Settings = toDoData.data.Settings;
                $scope.Activities = toDoData.data.Activities;

                // Set Page Titlte
                Page.setTitle(toDoData.data.PageTitle, $scope.Settings.HighscoreTitle);

                if (!angular.isString($scope.Settings.HighscoreTitle) || $scope.Settings.HighscoreTitle.length == 0) {
                    // Page title was not set through settings and should get a default translated value
                    $translate('TODO.MENU.HIGHSCORE').then(function (translation) {
                        Page.setTitle(toDoData.data.PageTitle, translation);
                    });
                }

                // Create back button
                Page.showBackButton();

                // Create default data
                $scope.UserHighscore = [];
                $scope.DepartmentHighscore = [];
                $scope.userLoading = false;
                $scope.userLoaded = false;
                $scope.departmentLoading = false;
                $scope.departmentLoaded = false;

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateReload();
                });
            }

            // Objects
       
            // Methods
            function UserHighscoreSelect(act) {
                if (angular.isObject(act)) {
                    if (!angular.isObject(act.UserHighscore) && !act.userLoading) {
                        // Only add if not already loaded
                        act.UserHighscore = [];
                        act.userLoading = true;
                        LoadData(false, act.ToDoActivityToken).then(function (data) {
                            act.userLoading = false;
                            act.UserHighscore = data;
                        });
                    }
                } else if (!$scope.userLoaded && !$scope.userLoading) {
                    $scope.userLoading = true;
                    LoadData(false, '').then(function (data) {
                        $scope.userLoading = false;
                        $scope.userLoaded = true;
                        $scope.UserHighscore = data;
                    });
                }
            }

            function DepartmentHighscoreSelect(act) {
                if (angular.isObject(act)) {
                    if (!angular.isObject(act.DepartmentHighscore) && !act.departmentLoading) {
                        // Only add if not already loaded
                        act.DepartmentHighscore = [];
                        act.departmentLoading = true;
                        LoadData(true, act.ToDoActivityToken).then(function (data) {
                            act.departmentLoading = false;
                            act.DepartmentHighscore = data;
                        });
                    }
                } else if (!$scope.departmentLoaded && !$scope.departmentLoading) {
                    $scope.departmentLoading = true;
                    LoadData(true, '').then(function (data) {
                        $scope.departmentLoading = false;
                        $scope.departmentLoaded = true;
                        $scope.DepartmentHighscore = data;
                    });
                }
            }

            function LoadData(isDepartment, toDoActivityToken) {
                var d = $q.defer();

                $http.get('/Module/ToDo/GetHighscore?token=' + $stateParams.token + '&isDepartment=' + isDepartment + '&toDoActivityToken=' + toDoActivityToken).then(function (response) {
                    //console.log(response.data);
                    d.resolve(response.data.Highscore);
                }, function () {
                    $translate('ERROR.GENERAL').then(function (translation) {
                        toastr.error(translation);
                    });
                    d.resolve([]);
                });

                return d.promise;
            }

            function GetSettingsString(property, defaultValue){
                if(angular.isString($scope.Settings[property]) && $scope.Settings[property].length > 0){
                    // String exists and is not empty
                    return $scope.Settings[property];
                }else{
                    // Set default translate value
                    $translate(defaultValue).then(function (translation) {
                        $scope.Settings[property] = translation;
                    });
                    return $scope.Settings[property];
                }
            }

            function GetIcon(act) {
              if (act.IconImage != null) {
                return act.IconImage.ImageFormats.W100;
              } else if (act.Image != null) {
                return act.Image.ImageFormats.W100;
              }
              return "";
            }
            
            // Bindings
            $scope.UserHighscoreSelect = UserHighscoreSelect;
            $scope.DepartmentHighscoreSelect = DepartmentHighscoreSelect;
            $scope.GetSettingsString = GetSettingsString;
            $scope.GetIcon = GetIcon;
        }
    ]);

})();