(function () {
    'use strict';

    angular.module('App')
        .component('policyOverview', {
            templateUrl: '/Scripts/Components/Policies/PolicyOverview/PolicyOverviewComponent.tpl.html',
            controller: ['$timeout', 'Page', 'PolicyViewService', 'ToastFactory', 'ResponsiveService', PolicyOverviewComponentController],
            controllerAs: 'ctrl',
            bindings: {}
        });

function PolicyOverviewComponentController($timeout, Page, PolicyViewService, ToastFactory, ResponsiveService) {
        const ctrl = this;

        ctrl.isLoading = true;
        ctrl.policies = null;
        ctrl.$onInit = init;
        ctrl.isDesktop = ResponsiveService.isDesktop();
        ctrl.displayedPolicy = null;
        ctrl.isListVisible = false;
        ctrl.openPrivacyPolicy = openPrivacyPolicy;
        ctrl.showPolicy = showPolicy;

        function init() {
           // Load policies
           PolicyViewService.getPolicies().then(response => {
                if (Array.isArray(response)) {
                    ctrl.policies = response;

                    if (ctrl.policies.length > 0) {
                        ctrl.policies = ctrl.policies.map((policy) => {
                            if (policy.DateAccepted) {
                                policy.Date = PolicyViewService.getAcceptedDate(policy.DateAccepted);
                            }
                            return policy;
                        })
                        ctrl.isListVisible = ctrl.policies && ctrl.policies.length > 1;
                        ctrl.displayedPolicy = ctrl.policies[0];
                    }
                } else {
                    // Error occured
                    ToastFactory.errorTranslated('ERROR.PAGE_NOT_LOADED');
                    ctrl.policies = null;
                }
            }).catch(() => {
                ctrl.policies = null;
            }).finally(() => ctrl.isLoading = false);
        }

        function showPolicy() {
            return (ctrl.displayedPolicy && ctrl.isDesktop) || !ctrl.isListVisible;
        }

        function openPrivacyPolicy(policy) {
            if (ctrl.isDesktop) {
                displayOtherPolicy(policy);
            } else {
                Page.stateGo('policyView', policy.PolicyToken, null, policy);
            }
        }

        function displayOtherPolicy(policy) {
            const policyElement = document.querySelector('.displayed-policy');
            policyElement.classList.add('hide-policy');

            $timeout(() => {
                ctrl.displayedPolicy = policy;
                policyElement.classList.remove('hide-policy');
            }, 300); 
        }
    }
}

)();