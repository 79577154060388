(function () {
  'use strict';
  var app = angular.module('App');

  app.factory('ProfileNavigationFactory', ['$rootScope', 'Profile', 'Page',
    function ($rootScope, Profile, Page) {
      var navigationOpen = false;

      // Functions
      function close() {
        navigationOpen = false;
      }
      function open() {
        navigationOpen = true;
      }

      // Return Object
      var f = {
        isVisible: function () {
          return navigationOpen;
        },
        toggleNavigation: function () {
          if (!navigationOpen) {
            f.openNavigation();
          } else {
            f.closeNavigation();
          }
        },
        closeNavigation: function () {
          $rootScope.safeApply(function () {
            close();
          })
        },
        openNavigation: function () {
            $rootScope.safeApply(function () {
              // Refresh Profile Info
              Profile.loadProfile();
              open();
          })
        }
      };

      return f;
    }
  ]);
})();