angular
    .module('App.Survey')
    .component('surveyCommentsTab', {
        template: require('./SurveyCommentsTabComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$stateParams', '$rootScope', 'SurveyService', 'TranslationService', 'Page', SurveyCommentsTabController],
        bindings: {
            questions: '<',
            surveyInstanceToken: '<',
            surveyToken: '<',
            departmentToken: '<',
            filters: '<',
            totalNewCommentsNumber: '=',
            isDirectReports: '<',
            surveyData: '<'
        }
    })

function SurveyCommentsTabController($stateParams, $rootScope, SurveyService, TranslationService, Page) {
    const ctrl = this;

    ctrl.getQeustionText = TranslationService.getCurrentLocale;
    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;
    ctrl.openQuestionCommentsPage = openQuestionCommentsPage;

    const unsubscribeFiltersUpdate = $rootScope.$on('SURVEY_FILTERS.UPDATED', () => {
        ctrl.isLaoded = false;
        init();
    });

    function init() {
        SurveyService
            .getQuestionsWithCommentsCount({
                surveyToken: ctrl.surveyToken,
                surveyInstanceToken: ctrl.surveyInstanceToken,
                userGroupIds: ctrl.filters.UserGroupIds,
                departmentToken: ctrl.departmentToken,
                directReport: ctrl.isDirectReports
            })
            .then(({TotalNewCommentsNumber, Questions}) => {
                ctrl.totalNewCommentsNumber = TotalNewCommentsNumber;
                ctrl.questions = Questions;
            })
            .finally(() => {
                ctrl.isLaoded = true;
            })
    }

    function openQuestionCommentsPage(comment) {
          Page.stateGo('questionComments', comment.SurveyQuestionToken, ctrl.surveyInstanceToken, {...ctrl.surveyData}, true, {
            filters: ctrl.filters,
            departmentToken: ctrl.departmentToken,
            isDirectReports: ctrl.isDirectReports,
            expired: $stateParams.expired
        });
    }

    function destroy() {
        unsubscribeFiltersUpdate && unsubscribeFiltersUpdate();
    }
}