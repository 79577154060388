(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('landingPageSocial', ['ResponsiveService',
        function (ResponsiveService) {
            return {
                restrict: 'E',
                link: function (scope, element, attrs) {
                    scope.getContentUrl = function () {
                        var isList = attrs.isListView === "true";
                        return '/Scripts/Components/LandingPage/Modules/Templates/landingpage-social' + (isList ? '-list' : '') + '.tpl.html';
                    }
                },
                template: '<div ng-include="getContentUrl()"></div>',
                scope: {
                    tile: '='
                },
                controller: ['$scope', '$element', '$attrs', '$rootScope', 'Page', '$http', 'MentionService', '$state',
                    function ($scope, $element, $attrs, $rootScope, PageService, $http, MentionService, $state) {

                        // Init
                        InitDirective();

                        function InitDirective() {
                            $scope.loading = true;
                            $scope.loaded = false;
                            $scope.headline = '';
                            $scope.limit = 5;
                            $scope.posts = [];
                            $scope.swiperParams = {
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev'
                                }
                            };

                            // Get item limit/count
                            if ($scope.tile.Settings && $scope.tile.Settings.SocialPostCount) {
                                var postCount = $scope.tile.Settings.SocialPostCount;
                                if (postCount > 0) {
                                    $scope.limit = postCount;
                                }
                            }

                            // Load Posts
                            LoadPosts();
                        }

                        // Methods
                        function LoadPosts() {
                            if(!$scope.tile.AppLink.PathToken){
                                $scope.posts = [];
                                $scope.loading = false;
                                return;
                            }

                            $http.get('/Social/GetSocialWall/' + $scope.tile.AppLink.PathToken + '?limit=' + $scope.limit + '&isLiveTile=true').then(function (response) {
                                var d = response.data;
                                $scope.headline = d.PageTitle;
                                $scope.isSharedView = d.IsSharedView;
                                $scope.posts = [];
                                $scope.loaded = true;
                                for (var i = 0; i < d.Posts.length; i++) {
                                    d.Posts[i]._currentCulture = getCurrentCulture(d.Posts[i].TranslatableEntity);
                                    d.Posts[i]._currentCulture.Text = MentionService.parseMentionsInText(
                                        d.Posts[i]._currentCulture.Text,
                                        d.Posts[i].TaggedUsers,
                                        d.Posts[i].TaggedDepartments,
                                        d.Posts[i].TaggedUserGroups,
                                        d.Settings.HashtagsEnabled
                                    );
                                    $scope.posts.push(d.Posts[i]);
                                }
                                $scope.loading = false;
                            }, function (err) {
                                $scope.loading = false;
                            });
                        }
                        
                        function getCurrentCulture(translatableEntity) {
                            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
                        }

                        function getTransition() {
                            var trans = null;

                            if (!$rootScope.IsFrontPage) {
                                trans = new StateTransitionClass($state.current.name, $state.params.token, $state.params.extra);
                            }

                            return trans;
                        }

                        function ShowSocial(socialPostToken) {
                            var tile = $scope.tile;
                            PageService.stateGo(tile.AppLink.PathState, tile.AppLink.PathToken, tile.AppLink.PathExtra, {
                                socialPostToken: socialPostToken || null,
                                limit: $scope.limit
                            });
                        }

                        function OpenCreatePost() {
                            var tile = $scope.tile;
                            PageService.stateGo(tile.AppLink.PathState, tile.AppLink.PathToken, tile.AppLink.PathExtra, {openCreatePopup: true});
                        }

                        // Bind scope
                        $scope.showSocial = ShowSocial;
                        $scope.openCreatePost = OpenCreatePost;
                    }
                ]
            }
        }
    ]);
})();