(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialCommentsPopup', {
            template: require('./SocialCommentsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$http', '$q', '$translate', '$timeout', 'ToastFactory', 'Profile',
                'BasicHelper', '$filter', 'ConfirmPopupService', 'MentionService', 'NotificationFactory',
                'ProfileViewerService', 'DepartmentViewerService', 'Page', '$stateParams', 'CommentsService',
                'ReportPopupService', socialCommentsPopupController],
            bindings: {
                commentId: '<',
                post: '=',
                settings: '<',
                moduleToken: '@'
            }
        });

    function socialCommentsPopupController($scope, $element, $http, $q, $translate, $timeout, ToastFactory, Profile,
                                           BasicHelper, $filter, ConfirmPopupService, MentionService, NotificationFactory,
                                           ProfileViewerService, DepartmentViewerService, Page, $stateParams, 
                                           CommentsService, ReportPopupService) {

        var ctrl = this, popup;

        ctrl.popupId = 'SocialCommentsPopup';
        ctrl.isPosting = false;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.onClose = onClose;
        ctrl.postComment = postComment;
        ctrl.reportComment = reportComment;
        ctrl.deleteComment = deleteComment;
        ctrl.$onDestroy = destroy;
        ctrl.loadComments = loadComments;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpen() {
            ctrl.loadingComments = true;
            initNewComment();

            ctrl.title = "";
            $translate('LOADING').then(function (translation) {
                ctrl.title = translation + "...";
            });

            $timeout(function () {
                $element.on('click', '.post-hashtag', addHashtagListener);
                $element.on('click', '.post-tag', addTagListener);
            });
        }

        function addHashtagListener(ev) {
            var text = ev.currentTarget.innerText;
            Page.stateGo(
                'socialHashtags',
                ctrl.settings.AccountModuleToken,
                text.replace('#', ''),
            );
            close();
        }

        function addTagListener(ev) {
            var userToken = $(ev.currentTarget).find(".post-tag-user-token");
            if (userToken && userToken[0]) {
                ProfileViewerService.showProfileCard(userToken[0].innerHTML);
            }
            var departmentToken = $(ev.currentTarget).find(".post-tag-department-token");
            if (departmentToken && departmentToken[0]) {
                DepartmentViewerService.showDepartmentCard(departmentToken[0].innerHTML);
            }
        }

        function close() {
            popup && popup.close();
        }

        function onClose() {
            $element.remove();
            popup = null;
            $scope.$parent.$destroy();
        }


        function destroy() {
            $element.off('click', '.post-hashtag', addHashtagListener);
            $element.off('click', '.post-tag', addTagListener);
        }

        function initNewComment() {
            ctrl.newComment = {
                text: '',
                uploadedMedia: []
            };
        }

        function getParentCommentId(comment) {
            if (comment.ParentSocialCommentId) {
                return getParentCommentId(ctrl.post.Comments.find(function (it) {
                    return it.SocialCommentId === comment.ParentSocialCommentId;
                }))
            } else {
                return comment.SocialCommentId;
            }
        }

        function postComment(replyingMessage) {
            var d = $q.defer(), parentId = null, newText = null, postUrl, model,
                isEdit = !!ctrl.newComment.socialCommentId, mentions;

            // Can we post yet?
            if (!ctrl.isPosting) {
                // Is the comment valid?
                ctrl.isPosting = true;
                if ((ctrl.newComment.text.length || (ctrl.newComment.uploadedMedia && ctrl.newComment.uploadedMedia.length))) {

                    // Create comment - Send to API
                    mentions = MentionService.formatMentions(ctrl.newComment);
                    ctrl.newComment.TaggedUsers = mentions.TaggedUsers;
                    ctrl.newComment.TaggedDepartments = mentions.TaggedDepartments;
                    ctrl.newComment.TaggedUserGroups = mentions.TaggedUserGroups;


                    if (replyingMessage) {
                        parentId = getParentCommentId(replyingMessage);

                        if (ctrl.settings.IsTaggingEnabled) {
                            newText = '@' + replyingMessage.CreatedByUserToken + ' ' + ctrl.newComment.text;
                            ctrl.newComment.TaggedUsers && ctrl.newComment.TaggedUsers.push(replyingMessage.CreatedByUserId);
                        }
                    }

                    model = {
                        SocialPostToken: ctrl.post.SocialPostToken,
                        Text: newText || ctrl.newComment.text,
                        TaggedUsers: ctrl.newComment.TaggedUsers,
                        TaggedDepartments: ctrl.newComment.TaggedDepartments,
                        TaggedUserGroups: ctrl.newComment.TaggedUserGroups,
                        MediaList: ctrl.newComment.uploadedMedia.map(function (item) {
                            return item.MediaId;
                        }),
                        ParentSocialCommentId: parentId,
                        AccountModuleToken: ctrl.settings.AccountModuleToken,
                        IgnoreContentSafetyWarning: ctrl.newComment.IgnoreContentSafetyWarning
                    }
                    if (!isEdit) {
                        postUrl = '/Social/PostComment';
                    } else {
                        postUrl = '/Social/EditComment';
                        model.SocialCommentId = ctrl.newComment.socialCommentId;
                    }

                    $http.post(postUrl, model).then(function (response) {
                        updateComments(response.data);
                        isEdit = false;
                        ctrl.newComment.text = '';
                        ctrl.newComment.uploadedMedia = [];
                        ctrl.post.IsSubscribed = true;
                        ctrl.isPosting = false;

                        d.resolve(response.data.Comments);
                    }).catch(resp => {
                        ctrl.isPosting = false;
                        d.reject(resp);
                    })
                } else {
                    // The comment was not long enough
                    ToastFactory.error('You need to write something');
                    ctrl.isPosting = false;
                }
            } else {
                d.reject();
            }

            return d.promise;
        }

        function updateComments(data) {
            if ((ctrl.post.Comments && ctrl.post.Comments.length !== data.Comments.length) || ctrl.newComment.text) {
                ctrl.post.Comments = ctrl.newComment.text || ctrl.newComment.uploadedMedia ?
                    data.Comments.map(comment => {
                        const oldComment = ctrl.post.Comments.find(item => item.SocialCommentId === comment.SocialCommentId);
                        return oldComment ?
                            {
                                ...oldComment,
                                MediaList: comment.MediaList,
                                TranslatableEntity: comment.TranslatableEntity,
                                _currentLocale: comment.TranslatableEntity.LocalizedAlternativeLocale?.CultureId === oldComment._currentLocale.CultureId ?
                                    comment.TranslatableEntity.LocalizedAlternativeLocale : comment.TranslatableEntity.LocalizedLocale
                            } :
                            {
                                ...comment,
                                MediaList: comment.MediaList,
                                TranslatableEntity: comment.TranslatableEntity,
                                _currentLocale: comment.TranslatableEntity.LocalizedLocale
                            }
                    })
                    : ctrl.post.Comments.filter(comment => data.Comments.map(comment => comment.SocialCommentId).includes(comment.SocialCommentId));
            } else {
                ctrl.post.Comments = data.Comments;
            }
            _.each(ctrl.post.Comments, function (comment) {
                comment._currentLocale = comment._currentLocale ?? getCurrentLocale(comment.TranslatableEntity);
                comment.originalText = comment._currentLocale.Text;
                comment._currentLocale.Text = MentionService.parseMentionsInText(
                    comment._currentLocale.Text,
                    comment.TaggedUsers,
                    comment.TaggedDepartments,
                    comment.TaggedUserGroups,
                );
            });
            ctrl.post.TotalComments = ctrl.post.Comments.length;

            NotificationFactory.markNotifications(data.Notifications, false);
            ctrl.post.CommentsTree = buildCommentsTree(ctrl.post.Comments);
        }

        function getCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }


        function onReportSubmit(model, itemId) {
            return ReportPopupService.reportSocialComment({
                socialCommentId: itemId,
                model,
            })
        }
        function onCloseReport(submitted) {
            ReportPopupService.onReportSubmit(submitted);
        }

        function reportComment(comment) {
            ReportPopupService.openReportPopup(onCloseReport, onReportSubmit, comment.SocialCommentId);
        }

        function deleteComment(comment) {
            if (comment.IsDeleteable) {
                CommentsService
                    .deleteComment('/Social/DeleteComment/' + comment.SocialCommentId + '?accountModuleToken=' + ctrl.settings.AccountModuleToken)
                    .then(updateComments)
            }
        }

        function loadComments() {
            $http.get('/Social/GetComments/' + ctrl.post.SocialPostToken + '?accountModuleToken=' + ctrl.settings.AccountModuleToken).then(function (response) {
                ctrl.loadingComments = false;
                $translate('SOCIAL.COMMENTS').then(function (translation) {
                    ctrl.title = translation;
                });
                updateComments(response.data);
            }, function () {
                // Failed
                ctrl.loadingComments = false;
            });
        }

        function buildCommentsTree(comments) {
            return CommentsService
                .buildCommentsTree(comments, 'SocialCommentId', 'ParentSocialCommentId')
        }
    }
})();
