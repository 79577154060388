(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('courseItem', {
            template: require('./CourseItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$translate', '$timeout', 'TrainingService', 'Page', 'toastr', CourseItemController],
            bindings: {
                course: '<',
                onCourseInit: '<',
                showTranslated: '<',
                learningPathToken: '<'
            }
        })

    function CourseItemController($translate, $timeout, TrainingService, Page, toastr) {
        var ctrl = this;

        ctrl.getStatusClass = TrainingService.getItemStatusClass;
        ctrl.getStatus = TrainingService.getItemStatus;
        ctrl.getDurationString = TrainingService.getDurationString;
        ctrl.openCourse = openCourse;
        ctrl.showTimeToRefreshReminder = showTimeToRefreshReminder;
        ctrl.$onInit = init;

        function init() {
            $timeout(() => {
                ctrl.onCourseInit && ctrl.onCourseInit();
            })
        }

        function openCourse(course) {
            if (course.CanBeTaken) {
                toastr.clear();
                Page.stateGo('trainingCourse', course.CourseToken, ctrl.learningPathToken)
            } else {
                $translate('TRAINING.COURSE.NOT_ALLOWED').then(function (translation) {
                    toastr.warning(translation);
                });
            }
        }
        function showTimeToRefreshReminder(complianceStatus, completionRate) {
            if (complianceStatus === 1 && completionRate === 0) {
                return refresherBadgeState[1];
            }
            if (complianceStatus === 1 && completionRate > 0) {
                return refresherBadgeState[2];
            }
            if (complianceStatus === 2 && completionRate > 0) {
                return refresherBadgeState[3];
            }
            if (complianceStatus === 2 && completionRate === 0) {
                return refresherBadgeState[4];
            }
            return false;
        }
        
        const refresherBadgeState = {
            1: {
                text: "Time to renew",
                background: 'var(--general-background-color)'
            },
            2: {
                text: 'In renewal',
                background: 'var(--general-background-color)'
            },
            3: {
                text: 'In renewal',
                background: 'var(--warning-color)'
            },
            4: {
                text: 'Time to renew',
                background: 'var(--warning-color)'
            }
        }
    }
})();