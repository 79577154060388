(() => {
    'use strict';

    angular
        .module('App')
        .component('taskDetailsPopup', {
            template: require('./TaskDetailsPopupComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', 'Page', '$timeout', 'TaskManagementRenderService',
                TaskDetailsPopupController],
            bindings: {
                moderateAllowed: '<',
                issue: '<',
                token: '<'
            }
        });

    function TaskDetailsPopupController($element, $scope, Page, $timeout, TaskManagementRenderService) {
        var ctrl = this, popup;

        ctrl.loading = true;
        ctrl.close = close;
        ctrl.openEventDetails = openEventDetails;
        ctrl.onRegistered = onRegistered;
        
        function onRegistered(popupCtrl) {
            popupCtrl.open();
        }

        function openEventDetails(issue, popupCtrl) {
            popup = popupCtrl;

            // Start loading data if needed
            if (ctrl.issue === undefined) {
                if (ctrl.token !== undefined) {
                    loadIssue(ctrl.token);
                } else {
                    var watcherUnregister = $scope.$watch('ctrl.token', function (newVal, oldVal) {
                        if (newVal) {
                            watcherUnregister();
                            loadIssue(ctrl.token);
                        }
                    });
                }
            } else {
                ctrl.loading = false;
            }
        }

        function loadIssue(token) {
            ctrl.loading = true;

            TaskManagementRenderService.getTaskPreviewData(null, token).then(issue => {
                ctrl.issue = issue;
                ctrl.loading = false;
            }).catch(() => {
                ctrl.loading = false;
            });
        }

        function close(skipBack) {
            if (ctrl.isClosing) {
                return;
            }

            ctrl.isClosing = true;

            $timeout(() => {
                $scope.$destroy();
            });
            
            if (!skipBack) {
                Page.stateGoBack();
            }
        }
    }
})();