(() => {
    'use strict';

    angular
        .module('App')
        .component('contactItem', {
            template: require('./ContactItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['Page', 'ProfileViewerService', 'ActionSheetService', '$translate', 'BasicHelper', ContactItemComponent],
            bindings: {
                contactItem: '<',
                showDepartment: '<'
            }
        });

    function ContactItemComponent(Page, ProfileViewerService, ActionSheetService, $translate, BasicHelper) {
        const ctrl = this;
        let contactButtons = [], actionSheet;

        ctrl.$onInit = init;
        ctrl.openProfile = openProfile;
        ctrl.handlePhoneAction = handlePhoneAction;
        ctrl.handleChatAction = handleChatAction;
        ctrl.handleEmailAction = handleEmailAction;
        ctrl.handleSmsAction = handleSmsAction;
        ctrl.getTitleToShow = getTitleToShow;


        function init() {
            formatEmailAndPhone();
            setLabelTranslatons();
        }

        function openProfile() {
            if (ctrl.contactItem.UserToken) {
                ProfileViewerService.showProfileCard(ctrl.contactItem.UserToken);
            }
        }

        function handlePhoneAction(event) {
            contactButtons = [];

            var headData = {
                description: ctrl.callTranslation + ' ' + ctrl.contactItem.Name
            }

            if (isPhoneSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: 'PHONE',
                        text: ('+' + ctrl.contactItem.PhoneCode + ' ' + ctrl.contactItem.Phone),
                        icon: 'phone',
                        onClick: function () {
                            window.location.href = 'tel:+' + ctrl.contactItem.PhoneCode + '-' + ctrl.contactItem.Phone;
                        }
                    }
                );
            }

            if (isBusinessPhoneSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: 'BUSINESS_PHONE',
                        text: ('+' + ctrl.contactItem.BusinessPhoneCode + ' ' + ctrl.contactItem.BusinessPhone),
                        icon: 'phone',
                        onClick: function () {
                            window.location.href = ctrl.contactItem.BusinessPhoneCallUrl;
                        }
                    }
                );
            }

            if (isPhoneSet(ctrl.contactItem) || isBusinessPhoneSet(ctrl.contactItem)) {
                actionSheet = ActionSheetService.create(contactButtons, event.currentTarget, headData, { name: 'contact-item'});
                actionSheet.show();
            }
        }

        function handleChatAction() {
            Page.stateGo('chat', ctrl.contactItem.ChatModuleToken, null,
                {
                    Name: ctrl.contactItem.Name,
                    UserToken: ctrl.contactItem.UserToken
                });
        }

        function handleEmailAction() {
            contactButtons = [];

            var headData = {
                description: ctrl.emailTranslation + ' ' + ctrl.contactItem.Name
            }

            if (isEmailSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: ctrl.primaryEmailTranslation,
                        text: (ctrl.contactItem.Email),
                        icon: 'email',
                        onClick: function () {
                            BasicHelper.mailTo(ctrl.contactItem.Email);
                        }
                    }
                );
            }

            if (isSecondaryEmailSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: ctrl.secondaryEmailTranslation,
                        text: (ctrl.contactItem.SecondaryEmail),
                        icon: 'email',
                        onClick: function () {
                            BasicHelper.mailTo(ctrl.contactItem.SecondaryEmail);
                        }
                    }
                );
            }

            if (isEmailSet(ctrl.contactItem) || isSecondaryEmailSet(ctrl.contactItem)) {
                actionSheet = ActionSheetService.create(contactButtons, event.currentTarget, headData, { name: 'contact-item'});
                actionSheet.show();
            }
        }

        function handleSmsAction() {
            contactButtons = [];

            var headData = {
                description: ctrl.smsTranslation + ' ' + ctrl.contactItem.Name
            }

            if (isPhoneSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: 'PHONE',
                        text: ('+' + ctrl.contactItem.PhoneCode + ' ' + ctrl.contactItem.Phone),
                        icon: 'sms',
                        onClick: function () {
                            window.location.href = 'sms:+' + ctrl.contactItem.PhoneCode + '-' + ctrl.contactItem.Phone;
                        }
                    }
                );
            }

            if (isBusinessPhoneSet(ctrl.contactItem)) {
                contactButtons.push(
                    {
                        subText: 'BUSINESS_PHONE',
                        text: ('+' + ctrl.contactItem.BusinessPhoneCode + ' ' + ctrl.contactItem.BusinessPhone),
                        icon: 'sms',
                        onClick: function () {
                            window.location.href = 'sms:+' + ctrl.contactItem.BusinessPhoneCode + '-' + ctrl.contactItem.BusinessPhone;
                        }
                    }
                );
            }

            if (isPhoneSet(ctrl.contactItem) || isBusinessPhoneSet(ctrl.contactItem)) {
                actionSheet = ActionSheetService.create(contactButtons, event.currentTarget, headData, { name: 'contact-item'});
                actionSheet.show();
            }
        }

        function isPhoneSet(contact) {
            if (!contact.PhoneNumber) {
                return false;
            } else if (contact.PhoneNumber.Phone && contact.PhoneNumber.Phone.length > 0) {
                return true;
            }
        }

        function isBusinessPhoneSet(contact) {
            if (!contact.BusinessPhoneNumber) {
                return false;
            } else if (contact.BusinessPhoneNumber.Phone && contact.BusinessPhoneNumber.Phone.length > 0) {
                return true;
            }
        }

        function isEmailSet(contact) {
            return contact.Email && contact.Email.length > 0;
        }

        function isSecondaryEmailSet(contact) {
            return contact.ShareSecondaryEmail && contact.SecondaryEmail && contact.SecondaryEmail.length > 0;
        }

        function formatEmailAndPhone() {
            if (ctrl.contactItem.Phone === "") {
                ctrl.contactItem.Phone = null;
            }

            if (ctrl.contactItem.BusinessPhone === "") {
                ctrl.contactItem.BusinessPhone = null;
            }

            if (ctrl.contactItem.SecondaryEmail === "") {
                ctrl.contactItem.SecondaryEmail = null;
            }

            if (ctrl.contactItem.Email === "") {
                ctrl.contactItem.Email = null;
            }
        }

        function setLabelTranslatons() {
            $translate('CONTACT.CALL')
                .then(translation => ctrl.callTranslation = translation);
            $translate('CONTACT.SMS')
                .then(translation => ctrl.smsTranslation = translation);
            $translate('CONTACT.EMAIL')
                .then(translation => ctrl.emailTranslation = translation);
            $translate('CONTACT.PRIMARY_EMAIL')
                .then(translation => ctrl.primaryEmailTranslation = translation);
            $translate('CONTACT.SECONDARY_EMAIL')
                .then(translation => ctrl.secondaryEmailTranslation = translation);
        }

        function getTitleToShow() {
            if (ctrl.showDepartment && ctrl.contactItem.Title && ctrl.contactItem.DepartmentName) {
                ctrl.titleToShow = ctrl.contactItem.Title + " · " + ctrl.contactItem.DepartmentName;
            }

            if (ctrl.contactItem.Title && (!ctrl.contactItem.DepartmentName || !ctrl.showDepartment)) {
                ctrl.titleToShow = ctrl.contactItem.Title;
            }

            if (ctrl.showDepartment && !ctrl.contactItem.Title && ctrl.contactItem.DepartmentName) {
                ctrl.titleToShow = ctrl.contactItem.DepartmentName;
            }

            return ctrl.titleToShow;
        }
    }
})();
