(function () {
    'use strict';

    angular
        .module('App')
        .component('switchUserPopup', {
            template: require('./SwitchUserPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$scope', '$timeout', 'Page', SwitchUserPopupController],
            bindings: {
                profile: '<',
                usersList: '<',
                triggerBtnPosition: '<'
            }
        })

    function SwitchUserPopupController($element, $scope, $timeout, Page) {
        const ctrl = this;

        ctrl.onClose = onClose;
        ctrl.$onInit = onInit;
        ctrl.loginAsNewUser = loginAsNewUser;
        ctrl.userClicked = userClicked;

        function onInit() {
            ctrl.selectedToken = ctrl.profile?.UserToken;
            ctrl.usersList = ctrl.usersList || [];
            
            if (!ctrl.usersList.length && ctrl.profile) {
                ctrl.usersList = [ctrl.profile];
            }

            positionActionsheetBelowProfileName();
        }

        function positionActionsheetBelowProfileName() {
            $timeout(() => {
                const actionsheet = $element.find('.actionsheet')[0];
                if (actionsheet && ctrl.triggerBtnPosition) {
                    $element[0].style.setProperty('--pin-actionsheet-top', `${ctrl.triggerBtnPosition + actionsheet.offsetHeight/2 + 15}px`);
                }
            });
        }

        function loginAsNewUser() {
            ctrl.closeAction();
            Page.stateGo('logout');
        }

        function userClicked(user) {
            if (user.UserToken === ctrl.profile.UserToken) {
                ctrl.closeAction();
                return;
            }
        }

        function onClose() {
            $scope.$destroy();
            $element.remove()
        }
    }
})();