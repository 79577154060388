(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('landingPageHighscore', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Components/LandingPage/Modules/Templates/landingpage-highscore.tpl.html',
                scope: {
                    tile: '='
                },
                controller: ['$scope', '$element', '$attrs', '$rootScope', 'Page', '$http', '$state',
                    function ($scope, $element, $attrs, $rootScope, PageService, $http, $state) {
                        // Init
                        InitDirective();
                        function InitDirective() {
                            $scope.loading = true;
                            
                            LoadUserHighscore();
                        }

                        // Methods
                        function LoadUserHighscore() {
                            if ($scope.tile.AppLink && $scope.tile.AppLink.PathToken && $scope.tile.AppLink.PathToken.length) {
                                $http.post('/Highscore/GetHighScoreOfCurrentUser/' + $scope.tile.AppLink.PathToken).then(function (response) {
                                    var d = response.data;

                                    $scope.userPoint = d;
                                    $scope.loading = false;
                                }, function (err) {
                                    $scope.loading = false;
                                });
                            } else {
                                $scope.loading = false;
                            }
                        }

                        function getTransition() {
                            var trans = null;

                            if (!$rootScope.IsFrontPage) {
                                trans = new StateTransitionClass($state.current.name, $state.params.token, $state.params.extra);
                            }

                            return trans;
                        }

                        function ShowHighscore() {
                            var tile = $scope.tile;
                            PageService.stateGo(tile.AppLink.PathState, tile.AppLink.PathToken, tile.AppLink.PathExtra);
                        }

                        // Bind scope
                        $scope.showHighscore = ShowHighscore;
                    }
                ]
            }
        }
    ]);
})();