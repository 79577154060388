(() => {
    angular
        .module('App')
        .component('activityIndicator', {
            template: require('./activityIndicatorComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                indicatorClass: '@',
                fontawesomeIcon: '@'
            },
            controller: [ActivityIndicatorController]
        })

    function ActivityIndicatorController() {
        const ctrl = this;
        ctrl.$onInit = onInit;

       function onInit() {
            ctrl.fontawesomeIcon = ctrl.fontawesomeIcon || 'fas fa-clock'; 
        }
    }
})();