(() => {
    'use strict';

    angular
        .module('App')
        .factory('TaskManagementService', [TaskManagementService]);
    
    function TaskManagementService() {

        return {
            updateIssue: updateIssue,
            setIssuesToShow: setIssuesToShow,
            getCompletableOverdueIssues: getCompletableOverdueIssues,
        }

        function getCompletableOverdueIssues(tasks) {
            return tasks.filter(issue => issue.IsUserAllowedToCompleteIssue && issue.CountAsOverdue);
        }

        function updateIssue(issues, issue) {
            const issueIndex = issues.findIndex(i => i.IssueId === issue.IssueId);
            if (issueIndex > -1) {
                issues[issueIndex] = issue;
            } else {
                issues.push(issue);
            }
            return issues;
        }

        function setIssuesToShow(issues) {
            const overviewIssues = issues.filter(task => task.ShowInOverview);
            const managerIssues = findManagerIssues(issues);
            const overviewOverseeingIssues = managerIssues.filter(task => task.ShowInOverview);
            return {overviewIssues, managerIssues, overviewOverseeingIssues};
        }

        function findManagerIssues(issues) {
            return issues.filter(issue => {
                const issueIsBeforeNow = moment(issue.EndTimeLocal).isBefore(moment().startOf('day'));
                if (!issue.IsUserAllowedToCompleteIssue && !issueIsBeforeNow) {
                    return issue;
                }
            });
        }
    }
})();