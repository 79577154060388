(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialWall', {
            template: require('./SocialWallComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$element', '$timeout', '$interval', '$stateParams', 'SocialService',
                'Profile', 'ReactionsService', 'Page', 'SocialWallService', 'SocialSortingService', 'events', SocialWallController],
            bindings: {
                postsData: '<',
                posts: '<',
                morePostsToLoad: '<',
                socialGroupId: '<',
                openCreatePopup: '=?',
                isLiveTile: '<',
                isPostsInitialLoaded: '<',
                startDate: '<',
                endDate: '<',
                isHashtag: '<',
                hashtag: '<',
                sortType: '<',
            }
        });

    function SocialWallController($scope, $rootScope, $element, $timeout, $interval, $stateParams, SocialService, Profile,
                                  ReactionsService, Page, SocialWallService, SocialSortingService, events) {
        const ctrl = this;
        let checkNewPostsInterval, removePostUpdateWatcher, removeCreateDeleteWatcher,
            removeIsPostsInitialLoaded, appUnHideWatcher;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onSortChange = onSortChange;
        ctrl.paging = paging;
        ctrl.postDeleted = postDeleted;
        ctrl.postEdit = postEdit;
        ctrl.openReactionsViewer = openReactionsViewer;
        ctrl.updateSearchString = updateSearchString;
        ctrl.onPostCreated = onPostCreated;
        ctrl.scrollToNewPosts = scrollToNewPosts;

        function init() {
            ctrl.loadingPosts = false;
            ctrl.sortingOptions = SocialSortingService.getOptions(true);

            if (!ctrl.posts) {
                ctrl.posts = ctrl.postsData.Posts;
            }
            if (_.isUndefined(ctrl.morePostsToLoad)) {
                ctrl.morePostsToLoad = ctrl.postsData.MorePosts;
            }
            ctrl.settings = ctrl.postsData.Settings;
            ctrl.settings.IsSharedView = ctrl.postsData.IsSharedView;

            $timeout(() => {
                $element.find('social-post').first().addClass('newest');
                if (ctrl.postsData.socialPostToken) {
                    scrollToPost(ctrl.postsData.socialPostToken);
                }
            });

            if (ctrl.openCreatePopup) {
                SocialWallService.showCreatePost(ctrl);
            }

            removePostUpdateWatcher = $rootScope.$on('social:postUpdated', (ev, updatedPost) => {
                const postIndexToUpdate =
                    ctrl.posts.findIndex(post => post.SocialPostToken === updatedPost.SocialPostToken);

                if (postIndexToUpdate > -1) {
                    ctrl.posts[postIndexToUpdate] = updatedPost;
                }
            });

            removeCreateDeleteWatcher = $rootScope.$on('social:postCreated', (ev, data) => {
                onPostCreated(data)
            });

            removeIsPostsInitialLoaded = $scope.$watch('ctrl.isPostsInitialLoaded', () => {
                if (ctrl.isPostsInitialLoaded && !ctrl.isLiveTile && !ctrl.isHashtag) {
                    SocialWallService.initPullToRefresh(() => {
                        updatePosts().then(hasNewPosts => {
                            if (hasNewPosts) {
                                SocialWallService.logActivities(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId);
                                $timeout(() => {
                                    markNewestPost();
                                })
                            }
                        });
                    });

                    checkForNewPosts();
                }
            });

            appUnHideWatcher = $rootScope.$on(events.APP_UNHIDE, () => {
                // Only instant check if we already have an interval which might have been skipped
                if (checkNewPostsInterval &&
                    checkNewPostsInterval.$$state.status == 0) {
                    checkForNewPosts(true);
                }
            })
        }

        function destroy() {
            removePostUpdateWatcher && removePostUpdateWatcher();
            removeCreateDeleteWatcher && removeCreateDeleteWatcher();
            removeIsPostsInitialLoaded && removeIsPostsInitialLoaded();
            appUnHideWatcher && appUnHideWatcher();
            $interval.cancel(checkNewPostsInterval);
            PullToRefresh.destroyAll();
        }

        function scrollToPost(token) {
            const interval = $interval(() => {
                const element = angular.element($element).find('[data-guid=' + token + ']');
                if (element.length) {
                    angular.element('html').scrollTop(element.offset().top - 60);
                    $interval.cancel(interval);
                }
            }, 100);
        }

        function onSortChange() {
            ctrl.loadingPosts = true;

            SocialService
                .getPosts(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId, 0, 20,
                    ctrl.sortTypeId, ctrl.startDate, ctrl.endDate)
                .then(({Posts, MorePosts}) => {
                    ctrl.posts = Posts;
                    ctrl.morePostsToLoad = MorePosts;
                    ctrl.loadingPosts = false;
                })
                .catch(() => {
                    ctrl.loadingPosts = false;
                    ctrl.morePostsToLoad = false;
                });
        }

        function paging() {
            if (!ctrl.loadingPosts && ctrl.morePostsToLoad) {
                ctrl.loadingPosts = true;

                if (!!ctrl.isHashtag) {
                    SocialService.getPostsWithHashtag({
                        token: $stateParams.token,
                        offset: ctrl.posts.length,
                        limit: 10,
                        hashtag: $stateParams.extra
                    }).then(resp => {
                        const data = resp.data;
                        ctrl.posts.push(...data.Posts)
                        ctrl.morePostsToLoad = data.MorePosts;
                        ctrl.loadingPosts = false;
                    }).catch(() => {
                        ctrl.loadingPosts = false;
                    });
                    return;
                }

                SocialService
                    .getPosts(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId, ctrl.posts.length, 10,
                        ctrl.sortTypeId, ctrl.startDate, ctrl.endDate)
                    .then(data => {
                        ctrl.posts.push(...data.Posts);
                        ctrl.morePostsToLoad = data.MorePosts;
                        ctrl.loadingPosts = false;
                    })
                    .catch(() => {
                        ctrl.loadingPosts = false;
                        ctrl.morePostsToLoad = false;
                    });
            }
        }

        function postDeleted(post) {
            const index = ctrl.posts.indexOf(post);
            if (index >= 0) {
                ctrl.posts.splice(index, 1);
            }
        }

        function postEdit($post) {
            SocialWallService.showCreatePost(ctrl, $post);
        }

        function openReactionsViewer(postToken) {
            ReactionsService.openReactionsViewer(postToken, ctrl.settings.AllowExtendedReactions, 'Social');
        }

        function updateSearchString(name) {
            ctrl.hashTagSearchString = '#' + name;
        }

        function onPostCreated(data) {
            if (ctrl.settings.PinnedPostsEnabled) {
                if (!ctrl.loadingPosts) {
                    ctrl.loadingPosts = true;
                    updatePosts().then(() => {
                        ctrl.loadingPosts = false;
                    })
                }
            } else {
                const post = setCurrentCulture(data.Post);
                    ctrl.posts.unshift(post);
                ctrl.postsData.Timestamp = data.Timestamp;
                $timeout(() => {
                    markNewestPost();
                });
            }
        }

        function setCurrentCulture(post) {
            post.TranslatableEntity._currentCulture = selectCurrentLocale(post.TranslatableEntity);

            if (post.SocialGroupTranslatableEntity) {
                post.SocialGroupTranslatableEntity._currentCulture = selectCurrentLocale(post.SocialGroupTranslatableEntity);
            }

            if (post.PollData?.TranslatableEntity) {
                post.PollData._currentLocale = selectCurrentLocale(post.PollData.TranslatableEntity);
            }

            function selectCurrentLocale(translatableEntity) {
                return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                    translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
            }
            
            return post;
        }

        function checkForNewPosts(instant) {
            var runNewPostCheck = () => {
                getNewPosts().then(hasNewPosts => {
                    $timeout(() => {
                        if (hasNewPosts) {
                            if (angular.element(window).scrollTop() === 0) {
                                angular.element(window)
                                    .scrollTop(angular.element('.newest').offset().top
                                        - angular.element('.base-top').height() - 10);
                                markNewestPost();
                            }
                            ctrl.showScrollToNewPostsButton = true;
                            $timeout(() => {
                                angular.element(window).on('scroll.social', () => {
                                    ctrl.showScrollToNewPostsButton = false;
                                    angular.element(window).off('scroll.social');
                                });
                            });
                        }
                    })
                });
            };

            $interval.cancel(checkNewPostsInterval);
            checkNewPostsInterval = $interval(() => {
                if (Page.isAppVisible() === false) {
                    return;
                }

                runNewPostCheck();
            }, 60 * 1000);

            if (instant) {
                runNewPostCheck();
            }
        }

        function getNewPosts() {
            return SocialWallService
                .getNewPosts(ctrl.postsData.AccountModuleToken, ctrl.postsData.Timestamp, ctrl.socialGroupId, ctrl.sortTypeId, ctrl.startDate, ctrl.endDate)
                .then(data => {
                    ctrl.postsData.Timestamp = data.Timestamp;
                    if (data.Posts.length) {
                        ctrl.posts.unshift(...data.Posts);
                    }
                }).catch(() => {
                    $interval.cancel(checkNewPostsInterval);
                })
        }

        function updatePosts() {
            return SocialService
                .getPosts(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId, 0, 20,
                    ctrl.sortTypeId, ctrl.startDate, ctrl.endDate)
                .then(data => {
                    ctrl.postsData.Timestamp = data.Timestamp;
                    ctrl.posts = data.Posts;
                    ctrl.morePostsToLoad = data.MorePosts;

                    $timeout(() => {
                        markNewestPost();
                    });

                    checkForNewPosts();
                })
                .catch(() => {
                    ctrl.loadingPosts = false;
                    ctrl.morePostsToLoad = false;
                });
        }

        function markNewestPost() {
            angular.element('.newest').removeClass('newest');
            angular.element('social-post').first().addClass('newest');
        }

        function scrollToNewPosts() {
            SocialWallService.logActivities(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId);
            angular.element('html').off('scroll.social');
            angular.element('html, body').animate({scrollTop: 0}, 250);
            ctrl.showScrollToNewPostsButton = false;
        }
    }
})();