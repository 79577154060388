(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('memberCard', {
                    parent: 'base',
                    url: '/membercard/:token',
                    templateUrl: '/MemberCard/Index',
                    resolve: {
                        memberCardInfo: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/MemberCard/GetCard/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'MemberCardController as MemberCardCtrl'
                });
        }
    ]);

    app.controller('MemberCardController', ['Page', '$compile', '$scope', '$element', '$http', '$state', 'toastr', '$translate', 'memberCardInfo', '$timeout', 'Menu', 'TimeLocale', 'BasicHelper', 'events',
        function (Page, $compile, $scope, $element, $http, $state, toastr, $translate, memberCardInfo, $timeout, Menu, TimeLocale, BasicHelper, events) {
            var ctrl = this;
            ctrl.Page = Page;

            // Init
            Page.setTitle(memberCardInfo.data.PageTitle);
            Page.showBackButton();
            $scope.showTC = memberCardInfo.data.ShowTC;
            $scope.hasMemberCard = memberCardInfo.data.HasMemberCard;
            $scope.MemberCard = memberCardInfo.data.MemberCard;
            $scope.CardImageUrl = memberCardInfo.data.CardImageUrl;
            $scope.CardText = prepareText(memberCardInfo.data.CardText);
            $scope.showAcceptButton = true;

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
            
            //console.log(memberCardInfo);

            // Translations
            $scope.Translations = {};
            //BasicHelper.getSettingString(workMoodInfo.data.Settings.IntroText, 'WORKMOOD.INTRO').then(function (text) { $scope.Translations.IntroText = text; });
            //BasicHelper.getSettingString(workMoodInfo.data.Settings.PickedText, 'WORKMOOD.PICKED').then(function (text) { $scope.Translations.PickedText = text; });
            //BasicHelper.getSettingString(workMoodInfo.data.Settings.LastWeekText, 'WORKMOOD.LASTWEEK').then(function (text) { $scope.Translations.LastWeekText = text; });

            function Init() {
                //$scope.showIntro = false;

            }

            function prepareText(html) {
                if (html) {
                    var el = angular.element('<div></div>');
                    el.html(html);
                    angular.element('#cardtext').html(($compile(el.contents())($scope)));
                }
            }

            // accept terms and conditions
            $scope.AcceptTC = function (noLoading) {
                if (!$scope.showPicked && !$scope.loading) {
                    if (!noLoading) {
                        // Send to server
                        $http.post('/MemberCard/AcceptTC').then(function (response) {
                            // Hide TC
                            $scope.showTC = false;
                        }, function () {
                            // Reset
                            $scope.loading = false;
                            $translate('ERROR.GENERAL').then(function (translation) {
                                toastr.error(translation);
                            });

                            Init();
                        });
                    } else {
                        $scope.loading = false;
                    }
                }
            };

            // accept terms and conditions
            $scope.ShowTC = function (noLoading) {
                $scope.showTC = true;
                $scope.showAcceptButton = false;
            };

            // accept terms and conditions
            $scope.HideTC = function (noLoading) {
                $scope.showTC = false;
                $scope.showAcceptButton = false;
            };

            // Run Init
            Init();
        }
    ]);
})();