(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('myProgress', {
            template: require('./MyProgressComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$stateParams', 'TrainingService', MyProgressController],
            bindings: {
                points: '<',
                badges: '<'
            }
        });

    function MyProgressController($http, $stateParams, TrainingService) {
        const ctrl = this;

        ctrl.$onInit = init;

        function init() {
            TrainingService.getMyProgress($stateParams.token).then(({Points, Badges}) => {
                ctrl.points = Points;
                ctrl.badges = Badges;
            })
        }
    }
})();