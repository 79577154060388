(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('search', {
                    parent: 'base',
                    url: '/search',
                    params: {
                        backState: null,
                        data: {}
                    },
                    resolve: {
                        searchSettings: () => true
                    },
                    template: `<search-page class="view-page"></search-page>`,
                    controller: 'SearchController as ctrl',
                })
        }])
        .controller('SearchController', ['$rootScope', 'Page',
            function ($rootScope, Page) {
                Page.showBackButton();

                Page.setTitle('SEARCH.TITLE');
            }]);
})();