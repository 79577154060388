(function () {
    'use strict';

    angular
        .module('App')
        .component('contentLiveTile', {
            template: require('./ContentLiveTileComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$filter', '$scope', 'Page', 'MentionService', ContentLiveTileController],
            bindings: {
                tile: '<'
            }
        })

    function ContentLiveTileController($http, $filter, $scope, Page, MentionService) {
        const ctrl = this
        let limit;


        ctrl.$onInit = init;
        ctrl.selectCategory = selectCategory;
        ctrl.readMore = readMore;
        ctrl.onContentRead = onContentRead;
        ctrl.selectCurrentLocale = selectCurrentLocale;

        $scope.$watch('ctrl.tile.Badge', function(newValue, oldValue) {
            if (newValue !== oldValue && ctrl.contentData) {
                getLiveTileData().then(resp => {
                    ctrl.contentData.Categories.forEach((category, index) => {
                        category.BadgeCount = resp.data.Categories[index].BadgeCount
                    })
                });
            }
        });

        function init() {
            ctrl.accountModuleToken = ctrl.tile.AppLink.PathToken;
            limit = ctrl.tile.Settings.ArticleCount || 20;

            if (ctrl.accountModuleToken === null || ctrl.accountModuleToken === undefined) {
                ctrl.isLoaded = true;
            } else {
                getLiveTileData().then(resp => {
                    ctrl.isLoaded = true;
                    ctrl.contentData = resp.data;
                    if (ctrl.contentData.Categories && ctrl.contentData.Categories.length) {
                        ctrl.contentData.Categories[0].selected = true;
                        ctrl.selectedCategoryToken = ctrl.contentData.Categories[0].ContentCategoryToken;
                    }
                });
            }
        }

        function getLiveTileData() {
            const showBadges = !ctrl.tile.HideBadge && ctrl.tile.Settings && ctrl.tile.Settings.AdvancedMode;
            return $http.get('/contentModule/GetLiveTileData/' + ctrl.accountModuleToken + '?limit=' + limit + '&showBadges=' + showBadges)
        }

        function selectCategory(category) {
            ctrl.contentData.Categories.find(category => {
                return category.selected === true
            }).selected = false;
            category.selected = true
            ctrl.selectedCategoryToken = category.ContentCategoryToken;

            $http.post('/ContentModule/GetContents', {
                token: ctrl.accountModuleToken,
                categoryToken: category.ContentCategoryToken,
                offset: 0,
                limit: 20
            }).then(resp => {
                ctrl.contentData.NextOffset = resp.data.NextOffset;
                ctrl.contentData.Items = resp.data.Items.map(item => parseMentions(item));
            });
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function readMore() {
            Page.stateGo('content', ctrl.accountModuleToken, ctrl.selectedCategoryToken);
        }

        function onContentRead(categoryToken, substract, isSwipeCategory) {
            const category = ctrl.contentData.Categories.find(category => category.ContentCategoryToken === categoryToken),
                allCategory = ctrl.contentData.Categories.find(category => category.ContentCategoryToken === null);

            if (category) {
                category.BadgeCount = category.BadgeCount - substract;

                if (!isSwipeCategory) {
                    if (category.ContentCategoryToken !== null) {
                        if (allCategory) {
                            allCategory.BadgeCount = allCategory.BadgeCount - substract;
                        }
                    }
                } else {
                    if (category.ContentCategoryToken === null) {
                        ctrl.contentData.Categories.forEach(category => {
                            category.BadgeCount = 0;
                        });
                    }
                }
            }
        }

        function parseMentions(item) {
            if (item.TranslatableEntity?.LocalizedLocale?.BodyText) {
                item.TranslatableEntity.LocalizedLocale.BodyText = MentionService.parseMentionsInText(
                    item.TranslatableEntity.LocalizedLocale.BodyText,
                    item.TaggedUsers,
                    item.TaggedDepartments,
                    false
                );

                if (item.TranslatableEntity.LocalizedAlternativeLocale?.BodyText) {
                    item.TranslatableEntity.LocalizedAlternativeLocale.BodyText = MentionService.parseMentionsInText(
                        item.TranslatableEntity.LocalizedAlternativeLocale.BodyText,
                        item.TaggedUsers,
                        item.TaggedDepartments,
                        false
                    );
                }
            }

            if (item.BodyText) {
                item.BodyText = MentionService.parseMentionsInText(
                    item.BodyText,
                    item.TaggedUsers,
                    item.TaggedDepartments,
                    false
                );
            }
            
            return item
        }
    }
})();