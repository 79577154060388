(function () {
    angular.module('App.Catalog')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('itemDetails', {
                    parent: 'base',
                    url: '/catalog/item/:token/:extra/:data',
                    template: require('./ItemDetails.tpl.html'),
                    params: {
                        backState: null,
                        data: null
                    },
                    trackPageView: false,
                    resolve: {
                        listItemData: ['base', '$http', '$stateParams', function (base, $http, $stateParams) {
                            if (base) {
                                return $http.post('/Catalog/GetItem/' + $stateParams.token, {
                                    dataListItemToken: $stateParams.extra,
                                    catalogCategoryToken: $stateParams.data
                                });
                            }
                        }]
                    },
                    controller: 'ItemDetailsController as ctrl'
                });
        }
        ])
        .controller('ItemDetailsController', ['$stateParams', 'listItemData', 'Page', 'TrackingService', 'ENTITY_TYPES', function ($stateParams, listItemData, Page, TrackingService, ENTITY_TYPES) {
            var ctrl = this;

            listItemData.data.Item.TranslatableEntity.PreferTranslatedVersion && listItemData.data.Item.TranslatableEntity.LocalizedAlternativeLocale ?
                Page.setTitle(listItemData.data.Item.TranslatableEntity.LocalizedAlternativeLocale.Title) :
                Page.setTitle(listItemData.data.Item.TranslatableEntity.LocalizedLocale.Title);

            const isGlobalSearchResult = $stateParams.backState?.state === 'search';
            const isSearchResult = isGlobalSearchResult || $stateParams.backState?.options?.showSearch;

            var trackingMetaData = null;
            if (isSearchResult) {
                trackingMetaData = {
                    isSearchResult: true,
                    isGlobalSearchResult: isGlobalSearchResult
                };
            }

            TrackingService.trackPageView(ENTITY_TYPES.CATALOG.ITEM, listItemData.data.Item.DataListItemToken, 'ItemDetails', trackingMetaData);

            Page.showBackButton();
            ctrl.listItemData = listItemData.data;
        }]);
})();
