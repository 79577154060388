(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingLessonItem', {
            template: require('./TrainingLessonItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$element', '$filter', 'TrainingService', 'TimeLocale', TrainingLessonItemController],
            bindings: {
                lesson: '<',
                onInitDone: '<'
            }
        });

    function TrainingLessonItemController($scope, $element, $filter, TrainingService, TimeLocale) {
        var ctrl = this;

        ctrl.getModuleStatus = TrainingService.getItemStatus;
        ctrl.getModuleClass = TrainingService.getItemStatusClass;
        ctrl.getDurationString = TrainingService.getDurationString;
        ctrl.getTimeTo = getTimeTo;
        ctrl.isTimeInFuture = isTimeInFuture;

        ctrl.$onInit = init;

        function init() {
            ctrl.onInitDone && ctrl.onInitDone();

            $scope.$watchGroup(['ctrl.lesson.CanBeTaken', 'ctrl.lesson.New', 'ctrl.lesson.State', 'ctrl.lesson.Passed'],
                () => {
                    $element[0].classList = '';
                    $element.addClass(`training-item lesson training-lesson-${ctrl.lesson.CourseLessonToken}`);
                    $element.addClass(ctrl.getModuleClass(ctrl.lesson));
                }, true);
        }

        function getTimeTo() {
            var dateNow = TimeLocale.getMomentDate();

            return dateNow.to(ctrl.lesson.CanRedoAt, true);
        }

        function isTimeInFuture(date) {
            var dateNow = TimeLocale.getMomentDate().toDate(),
                dateInput = TimeLocale.getMomentDate(date).toDate();

            return dateNow < dateInput;
        }

    }
})();