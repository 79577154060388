(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider', 'zendeskWidgetProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider, zendeskWidgetProvider) {
            $stateProvider
                .state('zendesk',
                    {
                        parent: 'base',
                        url: '/zendesk/:token',
                        templateUrl: '/Zendesk',
                        resolve: {
                            data: [
                                '$http', '$stateParams', 'base', function($http, $stateParams, base) {
                                    if (base) {
                                        return $http.post('/Zendesk/GetData/' + $stateParams.token);
                                    }
                                }
                            ]
                        },
                        controller: 'ZendeskController as ZendeskCtrl'
                    });
            zendeskWidgetProvider.init({
                manual: true,
                beforePageLoad: function (zE) {
                    zE.hide();
                }
            });
        }]);

    app.controller("ZendeskController", ['$rootScope', '$window', '$scope', 'data', 'Page', 'events', '$http', '$stateParams', 'zendeskWidget', '$timeout', '$interval',
        function ($rootScope, $window, $scope, data, Page, events, $http, $stateParams, zendeskWidget, $timeout, $interval) {
            var ctrl = this;
            ctrl.Page = Page;

			var updateStyleInterval;
            var updateStyleRepeats = 15;
            var zendeskData = data.data;

            var initZendesk = function () {
                var accountUrl = zendeskData.HostName + ".zendesk.com";
                zendeskWidget.settings({
                    accountUrl: accountUrl
                });
                // idempotent, so can call multiple times with no further effect
                zendeskWidget.injectZendeskScript();
				$timeout(function () {
					zendeskWidget.setLocale(zendeskData.Culture.i18n);
					zendeskWidget.identify({
						name: zendeskData.UserName,
						email: zendeskData.Email
					});
					zendeskWidget.activate({hideOnClose: true});

				}, 3000);


                updateStyleInterval = $interval(function() {
					setZendeskStyles();

				    if (--updateStyleRepeats === 0) {
						$interval.cancel(updateStyleInterval);
                    }
                }, 500)
            };

            var hideZendesk = function() {
				$interval.cancel(updateStyleInterval);
				angular.element('#webWidget').css('display', 'none');
			};

            var showZendesk = function () {
				angular.element('#webWidget').css('display', 'block');
			};

			var setZendeskStyles = function() {
				var widget = angular.element('#webWidget');
				var head = widget.contents().find('head');
				var width = $window.innerWidth;

				if (!widget) return;

				// Styles depend of device width
				widget.css('top', (width > 992 && '61px') || '56px');
				widget.css('left', (width > 1300 && '361px') || (width > 992 && '281px') || 0);
				widget.css('width', (width > 1300 && 'calc(100% - 361px)') || (width > 992 && 'calc(100% - 281px)') || '100%');
				widget.css('height', 'calc(100% - 56px)');
				widget.css('margin', 0);

				if (head.get(0) && !head.find('#zendeskStyles').length) {
					head.append(angular.element(
						'<style id="zendeskStyles" type="text/css">' +
							'.src-component-button-ButtonNav-button {display: none}' +
							'.src-component-frame-Frame-right {width: 100%!important}' +
							'.src-component-button-ButtonSecondary-button {display: none!important;}' +
							'.src-component-container-Container-container {width: 100%!important;}' +
							'.src-component-container-ScrollContainer-content {max-height: none!important;}' +
							'#embed > div {width: 100%!important}' +
						'</style>'));
				}
			};

            function initController() {
            	if (zendeskWidget.isLoaded()) {
					showZendesk();
				} else {
					initZendesk();
				}

                // Set Page Title
                Page.setTitle(zendeskData.PageTitle);
                Page.showBackButton();

                // Bind data to scope
                ctrl.data = data.data;
            }

			initController();

			$scope.$on('$destroy', function() {
				hideZendesk();
			});

            $scope.$on('ToggleMenu', function(e, val) {
				val ? hideZendesk() : showZendesk()
			});
        }
    ]);
})();