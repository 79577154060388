(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('TrainingVoluntaryService', ['$http', '$q', 'TrainingService', TrainingVoluntaryService])

    function TrainingVoluntaryService($http, $q, TrainingService) {

        return {
            getCategories: getCategories,
            getRelated: getRelated,
            getModules: getModules,
            searchModulesAndLessons: searchModulesAndLessons
        };

        function getCategories() {
            return $http.get(`${TrainingService.getBaseUrl()}/Category/GetList`).then(resp => resp.data)
        }

        function getModules() {
            const url = TrainingService.isNewTraining() ?
                '/TrainingV2/Voluntary/GetModules' :
                '/Training/CourseModule/GetList';

            return $http.get(url).then(resp => resp.data)
        }

        function searchModulesAndLessons(searchString) {
            const url = TrainingService.isNewTraining() ?
                '/TrainingV2/Voluntary/Search' :
                '/Training/Voluntary/Get';

            return $http.post(url, {SearchText: searchString}).then(resp => resp.data);
        }

        function getRelated(token) {
            const modulesUrl = TrainingService.isNewTraining() ?
                '/TrainingV2/Voluntary/GetModulesByCategory/' :
                '/Training/CourseModule/GetListByCategory/';

            const getRelatedCategories = $http.get(`${TrainingService.getBaseUrl()}/Category/GetRelatedList/` + token).then(resp => resp.data);
            const getChildCategories = $http.get(`${TrainingService.getBaseUrl()}/Category/GetChildList/` + token).then(resp => resp.data);
            const getModules = $http.get(modulesUrl + token).then(resp => resp.data);

            return $q.all({
                relatedCategories: getRelatedCategories,
                childCategories: getChildCategories,
                modules: getModules
            }).then(({relatedCategories, childCategories, modules}) => {
                return {
                    RelatedCategories: relatedCategories.Items || [],
                    ChildCategories: childCategories.Items || [],
                    PageTitle: modules.PageTitle,
                    AccountModuleToken: modules.AccountModuleToken,
                    Modules: modules.Modules,
                    Color: modules.Color,
                    CategoryImage: modules.CategoryImage,
                }
            })
        }
    }
})();   