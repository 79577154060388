(function () {
    'use strict';

    angular
        .module('App.Common')
        .factory('Page', ['$http', '$q', '$state', '$stateParams', '$rootScope', 'cfpLoadingBar',
            '$timeout', '$compile', '$sce', '$window', 'BasicHelper', 'NativeFactory', 'events',
            function ($http, $q, $state, $stateParams, $rootScope, cfpLoadingBar, $timeout, $compile, $sce,
                      $window, BasicHelper, NativeFactory, events) {

                window.r_e = $rootScope;

                var isLoading = false;
                var isAppVisible = true;
                var title = "";
                var subTitle = "";
                var directionClass = "forward";
                var showMenu = false;
                var showBase = false;
                var settings = null;
                var accountModuleCssClasses = [];
                var goBackListener = null;
                var pageModalState = false;
                var storedNav = {};
                var clearStoredNavState = false;
                var loadingPageSettings = [];
                var scrollPos = 0;
                var isPinCodePageVisible = false;
                var prevBackgroundColor = null;
                var _nextState = null;
                var stateAfterPin = {
                    state: '',
                    params: {}
                }
                var stateBeforePin = {
                    state: '',
                    params: {}
                };

                let navigationHistory = [];
                let isBack = true;

                $rootScope.$on(events.PINCODE_DIDSHOW, () => isPinCodePageVisible = true);
                $rootScope.$on(events.PINCODE_DIDHIDE, () => isPinCodePageVisible = false);
                $rootScope.isBrowserBack = true;

                var f = {
                    title: function () {
                        return title;
                    },
                    subTitle: function () {
                        return subTitle;
                    },
                    onTitleClick: () => {
                        $rootScope.$broadcast('openHeaderMenu');
                    },
                    setTitle: function (newTitle, newSubTitle) {
                        title = newTitle;
                        subTitle = (!newSubTitle) ? '' : newSubTitle;
                    },
                    setSubTitle: function (newSubTitle) {
                        subTitle = (!newSubTitle) ? '' : newSubTitle;
                    },
                    isLoading: function () {
                        return isLoading;
                    },
                    startLoading: function () {
                        isLoading = true;
                        cfpLoadingBar.start();
                    },
                    stopLoading: function () {
                        cfpLoadingBar.complete();
                        $timeout(function () {
                            isLoading = false;
                        }, 500);
                    },
                    removeInitialLoading: function () {
                        $('body').removeClass('initial-load');
                        $('body').removeClass('app-splashscreen-background-image');
                        $('body').removeClass('app-splashscreen-background');
                        NativeFactory.basic.appIsReady();
                    },

                    getVersion: function () {
                        return $('body').data('version');
                    },

                    baseShown: function () {
                        return showBase;
                    },
                    showBase: function () {
                        showBase = true;
                    },
                    setAppVisibility: (isVisible) => {
                        isAppVisible = isVisible
                    },
                    isAppVisible: () => isAppVisible,
                    isPinCodePageVisible: () => isPinCodePageVisible,
                    getSettings: function () {
                        return settings;
                    },
                    setSettings: function (data) {
                        settings = data;

                        angular.forEach(settings.HomeMedia, function (item) {
                            item.UrlEncoded = $sce.trustAsResourceUrl(item.UrlEncoded);
                        });

                        f.renderBodyClasses();
                        // Send out event broadcast
                        $rootScope.$broadcast(events.SETTINGS_LOADED);
                    },
                    setAccountModuleCssClasses: function (data) {
                        accountModuleCssClasses = data;
                    },
                    getCustomCssClassesForAccountModule: function (moduleName, moduleToken) {
                        var accountModuleToken = moduleToken ?? f.getCurrentToken();
                        var moduleTypeClass = f.getBaseViewClass();
                        var classesObj = accountModuleCssClasses.find(x => x.AccountModuleToken === accountModuleToken);

                        if (!classesObj) {
                            return '';
                        }

                        if (moduleName) {
                            return classesObj.CssClasses.map(x => `${moduleName}-custom-${x}`).join(' ');
                        }

                        return classesObj.CssClasses.map(x => `${moduleTypeClass}-custom-${x}`).join(' ');
                    },
                    getAccountModuleCssClasses: function (token) {
                        var moduleTypeClass = f.getBaseViewClass();
                        var classesObj = accountModuleCssClasses.find(x => x.AccountModuleToken === token);

                        if (!classesObj) {
                            return '';
                        }

                        return classesObj.CssClasses.map(x => `${moduleTypeClass}-custom-${x}`).join(' ');
                    },
                    setCustomCssClass: function (moduleToken, cssClass) {
                        $timeout(() => {
                            if (moduleToken) {
                                const customClasses = cssClass ? cssClass : f.getAccountModuleCssClasses(moduleToken);
                                if (customClasses) {
                                    const $element = document.querySelector('.base-view');
                                    customClasses.split(' ').forEach(customClass => {
                                        customClass && $element.classList.add(customClass);
                                    });
                                }
                            }
                        });
                    },
                    removeCustomCssClass: function (moduleToken, cssClass) {
                        if (moduleToken) {
                            const customClasses = cssClass ? cssClass : f.getAccountModuleCssClasses(moduleToken);
                            if (customClasses) {
                                const $element = document.querySelector('.base-view');
                                customClasses.split(' ').forEach(customClass => {
                                    customClass && $element.classList.remove(customClass)
                                });
                            }
                        }
                    },
                    renderBodyClasses: function () {
                        // Add class to body if HighscoreLevels are enabled
                        $('body').toggleClass('highscore-levels-enabled', (settings.Base.HighscoreLevelsEnabled));

                        // add current user department path
                        $('body').removeClass(function (index, className) {
                            return (className.match(/(^|\s)dp-\S+/g) || []).join(' ');
                        });
                        for (var i = 0; i < settings.UserDepartmentHierarchyPath.length; i++) {
                            $('body').addClass('dp-' + settings.UserDepartmentHierarchyPath[i]);
                        }
                        if (settings.UserRolesPath) {
                            for (var i = 0; i < settings.UserRolesPath.length; i++) {
                                $('body').addClass('ug-' + settings.UserRolesPath[i]);
                            }
                        }
                    },
                    clearSettings: function () {
                        settings = null;
                    },
                    loadSettings: function () {
                        var d = $q.defer();
                        var promise = d.promise;

                        if (loadingPageSettings.length === 0) {

                            loadingPageSettings.push(d);

                            $http.get('/Index/GetSettings').then(function (response) {
                                f.setSettings(response.data);

                                loadingPageSettings.forEach(o => {
                                    o.resolve();
                                });
                                loadingPageSettings = [];
                            }, function () {
                                f.clearSettings();

                                loadingPageSettings.forEach(o => {
                                    o.resolve();
                                });

                                loadingPageSettings = [];
                            });
                        } else {
                            loadingPageSettings.push(d);
                        }

                        return promise;
                    },

                    clearBodyClasses: function () {
                        $('body').removeClass('initial-load');
                        //$('body').attr('class', 'body');
                    },
                    getDirection: function () {
                        return directionClass;
                    },
                    clearDirection: function () {
                        directionClass = "forward";
                    },
                    nextDirection: function (direction) {
                        if (direction === 'backward') {
                            directionClass = "backward";
                        } else if (direction === 'enter') {
                            directionClass = "";
                        } else {
                            directionClass = "forward";
                        }
                    },

                    stateGo: function (state, token, extra, params, notify, restParams) {
                        isBack = false;
                        $rootScope.isBrowserBack = false;

                        stateAfterPin = {
                            state,
                            params: {
                                token,
                                extra,
                                data: params
                            }
                        }

                        if (state !== 'home') {
                            $rootScope.IsFrontPage = false;
                            directionClass = "forward";

                            if (f.getMenuState()) {
                                f.toggleMenu(false);
                            }

                            // Clear user variable aswell
                            $state.go(state, {
                                token,
                                extra,
                                user: null,
                                data: params,
                                ...restParams
                            }, {notify: notify !== undefined ? notify : true});
                        } else {
                            cleanNavigationHistory();
                            f.stateGoBack();
                        }
                    },

                    stateGoLink: function (appLink, skipBackNavigation) {
                        if (appLink.PreUrl) {
                            $http.get(appLink.PreUrl).then(function (response) {
                                $timeout(function () {
                                    if (NativeHelper.isIOS()) {
                                        var a = document.createElement("a");
                                        a.href = response.data;
                                        a.target = "_blank";
                                        a.click();
                                    } else {
                                        window.open(response.data, "_blank");
                                    }
                                })
                            });
                            return true;
                        } else if (_.isObject(appLink)
                            && _.isString(appLink.PathState)
                            && appLink.PathState.length
                            && _.isString(appLink.PathToken)
                            && appLink.PathToken.length) {

                            stateAfterPin = {
                                state: appLink.PathState,
                                params: {
                                    token: appLink.PathToken,
                                    extra: appLink.PathExtra,
                                }
                            }

                            if (isPinCodePageVisible == false) {
                                f.stateGo(
                                    appLink.PathState,
                                    appLink.PathToken,
                                    appLink.PathExtra,
                                    skipBackNavigation ? { skipBackNavigation } : null,
                                    true,
                                );
                            }

                            return true;
                        }
                        return false;
                    },
                    stateGoBack: function () {
                        directionClass = "backward";
                        isBack = true;
                        $rootScope.isBrowserBack = false;
                        
                        if (f.getMenuState()) {
                            f.toggleMenu(false);
                        }

                        const {state, token, extra, params, notify, restParams} = getBackHistoryState();

                        // Clear user variable aswell
                        $state.go(state || 'home', {
                            token,
                            extra,
                            user: null,
                            data: params,
                            ...restParams
                        }, {notify: notify !== undefined ? notify : true});
                        removeLastHistoryState();
                        state === 'home' && f.setTitle('');
                    },
                    stateGoPrevious: () => {
                        f.stateGoBack()
                    },
                    stateReload: function () {
                        sessionStorage.setItem('navigationState', JSON.stringify(navigationHistory));
                        $state.reload();
                    },
                    goToAppLink: function (appLinkId) {
                        // Get app link data from API
                        $http.get('/Navigation/GetAppLink?id=' + appLinkId).then(function (result) {
                            if (_.isObject(result.data)) {
                                // Go to link
                                f.stateGoLink(result.data);
                            }
                        }, function (err) {
                            // error happend and we ignore that
                        });
                    },
                    setNextState: function (nextState) {
                        _nextState = nextState;
                    },
                    getNextState: () => _nextState,
                    toggleMenu: function (show) {
                        if (show !== undefined) {
                            showMenu = show;
                        } else {
                            showMenu = !showMenu;
                        }

                        // Send out event broadcast
                        $rootScope.$broadcast('ToggleMenu', showMenu);
                    },
                    getMenuState: function () {
                        return showMenu;
                    },

                    showBackButton: function (state, token, extra, options, notify, restParams) {
                        $rootScope.ShowBackButton = true;
                        if (goBackListener !== null) {
                            goBackListener();
                        }
                        goBackListener = $rootScope.$on('GlobalBackClick', function () {
                            // hook which will be deleted after native design will be merged
                            if ($state.current.name === state && $stateParams.token === token && $stateParams.extra === extra) {
                                f.stateGo('home');
                                return;
                            }

                            if (state !== undefined) {
                                f.stateGo(state, token, extra, {
                                    skipHistory: true,
                                    removeHistory: true
                                }, notify, restParams);
                            } else {
                                f.stateGoBack();
                            }
                        });
                    },
                    showBackButtonFunction: function (func) {
                        $rootScope.ShowBackButton = true;
                        if (goBackListener !== null) {
                            goBackListener();
                        }
                        goBackListener = $rootScope.$on('GlobalBackClick', func);
                    },

                    clearBackButton: function () {
                        $rootScope.ShowBackButton = false;
                        if (goBackListener !== null) {
                            goBackListener();
                        }
                        goBackListener = null;
                    },

                    triggerBackButton: function () {
                        $rootScope.$broadcast('GlobalBackClick');
                    },

                    showMenuButton: function (show) {
                        $rootScope.ShowMenuButton = show;
                    },

                    showMessagePopup: function (options, callback) {
                        var newScope = $rootScope.$new(true);
                        var defaultOptions = {
                            headline: '',
                            text: '',
                            icon: '',
                            cssClass: ''
                        };
                        $.extend(defaultOptions, options);

                        newScope.PopupContent = {
                            headline: defaultOptions.headline,
                            text: defaultOptions.text,
                            icon: defaultOptions.icon,
                            cssClass: defaultOptions.cssClass
                        };

                        newScope.PopupClosed = function () {
                            if (callback !== undefined) {
                                callback();
                            }
                        };

                        var $popup = $compile("<popup></popup>")(newScope);
                        $('body').append($popup);
                    },

                    showHelpPopup: function (body, callback) {
                        var newScope = $rootScope.$new(true);
                        newScope.Data = {
                            Body: body
                        };

                        newScope.PopupClosed = function () {
                            if (callback !== undefined) {
                                callback();
                            }
                        };

                        var $helpPopup = $compile("<help-popup><help-popup>")(newScope);
                        $('body').append($helpPopup);
                    },

                    appLinkGo: function (appLink) {
                        if (appLink && appLink.PathState && appLink.PathState.length) {
                            f.stateGo(appLink.PathState, appLink.PathToken, appLink.PathExtra);
                        }
                    },
                    getBaseViewClass: function () {
                        switch (f.getCurrentPage().name) {
                            case 'socialOverview':
                                return 'social';
                            case 'taskPopup':
                                return 'taskmanagement';
                            case 'catalogCategory':
                                return 'catalog-category base-view-catalog';
                            case 'itemDetails':
                                return 'item-details base-view-catalog';
                            case 'trainingCourseModuleMandatory':
                            case 'trainingCourseModuleVoluntary':
                            case 'trainingPhase':
                            case 'trainingCourse':
                            case 'trainingCategory':
                            case 'trainingMy':
                            case 'trainingProgress':
                            case 'trainingManager':
                            case 'trainingVoluntary':
                            case 'learningPath':
                                return 'training';
                            case 'trainingLesson':
                                return 'training-lesson base-view-training';
                            case 'contentLibrary':
                                return 'library';
                            case 'userManagement':
                                return 'user-management';
                            case 'userManagementCreate':
                                return 'user-management-create';
                            case 'landingpage':
                                return 'landing-page'
                            case 'surveySingleDepartment':
                            case 'directReports':
                                return 'survey';
                            case 'serviceForm':
                                return 'service-form';
                            default:
                                return f.getCurrentPage().name;
                        }
                    },
                    getFullBaseViewIdentifier: function () {
                        let baseViewClass = `base-view-${f.getBaseViewClass()} ${f.getCustomCssClassesForAccountModule()}`;

                        if (f.getCurrentToken()) {
                            baseViewClass += ` module-${f.getBaseViewClass()}-${f.getCurrentToken()}`
                        }
                        return baseViewClass
                    },
                    getCurrentPage: function () {
                        return $state.current;
                    },
                    getCurrentToken: function () {
                        return $stateParams.token;
                    },
                    getPageAsModalState: function () {
                        return pageModalState;
                    },

                    setPageAsModalState: function (state) {
                        pageModalState = state;
                    },

                    // Toggle inert state for bottom popups
                    setPopupInert: function (state) {
                        $timeout(function () {
                            var $popups = $('.popup-viewer');
                            var $bottomPopups = $('.popup-viewer').not(':last');

                            if (state === true) {
                                $bottomPopups.each(function (i, el) {
                                    el.inert = state;
                                });
                            } else {
                                $popups.each(function (i, el) {
                                    el.inert = state;
                                });
                            }

                        }, 100);
                    },

                    // Toggle inert state for main container. Use for popovers, popups
                    setMainContainerInert: function (state) {
                        f.setPopupInert(state);
                        var $content = $('.base-container');
                        var $popupsCount = $('.popup-viewer').length;

                        // disable body overflow if any popup exist
                        var overflowState = state === false && $popupsCount > 1 ? true : state;
                        f.setBodyOverflow(overflowState, $popupsCount > 1);

                        // updating container inert after last popup has been closed
                        if ($content && $content.get(0) && $popupsCount < 2) {
                            $content.get(0).inert = state;
                        }
                    },

                    // Toggle inert state for page content. Use for menu
                    setPageContentInert: function (state) {
                        f.setBodyOverflow(state);
                        var $pageContent = $('.base-content-wrapper .content-wrapper');
                        if ($pageContent && $pageContent.get(0)) {
                            $pageContent.get(0).inert = state;
                        }
                    },

                    setBodyOverflow: function (state, skipScrollPos) {
                        var $body = $('body');

                        // Toggle body scrolling
                        if (state === true) {

                            // Fix IOS body scrolling
                            if (BasicHelper.isIOS()) {
                                scrollPos = skipScrollPos ? scrollPos : $window.pageYOffset;
                                $body.addClass('fixed');
                            }

                            $body.addClass('no-scroll');
                        } else {
                            $body.removeClass('no-scroll');

                            // Fix IOS body scrolling (return scroll position)
                            if (BasicHelper.isIOS()) {
                                $window.scrollTo(0, scrollPos);
                                $body.removeClass('fixed');
                            }
                        }
                    },
                    getClearStoredNavState: function () {
                        return clearStoredNavState;
                    },
                    setClearStoredNavState: function (state) {
                        clearStoredNavState = state;
                    },
                    getStoredNav: function (pageToken) {
                        return storedNav[pageToken];
                    },
                    setStoredNav: function (nav, pageToken) {
                        storedNav[pageToken] = nav;
                    },
                    clearStoredNav: function () {
                        storedNav = {};
                    },
                    setBackgroundColor: function (newColor) {
                        // Set the previous background color
                        var currentBackground = $('body')[0].style.backgroudColor;
                        if (_.isString(currentBackground) === true) {
                            prevBackgroundColor = currentBackgroundColor;
                        } else {
                            prevBackgroundColor = null;
                        }
                        // Change the background color
                        $('body').css('backgroundColor', newColor);
                    },
                    revertBackgroundColor: function () {
                        if (prevBackgroundColor !== null) {
                            $('body').css('backgroundColor', prevBackgroundColor);
                        } else {
                            $('body').css('backgroundColor', '');
                        }
                        prevBackgroundColor = null;
                    },
                    overlayColorAdjustment: function (backgroundColor, textColor) {
                        // iPhoneX
                        // Set body background color to black to help with "footer" background being black as well
                        f.setBackgroundColor(backgroundColor);
                        // Set native statusbar to match fullscreen view
                        NativeFactory.visual.changeStatusBar(backgroundColor, textColor);
                    },
                    revertOverlayColorAdjustment: function () {
                        // iPhoneX
                        // Remove body background color to help with "footer" background
                        f.revertBackgroundColor();
                        // Revert native statusbar colors
                        NativeFactory.visual.useDefaultStatusBar();
                    },
                    stateGoAfterPin,
                    saveFromPinState,
                    resetStateAfterPin,
                    stateGoLinkV2,
                    isLandingPage,
                    getBackHistoryState,
                    hasHistory,
                    addHistoryState,
                    cleanNavigationHistory,
                    setInitialBackState,
                    removeLastHistoryState,
                    getRestParams,
                    generateRestParamsString
                };

                function stateGoLinkV2(entityLink) {
                    const appLink = {PathToken: entityLink.AccountModuleToken, Params: entityLink.Params};
                    
                    switch (entityLink.EntityType) {
                        case 'Content.Post':
                            appLink.PathState = 'contentItem';
                            appLink.PathExtra = '?accountModuleToken=' + entityLink.AccountModuleToken;
                            appLink.PathToken = entityLink.ContentToken;
                            break;
                        case 'ContentLibrary.Post':
                        case 'Content.Library':
                            appLink.PathState = 'contentLibrary';
                            appLink.PathExtra = '?accountModuleToken=' + entityLink.AccountModuleToken;
                            appLink.PathToken = entityLink.ContentToken;
                            break;
                        case 'Social.Post':
                            appLink.PathState = 'socialPost';
                            appLink.PathExtra = '?accountModuleToken=' + entityLink.AccountModuleToken;
                            appLink.PathToken = entityLink.SocialToken;
                            break;
                        case 'Catalog.Item':
                            appLink.PathState = 'itemDetails';
                            appLink.PathExtra = entityLink.CatalogItemToken;
                            appLink.Params = entityLink.CatalogCategoryToken;
                            break;
                        case 'Catalog.Category':
                            appLink.PathState = 'catalogCategory';
                            appLink.PathExtra = entityLink.CatalogCategoryToken;
                            break;
                    }

                    stateAfterPin = {
                        state: appLink.PathState,
                        params: {
                            token: appLink.PathToken,
                            extra: appLink.PathExtra,
                        }
                    }
                    if (appLink.PathState) {
                        f.stateGo(
                            appLink.PathState,
                            appLink.PathToken,
                            appLink.PathExtra,
                            appLink.Params || null
                        );
                    } else {
                        console.error('No state for this EntityType')
                    }
                }

                function stateGoAfterPin() {
                    const link = stateBeforePin.params.path?.split('?')[1];

                    if (stateAfterPin.state !== '') {
                        f.stateGo(
                            stateAfterPin.state,
                            stateAfterPin.params.token,
                            stateAfterPin.params.extra,
                            stateAfterPin.params.data
                        )
                    } else if (stateBeforePin.state !== '' && stateBeforePin.state !== 'pin') {
                        f.stateGo(
                            stateBeforePin.state,
                            stateBeforePin.params.token,
                            stateBeforePin.params.extra,
                            stateBeforePin.params.data
                        )
                    } else if (stateBeforePin.state === '' && stateBeforePin.params.path
                        && stateBeforePin.params.path.includes('link') && link) {
                        const searchParams = new URLSearchParams(link);
                        f.stateGoLink({
                            PathState: searchParams.get('PathState'),
                            PathToken: searchParams.get('PathToken'),
                            PathExtra: searchParams.get('PathExtra')
                        });
                    } else {
                        f.stateGo('home');
                    }
                }

                function resetStateAfterPin() {
                    stateAfterPin = {
                        state: '',
                        params: {}
                    }
                }

                function saveFromPinState(state, params) {
                    stateBeforePin = {
                        state,
                        params
                    };
                }

                function isLandingPage() {
                    const currentPage = f.getCurrentPage().name;
                    return currentPage === 'landingpage' || currentPage === 'home' || currentPage === 'childLanding';
                }

                function addHistoryState(previousState) {
                    if (previousState?.state && !isBack) {
                        navigationHistory.push(previousState);
                    }
                }
                
                function hasHistory() {
                    return navigationHistory.length > 0;
                }

                function getBackHistoryState() {
                    if (!navigationHistory?.length) {
                        return {}
                    }

                    const currentHistoryStep = navigationHistory[navigationHistory.length - 1];
                    return {...currentHistoryStep, restParams: getRestParams(currentHistoryStep)};
                }

                function removeLastHistoryState() {
                    navigationHistory.pop();
                }
                
                function cleanNavigationHistory() {
                    navigationHistory.splice(0, navigationHistory.length);
                    sessionStorage.removeItem('navigationState');
                }

                function setInitialBackState() {
                    window.onbeforeunload = () => {
                        sessionStorage.setItem('navigationState', JSON.stringify(navigationHistory));
                    }
                    
                    window.onpopstate = () => {
                        isBack = true;
                        $rootScope.isBrowserBack = true;
                    }

                    navigationHistory = JSON.parse(sessionStorage.getItem('navigationState')) || [];
                }

                function getRestParams(params) {
                    const keysToExclude = ['token', 'extra', 'data', 'isPreview', 'user'];
                    return Object.fromEntries(Object.entries(params).filter(([key]) => !keysToExclude.includes(key)))
                }    
                
                function generateRestParamsString(params) {
                    const nonEmptyParams = Object
                        .entries(params)
                        .filter(([_, value]) => value !== null && value !== undefined && value !== "");
                    const restParams = new URLSearchParams(Object.fromEntries(nonEmptyParams));

                    return restParams.size ? ('?' + restParams.toString()) : '';
                }

                return f;
            }
        ]);
})();