(() => {
    angular
        .module('App.Catalog')
        .config(['$stateProvider', $stateProvider => {
            $stateProvider
                .state('catalogCategory', {
                    parent: 'base',
                    url: '/catalog/category/:token/:extra',
                    template: require('./CatalogCategory.tpl.html'),
                    params: {
                        backState: null,
                        data: {}
                    },
                    trackPageView: false,
                    resolve: {
                        catalogCategoryData: ['base', '$http', '$stateParams', function (base, $http, $stateParams) {
                            if (base) {
                                return $http.post('/Catalog/GetSubCategoriesAndItems/' + $stateParams.token, {
                                    catalogCategoryToken: $stateParams.extra
                                });
                            }
                        }]
                    },
                    controller: 'CatalogCategoryController as ctrl'
                });
        }])
        .controller('CatalogCategoryController', ['$stateParams', 'catalogCategoryData', 'Page', 'TrackingService', 'ENTITY_TYPES', function ($stateParams, catalogCategoryData, Page, TrackingService, ENTITY_TYPES) {

            var ctrl = this;

            catalogCategoryData.data.Category.TranslatableEntity.PreferTranslatedVersion && catalogCategoryData.data.Category.TranslatableEntity.LocalizedAlternativeLocale ?
                Page.setTitle(catalogCategoryData.data.Category.TranslatableEntity.LocalizedAlternativeLocale.Title) :
                Page.setTitle(catalogCategoryData.data.Category.TranslatableEntity.LocalizedLocale.Title);
            
            ctrl.catalogCategoryData = catalogCategoryData.data;

            const isGlobalSearchResult = $stateParams.backState?.state === 'search';
            const isSearchResult = isGlobalSearchResult || $stateParams.backState?.options?.showSearch;

            var trackingMetaData = null;
            if (isSearchResult) {
                trackingMetaData = {
                    isSearchResult: true,
                    isGlobalSearchResult: isGlobalSearchResult
                };
            }

            TrackingService.trackPageView(ENTITY_TYPES.CATALOG.CATEGORY, catalogCategoryData.data.Category.CatalogCategoryToken, 'CategoryView', trackingMetaData);
            Page.showBackButton();
        }]);
})();
