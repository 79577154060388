(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonSwipeCard', {
            template: require('./LessonSwipeCardComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', '$rootScope', 'ResponsiveService', LessonSwipeCardController],
            bindings: {
                question: '<',
                onSelectAnswer: '<',
                onResetAnswer: '<',
                showAnswers: '<',
                expired: '<',
                moreCardsInStack: '<',
                isLast: '<'
            }
        })

    function LessonSwipeCardController($timeout, $element, $rootScope, ResponsiveService) {
        const ctrl = this;
        let startX = 0, startY = 0, moveX = 0, moveY = 0
        let answerHighlight, card;

        ctrl.$onInit = init;
        ctrl.answer = answer;
        ctrl.reset = reset;

        function init() {
            ctrl.trueOption = ctrl.question.AnswerOptionList[0];
            ctrl.falseOption = ctrl.question.AnswerOptionList[1];
            ctrl.answered = isAnswered();

            if (!ctrl.showAnswers && !ctrl.expired) {
                $timeout(() => setSwipe())
            }
        }

        function answer(option, selectedAnswer) {
            if (!ctrl.expired && !ctrl.showAnswers && !ctrl.answered) {
                if (!ctrl.isLast) {
                    const moveX = $rootScope.isRTL ? (selectedAnswer ? -1 : 1) : (selectedAnswer ? 1 : -1);
                    animateAnswer(moveX, 0, () => {
                        sendAnswer(option)
                    });
                } else {
                    sendAnswer(option)
                }
            }
        }

        function sendAnswer(option) {
            ctrl.onSelectAnswer(option, () => {
                card.closest('.question-wrapper').classList.remove('no-ng-animation');
                ctrl.answered = isAnswered();
                cancel(0);
            })
        }

        function reset() {
            cancel(200, () => {
                ctrl.onResetAnswer();
                ctrl.answered = isAnswered();
                setSwipe();
            });
        }

        function isAnswered() {
            return ctrl.trueOption.selected || ctrl.falseOption.selected;
        }

        function setSwipe() {
            answerHighlight = $element.find('.answer-highlight')[0];
            card = $element.find('.card')[0];
            !ResponsiveService.isDesktop() && card.addEventListener('pointerdown', onPointerDown);
        }

        function onPointerDown({clientX, clientY}) {
            startX = clientX
            startY = clientY
            card.addEventListener('pointermove', onPointerMove)
            card.addEventListener('pointerup', onPointerUp)
            card.addEventListener('pointerleave', onPointerUp)
        }

        function onPointerMove({clientX, clientY}) {
            moveX = clientX - startX
            moveY = clientY - startY
            if (Math.abs(moveX) > 5 || Math.abs(moveY) > 5) {
                setTransform(moveX, moveY, moveX / window.innerWidth * 50)
            }
        }

        function onPointerUp() {
            card.removeEventListener('pointermove', onPointerMove)
            card.removeEventListener('pointerup', onPointerUp)
            card.removeEventListener('pointerleave', onPointerUp)
            if (Math.abs(moveX) >= card.clientWidth / 3) {
                animateAnswer(moveX, moveY, () => {
                    const option = $rootScope.isRTL ? (moveX > 0 ? ctrl.falseOption : ctrl.trueOption) : (moveX > 0 ? ctrl.trueOption : ctrl.falseOption);
                    sendAnswer(option)
                });
            } else if (moveX || moveY) {
                cancel(400);
            }
        }

        function animateAnswer(moveX, moveY, callback) {
            const flyX = (Math.abs(moveX) / moveX) * window.innerWidth * 1.3,
                flyY = (moveY / moveX) * flyX;

            card.removeEventListener('pointerdown', onPointerDown)
            card.closest('.question-wrapper').classList.add('no-ng-animation');

            setTransform(flyX, flyY, flyX / window.innerWidth * 50, 400);

            $timeout(() => {
                callback && callback();
            }, 300);
        }

        function setTransform(moveX, moveY, deg, duration) {
            if (duration) card.style.transition = `transform ${duration}ms`
            card.style.transform = `translate3d(${moveX}px, ${moveY}px, 0) rotate(${deg}deg)`
            if (duration !== 0) {
                answerHighlight.style.opacity = Math.abs((moveX / window.innerWidth * 3.03))
                answerHighlight.className = `answer-highlight ${moveX > 0 ? 'show-true' : moveX < 0 ? 'show-false' : ''}`
            }
        }

        function cancel(d, callback) {
            const duration = d !== undefined ? d : 100;
            setTransform(0, 0, 0, duration);
            $timeout(() => {
                card.style.transition = '';
                card.style.transform = '';
                answerHighlight.style.opacity = '';
                moveX = 0;
                moveY = 0;
                callback && callback();
            }, duration)
        }
    }
})();