(function () {
    'use strict';

    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('notificationsSettings', {
                    parent: 'base',
                    url: '/notificationsSettings',
                    template: '<notifications-setting-page></notifications-setting-page>',
                    controller: 'NotificationsSettingsPageController as ctrl'
                });
        }
    ]);

    app.controller("NotificationsSettingsPageController", ['Page', '$translate',
        function (Page, $translate) {

            $translate('NOTIFICATION.NOTIFICATION_SETTINGS').then(function (translation) {
                Page.setTitle(translation);
            });
            
            Page.showBackButton();
        }
    ]);
})();