(() => {
    'use strict';

    angular
        .module('App')
        .component('pinProfile', {
            template: require('./PinProfileComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                profile: '<',
                switchUserDisabled: '<'
            },
            controller: ['Profile', 'Page', 'ActionSheetCustomService', PinProfileController]
        });

    function PinProfileController(Profile, Page, ActionSheetCustomService) {
        const ctrl = this;
        let localProfile;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.showSwitchUserActionsheet = showSwitchUserActionsheet;
        ctrl.canLoginAsAnotherUser = false;

        function init() {
            localProfile = ctrl.profile;
            if (!ctrl.profile) {
                localProfile = Profile.getCachedProfile();
                if (!localProfile) {
                    localProfile = Profile.getProfile();
                }
            }

            ctrl.profileName = localProfile && localProfile.Name || '';
            ctrl.profileImage = '/Content/Graphics/Profile.png';

            if (localProfile) {
                if (localProfile.ProfileImageBase64) {
                    ctrl.profileImage = localProfile.ProfileImageBase64;
                } else {
                    ctrl.profileImage = Profile.getProfileImageUrl(localProfile);
                }
            }

            if (!ctrl.switchUserDisabled) {
                ctrl.canLoginAsAnotherUser = Page.getSettings()?.LogInNewUserOnPinScreenEnabled;
            }
        }

        function showSwitchUserActionsheet() {
            const triggerBtnPosition = document.querySelector('.profile-name')?.getBoundingClientRect()?.bottom;

            ActionSheetCustomService.create(
                `<switch-user-popup class="switch-user-popup" profile="profile" trigger-btn-position="triggerBtnPosition"></switch-user-popup>`, {
                    profile: localProfile,
                    triggerBtnPosition: triggerBtnPosition
                }
            )
        }

        function destroy() {
            const actionsheet = document.querySelector('.switch-user-popup');
            if (actionsheet) {
                actionsheet.remove();
            }
        }
    }
})()