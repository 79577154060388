(function () {
        'use strict';

        angular.module('App')
            .component('policyView', {
                templateUrl: '/Scripts/Components/Policies/PolicyView/PolicyViewComponent.tpl.html',
                controller: ['PolicyViewService', 'ResponsiveService', PolicyViewComponentController],
                controllerAs: 'ctrl',
                bindings: {
                    policy: '<',
                    policiesCount: '<',
                    policyNumber: '<',
                    isWalkthrough: '<',
                }
            });

    function PolicyViewComponentController(PolicyViewService, ResponsiveService) {
            const ctrl = this;

            ctrl.state = 'load';
            ctrl.isLoading = true;
            ctrl.$onInit = init;
            ctrl.revoke = revoke;
            ctrl.showRevokeBtn = showRevokeBtn;
            ctrl.isDesktop = ResponsiveService.isDesktop();

            function init() {
                if (ctrl.policy && !ctrl.policy.Date && ctrl.policy.DateAccepted) {
                    ctrl.policy.Date = PolicyViewService.getAcceptedDate(ctrl.policy.DateAccepted);
                }

                ctrl.isLoading = false;
                ctrl.state = 'view';
            }

            function revoke() {
                ctrl.isLoading = true;
                
                PolicyViewService.confirmAndRevokePolicy(ctrl.policy.PolicyToken).then(() => {
                    ctrl.state = 'revoke';
                }).catch(() => {
                    ctrl.state = 'view';
                }).finally(() => {
                    ctrl.isLoading = false;
                });
            }

            function showRevokeBtn() {
                return !ctrl.isWalkthrough && ctrl.policy?.Date;
            }
        }
    }
)();