(() => {
    'use strict';

    angular
        .module('App.Training')
        .controller('TrainingController', ['$scope', '$state', '$stateParams', '$filter', 'trainingSettings', 'Page',
            'TrainingService', 'HeaderButtonsFactory', 'TRAINING_ROUTE', TrainingController]);

    function TrainingController($scope, $state, $stateParams, $filter, trainingSettings, Page, TrainingService,
                                HeaderButtonsFactory, TRAINING_ROUTE) {
        const ctrl = this;

        ctrl.trainingSettings = trainingSettings;
        ctrl.state = $state.current.name;

        Page.setTitle(
            ctrl.trainingSettings.PageTitle,
            $filter('translate')(TrainingService.trainingRoutesTitles[ctrl.state])
        );

        TrainingService.setHeaderButtons(ctrl.trainingSettings);
        Page.showBackButton()
        $scope.$on('$destroy', () => HeaderButtonsFactory.resetButtonsList());
    }
})();
