(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('formReportList', {
                    parent: 'base',
                    url: '/form/:token/reportlist',
                    templateUrl: '/Scripts/Controllers/Modules/Form/Templates/FormReportList.tpl.html',
                    resolve: {
                        formData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Form/GetReportList/' + $stateParams.token);
                            }
                            return;
                        }]
                    },
                    controller: 'FormReportListController as FormReportListCtrl'
                });
        }
    ]);

    app.controller('FormReportListController', ['$rootScope', '$scope', '$http', 'Page', 'formData', '$state', 'toastr', '$translate', '$timeout', 'TimeLocale', '$stateParams', 'events',
        function ($rootScope, $scope, $http, Page, formData, $state, toastr, $translate, $timeout, TimeLocale, $stateParams, events) {
            var ctrl = this;

            // Init Controller
            InitController();

            function InitController() {
                // Setup Page
                Page.setTitle(formData.data.PageTitle);
                Page.showBackButton();

                $scope.reports = formData.data.Reports;
                $scope.managerReports = formData.data.ManagerReports;
                $scope.isManager = formData.data.IsManager;

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }

            // Methods
            function ShowReport(report) {
                Page.stateGo('formReport', $stateParams.token, report.FormAnswerToken)
            }

            // Bindings
            $scope.TimeLocale = TimeLocale;
            $scope.showReport = ShowReport;
        }
    ]);

})();