(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('leaveRegistration', {
                        parent: 'base',
                        url: '/profileSettings/leaveRegistration',
                        template: '<leave-registration class="view-page">' +
                            '</leave-registration>',
                        params: {
                            backState: null
                        },
                        controller: 'LeaveRegistrationController as ctrl'
                    });
            }
        ])
        .controller('LeaveRegistrationController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('LEAVE_REGISTRATION.PAGE_TITLE').then((translation) => {
                    Page.setTitle(translation);
                });

                Page.showBackButton();
            }]);
})();