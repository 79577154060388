'use strict';

angular
    .module('App.Training')
    .component('myTrainingV2', {
        template: require('./MyTrainingV2Component.html'),
        controllerAs: 'ctrl',
        controller: ['$timeout', '$element', '$stateParams', '$state', 'Page', 'TrainingService', MyTrainingV2ComponentController],
        bindings: {
            learningPathsOverviewEnabled: '<',
            isVoluntaryEnabled: '<',
            showTranslated: '<',
        }
    })

function MyTrainingV2ComponentController($timeout, $element, $stateParams, $state, Page, TrainingService) {
    const ctrl = this;

    ctrl.TABS = {
        'LEARNING_PATH': 1,
        'COURSES': 2,
    }

    ctrl.getStatus = TrainingService.getItemStatus;
    ctrl.activeTab = $stateParams.activeTab === 'courses' ? ctrl.TABS.COURSES : ctrl.TABS.LEARNING_PATH;

    ctrl.$onInit = init;
    ctrl.setActiveTab = setActiveTab;
    ctrl.isActiveTab = isActiveTab;
    ctrl.openPath = openPath;
    ctrl.openVoluntary = openVoluntary;

    function init() {
        ctrl.isLoading = true;
        TrainingService
            .getMyTrainingData($stateParams.token)
            .then(({LearningPaths, Courses}) => {
                ctrl.isLoading = false;
                ctrl.courses = Courses;
                ctrl.learningPaths = LearningPaths;

                ctrl.showTabs = ctrl.courses?.length && ctrl.learningPaths?.length;

                if (!ctrl.learningPaths?.length && ctrl.courses?.length > 0) {
                    ctrl.activeTab = ctrl.TABS.LEARNING_PATH;
                }
                $element[0].style.opacity = 0;
                $element[0].style.transition = 'none';

                $timeout(() => {
                    $element[0].style.removeProperty('transition');
                    TrainingService.scrollToTrainingItem($element, () => {
                        $element[0].style.opacity = 1;
                    });
                }, 100)
            })
    }

    function setActiveTab(tab) {
        ctrl.activeTab = tab;
        Page.stateGo($state.current.name, $stateParams.token, null, null, null, false, {
            activeTab: ctrl.activeTab === ctrl.TABS.COURSES ? 'courses' : null
        })
    }

    function isActiveTab(tab) {
        return ctrl.activeTab === tab;
    }

    function openPath(path) {
        Page.stateGo('learningPath', path.LearningPathToken)
    }

    function openVoluntary() {
        Page.stateGo('trainingVoluntary', $stateParams.token);
    }
}