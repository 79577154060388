(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('dutyplan', {
                    parent: 'base',
                    url: '/dutyplan/:token',
                    templateUrl: '/DutyPlan',
                    resolve: {
                        dutyPlanData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/DutyPlan/GetDutyPlanShiftWeekPlanByUser/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'DutyPlanController as DutyPlanCtrl'
                })
                .state('dutyplanshiftweekplanbyuser', {
                    parent: 'base',
                    url: '/dutyplan/:token/:extra',
                    templateUrl: '/DutyPlan/DutyPlanDepartmentWeekPlan',
                    resolve: {
                        dutyPlanData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/DutyPlan/ShowDepartmentWeekplan/' + $stateParams.token + '/?date=' + $stateParams.extra);
                            } return;
                        }]
                    },
                    controller: 'DutyPlanDepartmentWeekplanController as DutyPlanCtrl'
                });
        }]);

    app.controller('DutyPlanController', ['$window','$rootScope', '$scope', '$state', '$stateParams', 'Page', 'dutyPlanData', '$translate', 'TimeLocale', 'BasicHelper', 'events',
        function ($window, $rootScope, $scope, $state, $stateParams, Page, dutyPlanData, $translate, TimeLocale, BasicHelper, events) {
            var ctrl = this;
            ctrl.Page = Page;
            
            Page.showBackButton();
            Page.setTitle(dutyPlanData.data.Title);

            ctrl.Time = TimeLocale;
            ctrl.Weeks = dutyPlanData.data.Schedule.Weeks;
            ctrl.DutyPlan = dutyPlanData.data.DutyPlan;
            ctrl.TotalHours = dutyPlanData.data.Schedule.TotalHours;
            ctrl.TotalHoursByWeekAvg = dutyPlanData.data.Schedule.TotalHoursByWeekAvg;
            //console.log(dutyPlanData.data.Schedule.Weeks[0]);

            // Functions
            ctrl.ShowDepartmentWeekplan = function (date) {
              Page.stateGo('dutyplanshiftweekplanbyuser', $stateParams.token, TimeLocale.dateTimeFormat(date, "YYYY-MM-DD"));
            };

            ctrl.GoToDutyPlanSystem = function (url) {
                $window.open(url);
            }

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateReload();
            });
        }
    ]);

    app.controller('DutyPlanDepartmentWeekplanController', ['$rootScope', '$scope', '$state', '$stateParams', 'Page', 'dutyPlanData', '$translate', 'TimeLocale', 'events',
        function ($rootScope, $scope, $state, $stateParams, Page, dutyPlanData, $translate, TimeLocale, events) {
            var ctrl = this;
            ctrl.Page = Page;
            Page.showBackButton();

            $translate('CONTACT.STORE').then(function (translation) {
                Page.setTitle(translation);
            });
            
            ctrl.Date = dutyPlanData.data.Date;
            ctrl.Time = TimeLocale;
            ctrl.WeekNumber = dutyPlanData.data.WeekNumber;
            ctrl.Shifts = dutyPlanData.data.Shifts;
            ctrl.DepartmentName = dutyPlanData.data.DepartmentName;

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
              Page.stateGoBack('dutyplan', $stateParams.token);
            });
        }
    ]);

    app.filter('capitalize', function () {
        return function (s) {
            return (angular.isString(s) && s.length > 0) ? s[0].toUpperCase() + s.substr(1).toLowerCase() : s;
        }
    });

})();