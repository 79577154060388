(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
    function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
        $stateProvider
            .state('todo', {
                parent: 'base',
                url: '/todo/:token',
                templateUrl: '/Templates/Modules/ToDo/ToDo.tpl.html',
                resolve: {
                    toDoData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                        if (base) {
                            return $http.get('/Module/ToDo/GetPage/' + $stateParams.token);
                        } return;
                    }]
                },
                controller: 'ToDoController'
            });
    }]);

    app.controller('ToDoController', ['$rootScope', '$scope', '$state', 'Page', 'toDoData', '$translate', 'TimeLocale', '$timeout', '$compile', '$http', '$stateParams', 'toastr', '$q', 'events',
        function ($rootScope, $scope, $state, Page, toDoData, $translate, TimeLocale, $timeout, $compile, $http, $stateParams, toastr, $q, events) {
            $scope.loading = false;
            $scope.opening = false;

            // Init
            InitController();
            function InitController() {
                $scope.Settings = toDoData.data.Settings;
                $scope.EndPage = toDoData.data.EndPage;
                SetHighscoreButtonText();

                // Create back button
                Page.showBackButton();

                // Set page title
                Page.setTitle(toDoData.data.PageTitle, $scope.Settings.OverviewTitle);

                if (!angular.isString($scope.Settings.OverviewTitle) || $scope.Settings.OverviewTitle.length == 0) {
                    // Page title was not set through settings and should get a default translated value
                    $translate('TODO.MENU.OVERVIEW').then(function (translation) {
                        Page.setTitle(toDoData.data.PageTitle, translation);
                    });
                }

                // console.log(toDoData.data);

                // Add Activities
                SetActivities(toDoData.data.Activities);

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateReload();
                });
            }

            // Objects
       
            // Methods
            function SetActivities(activities) {
                $scope.activities = activities;

                // Set Calendar State
                for (var i = 0; i < $scope.activities.length; i++) {
                    var act = $scope.activities[i];
                    if(act.HasRegistration && act.IsConfirmed){
                        act.State = 'positive';
                    } else {
                        //act.State = 'negative';
                    }
                }
            }

            function GoToExtraLink() {
                if ($scope.Settings.AppLink.Title) {
                    var al = $scope.Settings.AppLink;
                    // Go to new page
                    Page.stateGo(al.PathState, al.PathToken, al.PathExtra);
                }
            }

            function DateString(date) {
                return TimeLocale.dateTimeFormat(date, '');
            }

            function CalendarClick(data) {
                if (data.Items.length > 0 && !$scope.loading && !$scope.opening) {
                    $scope.opening = true;
                    var timeout = $timeout(function () {
                        $scope.loading = true;
                    }, 250);
                    var date = data.Day.Date.format('YYYY-MM-DD h:mm:ss');

                    $http.get('/Module/ToDo/GetDay?token=' + $stateParams.token + '&date=' + date).then(function (response) {
                        $timeout.cancel(timeout);
                        $scope.loading = false;
                        
                        if (response.data.Activities.length > 0) {
                            ShowToDoDay(response.data);
                        } else {
                            $translate('ERROR.GENERAL').then(function (translation) {
                                toastr.error(translation);
                            });
                        }
                        $scope.opening = false;
                    }, function () {
                        $timeout.cancel(timeout);
                        $scope.loading = false;
                        $scope.opening = false;
                        $translate('ERROR.GENERAL').then(function (translation) {
                            toastr.error(translation);
                        });
                    });
                }
            }

            function CalendarMonthChange(data) {
                var date = data.Date.format('YYYY-MM-DD h:mm:ss');
                var timeout = $timeout(function () {
                    $scope.loading = true;
                }, 250);
                
                $http.get('/Module/ToDo/GetMonth?token=' + $stateParams.token + '&date=' + date).then(function (response) {
                    $timeout.cancel(timeout);
                    $scope.loading = false;
                    SetActivities(response.data.Activities);
                }, function () {
                    $timeout.cancel(timeout);
                    $scope.loading = false;
                    $translate('ERROR.GENERAL').then(function (translation) {
                        toastr.error(translation);
                    });
                });
            }

            function ShowToDoDay(day) {
                var newScope = $rootScope.$new(true);

                newScope.Day = day;
                newScope.Settings = $scope.Settings;
                newScope.EndPage = $scope.EndPage;
                newScope.Callback = function () {
                    // Reload Data
                    CalendarMonthChange({ Date: moment(day.Date) });
                }

                var toDoDay = $compile("<to-do-day></to-do-day>")(newScope);
                $('body').append(toDoDay);
            }

            function ShowHighscore() {
                if ($scope.Settings.EnableHighscore) {
                    Page.stateGo('todoHighscore', $stateParams.token);
                }
            }

            function SetHighscoreButtonText() {
                $translate('TODO.HIGHSCORE.LINK').then(function (translation) {
                    var text = translation;

                    if (angular.isString($scope.Settings.HighscoreTitle) && $scope.Settings.HighscoreTitle.length > 0) {
                        $scope.HighscoreButtonText = text.replace('[HIGHSCORE]', $scope.Settings.HighscoreTitle);
                    } else {
                        $translate('TODO.MENU.HIGHSCORE').then(function (highscoreTranslation) {
                            $scope.HighscoreButtonText = text.replace('[HIGHSCORE]', highscoreTranslation);
                        });
                    }
                });
            }

            // Bindings
            $scope.DateString = DateString;
            $scope.CalendarClick = CalendarClick;
            $scope.CalendarMonthChange = CalendarMonthChange;
            $scope.ShowHighscore = ShowHighscore;
            $scope.GoToExtraLink = GoToExtraLink;
        }
    ]);

})();