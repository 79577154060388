(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('departmentRow', {
            template: require('./DepartmentRowComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'SurveyService', DepartmentRowController],
            bindings: {
                department: '<',
                surveyToken: '<',
                surveyData: '<',
                filters: '<'
            }
        })

    function DepartmentRowController($stateParams, Page, SurveyService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.toggleChildren = toggleChildren;
        ctrl.openDepartmentPage = openDepartmentPage;

        function init() {
            ctrl.ratingClass = SurveyService.getRatingClass(ctrl.department.RateType);
            ctrl.department.isHidden = !!ctrl.department.ParentDepartmentId;
        }

        function openDepartmentPage() {
            Page.stateGo('surveySingleDepartment', ctrl.surveyToken, ctrl.department.DepartmentToken, {...ctrl.surveyData, filters: ctrl.filters});
        }

        function toggleChildren() {
            ctrl.department.opened = !ctrl.department.opened;
            ctrl.department.Children.forEach(child => {
                child.visible = !child.visible;
            })
        }
    }
})();