(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('trainingCategory', {
                parent: 'trainingSettings',
                url: '/category/:token',
                template: '<training-voluntary-category class="view-page" modules="ctrl.modules" related-categories="ctrl.relatedCategories"' +
                    ' child-categories="ctrl.childCategories" color="ctrl.color" module-token="ctrl.moduleToken" image="ctrl.image"></training-voluntary-category>',
                resolve: {
                    categoryData: ['TrainingVoluntaryService', 'trainingSettings', '$stateParams',
                        function (TrainingVoluntaryService, trainingSettings, $stateParams) {
                            return TrainingVoluntaryService.getRelated($stateParams.token);
                        }]
                },
                params: {
                    backState: null,
                    data: {}
                },
                controller: 'TrainingCategoryController as ctrl'
            });
        }])
        .controller('TrainingCategoryController',
            ['$scope', 'categoryData', 'events', 'Page', TrainingCategoryController]);

    function TrainingCategoryController($scope, categoryData, events, Page) {
        var ctrl = this;

        ctrl.relatedCategories = categoryData.RelatedCategories;
        ctrl.childCategories = categoryData.ChildCategories;
        ctrl.modules = categoryData.Modules;
        ctrl.color = categoryData.Color;
        ctrl.image = categoryData.CategoryImage;
        ctrl.moduleToken = categoryData.AccountModuleToken;

        Page.showBackButton();

        Page.setTitle(categoryData.PageTitle);

        $scope.$on(events.DEPARTMENT_CHANGED, function () {
            Page.stateGoBack('training', categoryData.AccountModuleToken);
        });
    }

})();