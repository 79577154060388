(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('christmasCalendarDay', {
                    parent: 'base',
                    url: '/christmascalendarday/:token',
                    templateUrl: '/Scripts/Controllers/Modules/ChristmasCalendar/Templates/ChristmasCalendarDay.tpl.html',
                    resolve: {
                        pageData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/ChristmasCalendar/GetChristmasCalendarDay/?token=' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'ChristmasCalendarDayController as ChristmasCalendarDayCtrl'
                });
        }
    ]);

    app.controller('ChristmasCalendarDayController', ['Page', '$scope', 'pageData', '$http', '$timeout', 'toastr', '$translate', 'ModalService', 'BasicHelper', '$interval', '$stateParams', '$element', 'events',
        function (PageService, $scope, pageData, $http, $timeout, toastr, $translate, ModalService, BasicHelper, $interval, $stateParams, $element, events) {
            $scope.containerStyle = {};
            $scope.tileStyle = {};

            // Init
            InitController();
            function InitController() {
                $scope.settings = pageData.data.Settings;
                $scope.day = pageData.data.Day;

                $translate('DEFAULT.DAY').then(function (translation) {
                    PageService.setTitle(translation + " " + $scope.day.DayNumber);
                });
                PageService.showBackButton();

                // Set Styles
                if ($scope.settings.BackgroundColor.length > 0) {
                    $scope.containerStyle = {
                        backgroundColor: $scope.settings.BackgroundColor
                    };
                }
                if ($scope.settings.BorderColor.length > 0) {
                    $scope.containerStyle.color = $scope.settings.BorderColor;
                    $scope.tileStyle = {
                        borderColor: $scope.settings.BorderColor
                    };
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  PageService.stateReload();
                });
            }

            // Methods
            function ShowContent() {
                PageService.stateGo('contentItem', $scope.day.ContentToken);
            }

            function ShowSurvey() {
                PageService.stateGo('survey', null, $scope.day.SurveyToken);
            }

            // Bindings
            $scope.showContent = ShowContent;
            $scope.showSurvey = ShowSurvey;
        }
    ]);
})();