(function () {
    'use srict';
    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('taskmanagement', {
                        parent: 'base',
                        url: '/taskmanagement/:token',
                        template: '<task-management ' +
                            'class="view-page" ' +
                            'moderate-allowed="ctrl.ModerateAllowed" ' +
                            'issues="ctrl.issues" ' +
                            'categories="ctrl.categories" ' +
                            'feed-link="ctrl.feedLink" ' +
                            'overdue-issues-to-show="ctrl.overdueIssuesToShow" ' +
                            'on-switch-view="ctrl.onSwitchView" ' +
                            'allow-manager-view="ctrl.IsTaskListManagerViewAvailable" ' +
                            'selected-department-name="{{ctrl.SelectedDepartmentName}}"' +
                            'enable-how-to-guide="ctrl.enableHowToGuide"' +
                            'set-default-back-button="ctrl.setBackButton"' +
                            'days-counted-in-badge="ctrl.daysCountedInBadge"' +
                            'show-overdue-view-in-my-tasks="ctrl.showOverdueViewInMyTasks"> ' +
                            '</task-management>',
                        resolve: {
                            taskManagementData: ['$http', '$stateParams', 'Page', 'base', function ($http, $stateParams, Page, base) {
                                if (base) {
                                    return $http.post('/TaskManagement/GetData/' + $stateParams.token);
                                }
                            }]
                        },
                        controller: 'TaskManagementController as ctrl'
                    })
                    .state('taskPopup', {
                        parent: 'taskmanagement',
                        url: '/:extra',
                        onEnter: ['$stateParams', 'TaskDetailsService', function ($stateParams, TaskDetailsService) {
                            TaskDetailsService.openPopup($stateParams.extra);
                        }]
                    })
            }
        ])
        .controller('TaskManagementController', [
            '$scope', '$stateParams', 'Page', 'taskManagementData', 'TaskManagementRenderService', 'HowToGuideService',
            '$translate', 'TemplateFactory', 'ResponsiveService', TaskManagementController
        ]);

    function TaskManagementController($scope, $stateParams, Page, taskManagementData, TaskManagementRenderService,
                                      HowToGuideService, $translate, TemplateFactory, ResponsiveService) {
        var ctrl = this;
        ctrl.currentView = null;

        // back button setup
        setBackButton();
        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name === fromState.parent && toState.name === 'taskmanagement') {
                $scope.$broadcast('TaskManagement:stateChanged', {
                    toState: toState,
                    toParams: toParams,
                    fromState: fromState,
                    fromParams: fromParams
                });
            }
        });

        TaskManagementRenderService.saveCategories(taskManagementData.data.Categories);

        ctrl.onSwitchView = onSwitchView;

        ctrl.categories = taskManagementData.data.Categories;
        ctrl.feedLink = taskManagementData.data.FeedLink;
        ctrl.daysCountedInBadge = taskManagementData.data.DaysCountedInBadge;
        ctrl.ModerateAllowed = taskManagementData.data.ModerateAllowed;
        ctrl.IsTaskListManagerViewAvailable = taskManagementData.data.IsTaskListManagerViewAvailable;
        ctrl.SelectedDepartmentName = taskManagementData.data.SelectedDepartmentName;
        ctrl.enableHowToGuide = taskManagementData.data.HowToGuideEnabled;
        ctrl.showOverdueViewInMyTasks = taskManagementData.data.ShowOverdueViewInMyTasks;
        ctrl.setBackButton = setBackButton;

        TaskManagementRenderService.overdueIssuesToShow = taskManagementData.data.OverdueIssuesToShow;
        ctrl.overdueIssuesToShow = TaskManagementRenderService.markAsOverdue(TaskManagementRenderService.overdueIssuesToShow);

        if (ResponsiveService.isDesktop()) {
            Page.setTitle(taskManagementData.data.PageTitle, null, (ctrl.enableHowToGuide && !TemplateFactory.isNative()) ? openHowToGuide : null);
        } else {
            $translate('TASK_MANAGEMENT.MENU.MY_TASKS').then(function (translation) {
                Page.setTitle(taskManagementData.data.PageTitle, translation, (ctrl.enableHowToGuide && !TemplateFactory.isNative()) ? openHowToGuide : null);
            });
        }
        
        function setBackButton() {
            Page.showBackButton();
        }

        function openHowToGuide() {
            HowToGuideService.renderHowToGuidePopup($stateParams.token);
        }

        function onSwitchView(subTitle) {
            if (subTitle) {
                $translate(subTitle).then(function (translation) {
                    Page
                        .setTitle(
                            taskManagementData.data.PageTitle,
                            translation,
                            (ctrl.enableHowToGuide && !TemplateFactory.isNative()) ? openHowToGuide : null
                        );
                })
            }
        }
    }
})();