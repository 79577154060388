(() => {
    'use strict';

    angular
        .module('App.Social')
        .controller('SocialPostController', ['$scope', '$rootScope', '$state', '$stateParams', 'Page', 'post', 'events',
            'ReactionsService', 'PostReadRegistrationService', 'NotificationFactory', 'Menu', 'SocialWallService', SocialPostController]);

    function SocialPostController($scope, $rootScope, $state, $stateParams, Page, post, events, ReactionsService,
                                  PostReadRegistrationService, NotificationFactory, Menu, SocialWallService) {
        const ctrl = this,
            urlSearchParams = new URLSearchParams($stateParams.extra);
        let removePostUpdatedWatcher;

        $scope.post = post.data.Post;
        $scope.settings = post.data.Settings;

        $scope.postDeleted = postDeleted;
        $scope.openReactionsViewer = openReactionsViewer;
        $scope.postEdit = postEdit;
        $scope.goToFeed = goToFeed;

        Page.setTitle(post.data.PageTitle, post.data.PageSubTitle);

        if (!Page.isLandingPage()) {
            const customClasses = `module-social-${post.data.AccountModuleToken} ${Page.getCustomCssClassesForAccountModule('social', post.data.AccountModuleToken)}`;
            Page.setCustomCssClass(post.data.AccountModuleToken, customClasses);
            $scope.$on('$destroy', () => Page.removeCustomCssClass(post.data.AccountModuleToken, customClasses));
        }

        if (!$scope.post.IsRead && $scope.post.TrackViews) {
            PostReadRegistrationService.registerRead($scope.post.SocialPostId, post.data.AccountModuleToken, 'social')
                .then(function (response) {
                    $scope.post.IsRead = true;
                    $scope.post.TotalViews = response.data.TotalViews;
                    NotificationFactory.markNotifications(response.data, false);
                })
                .catch(function (err) {
                    console.error(err)
                });
        }

        if (post.data.DecreaseBadge && post.data.NavigationBadge) {
            Menu.removeLandingBadge(1, post.data.NavigationBadge.AccountModuleId,
                post.data.NavigationBadge.ModuleId, post.data.NavigationBadge.ModuleItemId);
            Menu.removeActivity(1, 'social', post.data.AccountModuleToken, null);
        }
        
        Page.showBackButton();
        
        if (urlSearchParams.get('CommentId')) {
            $scope.$watch('ctrl.openComments', (isLoaded) => {
                if (isLoaded) {
                    ctrl.openComments(urlSearchParams.get('CommentId'));
                }
            })
        }

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, function () {
            Page.stateReload();
        });

        // Methods
        function postDeleted() {
            $scope.post = null;
            Page.stateGoBack();
        }

        function postEdit($post) {
            const ctrl = {
                settings: post.data.Settings,
                postsData: {
                    AccountModuleToken: post.data.AccountModuleToken,
                    AllowCreatingPolls: post.data.AllowCreatingPolls,
                    SocialGroups: []
                }
            };

            removePostUpdatedWatcher = $rootScope.$on('social:postUpdated', (ev, updatedPost) => {
                $scope.post = updatedPost;
                removePostUpdatedWatcher();
            })

            SocialWallService.showCreatePost(ctrl, $post);
        }

        function openReactionsViewer(postToken) {
            ReactionsService.openReactionsViewer(postToken, $scope.settings.AllowExtendedReactions, 'Social');
        }

        function goToFeed() {
            Page.stateGo('social', post.data.AccountModuleToken, '');
        }
    }
})();