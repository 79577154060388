(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('trainingPhase', {
                    parent: 'base',
                    url: '/training/phase/:token',
                    template: '<training-phase-page courses="ctrl.courses" ' +
                        'phase="ctrl.phase" performance-ranges="ctrl.performanceRanges" module-token="ctrl.moduleToken" class="view-page"' +
                        '></training-phase-page>',
                    resolve: {
                        trainingData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Training/Training/GetPhaseCourses/' + $stateParams.token)
                                    .then(function (resp) {
                                        return resp.data
                                    });
                            }
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    controller: 'TrainingPhaseController as ctrl'
                });
        }])
        .controller('TrainingPhaseController', ['$scope', 'trainingData', 'events', 'Page',
            function ($scope, trainingData, events, Page) {
                var ctrl = this;

                ctrl.courses = trainingData.Courses;
                ctrl.phase = trainingData.Phase;
                ctrl.performanceRanges = trainingData.PerformanceRanges;
                ctrl.moduleToken = trainingData.AccountModuleToken;

                Page.setTitle(trainingData.PageTitle);
                Page.showBackButton();
                
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateGoBack('training', trainingData.AccountModuleToken);
                });
            }]);
})();