(() => {
    'use strict';

    angular
        .module('App')
        .component('profileDesktopDropdownMenu', {
            template: require('./ProfileDesktopDropdownMenuComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['Page', 'Profile', '$filter', '$scope', 'ConfirmPopupService', 'events', ProfileDesktopDropdownMenuController]
        });

    function ProfileDesktopDropdownMenuController(Page, Profile, $filter, $scope, ConfirmPopupService, events) {
        var ctrl = this, pageChangedWatcher;
        ctrl.Profile = Profile;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.onLogout = onLogout;
        ctrl.openProfile = openProfile;
        ctrl.openSettings = openSettings;
        ctrl.openPrivacyPolicy = openPrivacyPolicy;

        ctrl.hasPolicy = () => Profile.getProfile().HasPolicy;

        function onInit() {
            ctrl.title = Profile.getProfile().Name

            $scope.$watch(() => ctrl.Profile.getDesktopMenuDropdownVisibility(), (isVisible) => {
                if (isVisible){
                    ctrl.title = Profile.getProfile().Name
                }
            });

            pageChangedWatcher = $scope.$on(events.PAGE_CHANGED, function () {
                Profile.toggleDesktopMenuDropdownVisibility(false);
            });
        }

        function onDestroy() {
            pageChangedWatcher();
        }

        function onLogout() {
            var opts = {
                message: $filter('translate')('PROFILE.LOGOUT_LABEL')
            };

            ConfirmPopupService.open(opts).then(function () {
                Profile.toggleDesktopMenuDropdownVisibility(false);
                Page.stateGo('logout');
            });
        }

        function openProfile() {
            Profile.toggleDesktopMenuDropdownVisibility(false);
            Page.stateGo('profileOverview');
        }

        function openPrivacyPolicy() {
            Profile.toggleDesktopMenuDropdownVisibility(false);
            Page.stateGo('policyOverview');
        }

        function openSettings() {
            Profile.toggleDesktopMenuDropdownVisibility(false);
            Page.stateGo('profileSettings');
        }
    }
})();