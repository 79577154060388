(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('formReport', {
                    parent: 'base',
                    url: '/form/:token/report/:extra',
                    templateUrl: '/Scripts/Controllers/Modules/Form/Templates/FormReport.tpl.html',
                    resolve: {
                        formData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Form/GetReport/' + $stateParams.token + '?answer=' + $stateParams.extra);
                            } return;
                        }]
                    },
                    controller: 'FormReportsController as FormReportsCtrl'
                });
        }
    ]);

    app.controller('FormReportsController', ['$rootScope', '$scope', '$http', 'Page', 'formData', '$state', 'toastr', '$translate', '$timeout', 'TimeLocale', '$stateParams', 'Menu', 'events',
        function ($rootScope, $scope, $http, Page, formData, $state, toastr, $translate, $timeout, TimeLocale, $stateParams, Menu, events) {
            var ctrl = this;

            // Init Controller
            InitController();
            function InitController() {
                // Setup Page
                Page.setTitle(formData.data.PageTitle);
                Page.showBackButton();

                // Set Menu
                Menu.setCurrentMenu('formReportList', $stateParams.token);

                $scope.answer = formData.data.Answer;
                $scope.downloadPdfUrl = formData.data.DownloadPdfUrl;
                $scope.downloadCsvUrl = formData.data.DownloadCsvUrl;
                $scope.isCsvAvailable = formData.data.IsCsvAvailable;
                $scope.isOwnReport = formData.data.IsOwnReport;

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateReload();
                });
            }

            // Methods


            // Bindings
            $scope.TimeLocale = TimeLocale;
        }
    ]);

})();