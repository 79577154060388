(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalog', {
            template: require('./CatalogComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'CatalogSearchService', 'Page', 'ENTITY_TYPES', CatalogController],
            bindings: {
                moduleToken: '<',
                settings: '<',
                categories: '<',
                searchPlaceholder: '<',
            }
        });

    function CatalogController($stateParams, CatalogSearchService, Page, ENTITY_TYPES) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.pagingCategories = pagingCategories;
        ctrl.goToCategory = goToCategory;

        function init() {
            ctrl.renderCategories = _.take(ctrl.categories, 24);
            ctrl.searchSettings = {
                accountModuleId: ctrl.settings.AccountModuleId,
                entityType: ENTITY_TYPES.CATALOG.MODULE,
                entityId: ctrl.moduleToken,
                viewName: 'Overview'
            };
        }

        function destroy() {
            if (ctrl.moduleToken !== $stateParams.token) {
                CatalogSearchService.cleanSearchState();
            }
        }

        function pagingCategories() {
            ctrl.renderCategories = _.take(ctrl.categories, ctrl.renderCategories.length + 6);
            ctrl.isCategoriesScrollDisabled = (ctrl.renderCategories && ctrl.categories) &&
                (ctrl.renderCategories.length === ctrl.categories.length);
        }

        function goToCategory(category) {
            Page.stateGo('catalogCategory', $stateParams.token, category.CatalogCategoryToken);
        }
    }
})();