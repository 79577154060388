(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .component('rlSearch', {
            template: require('./RelesysSearchComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', RlSearchController],
            bindings: {
                searchString: '=?',
                onSearch: '<',
                placeholder: '@',
                searchOnEnter: '<',
            }
        });

    function RlSearchController($timeout) {
        const ctrl = this;
        let prevSearchString = '';

        ctrl.onEnterSearch = onEnterSearch;
        ctrl.onClear = onClear;
        ctrl.makeSearch = makeSearch;

        function onEnterSearch(event) {
            if (event.keyCode === 13) {
                if (prevSearchString !== ctrl.searchString) {
                    prevSearchString = ctrl.searchString;
                    ctrl.onSearch && ctrl.onSearch(ctrl.searchString);
                }
            } else {
                prevSearchString = '';
            }
        }

        function makeSearch() {
            $timeout(() => {
                ctrl.onSearch && ctrl.onSearch(ctrl.searchString)
            })
        }

        function onClear() {
            prevSearchString = '';
            ctrl.searchString = '';
            $timeout(() => {
                ctrl.onSearch && ctrl.onSearch(ctrl.searchString);
            })
        }
    }
})();