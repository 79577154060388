(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('socialGroups', {
            template: require('./SocialGroupsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', 'Page', 'SocialGroupService', SocialGroupsController],
            bindings: {
                data: '<'
            }
        });

    function SocialGroupsController($timeout, Page, SocialGroupService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.selectGroup = selectGroup;

        function init() {
            ctrl.isLoading = true;
            ctrl.groups = _.sortBy(ctrl.data.SocialGroups,
                group => !group.Following && group.FollowingEnabled
            );

            if (ctrl.data.noBadges) {
                SocialGroupService.getGroups(ctrl.data.AccountModuleToken, true).then(groupsWBadges => {
                    ctrl.groups.forEach(group => {
                        group.BadgeCount = groupsWBadges.find(gWB => group.SocialGroupId === gWB.SocialGroupId).BadgeCount;
                    })
                });
            }

            ctrl.groups.forEach(group => {
                group.currentLocale = selectCurrentLocale(group.TranslatableEntity);
            })

            const selectedGroup = SocialGroupService.getSelectedSocialGroup(ctrl.groups, ctrl.data.AccountModuleToken);

            if (selectedGroup) {
                selectedGroup.selected = true;
            }

            $timeout(() => {
                ctrl.isLoading = false;
            })
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }
        
        function selectGroup(group) {
            const selectedGroup = ctrl.groups.find(group => group.selected),
                id = group.SocialGroupId;

            if (selectedGroup) {
                selectedGroup.selected = false;
            }
            group.selected = true;

            if (!ctrl.data.SelectedSocialGroupId) {
                SocialGroupService.saveSelectedSocialGroup(ctrl.data.AccountModuleToken, id);
            }

            Page.stateGo('social', ctrl.data.AccountModuleToken, id,{openGroup: true});
        }
    }
})();