angular
    .module('App.Training')
    .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
            .state('training', {
                parent: 'base',
                url: '/training',
                params: {
                    token: null,
                },
                resolve: {
                    redirect: ['$stateParams', 'TrainingService', 'Page', 'TRAINING_ROUTE', resolveTrainingRoute]
                }
            })
            .state('trainingSettings', {
                parent: 'base',
                url: '/training',
                abstract: true,
                template: '<ui-view></ui-view>',
                resolve: {
                    trainingSettings: ['TrainingService', (TrainingService) => {
                        return TrainingService.getTrainingSettings()
                    }]
                },
            })
            .state('trainingMy', createStateConfig('/:token/index/:activeTab'))
            .state('trainingProgress', createStateConfig('/:token/progress'))
            .state('trainingManager', createStateConfig('/:token/manager'))
            .state('trainingVoluntary', createStateConfig('/:token/voluntary'))
            .state('trainingEntityLink', {
                parent: 'base',
                url: 'trainingEntityLink/:token',
                resolve: {
                    redirect: ['$stateParams', '$http', 'TrainingService', resolveEntityLink]
                }
            });
    }])

function resolveTrainingRoute($stateParams, TrainingService, Page, TRAINING_ROUTE) {
    return TrainingService.getTrainingSettings().then(trainingSettings => {
        if (trainingSettings.IsMandatoryAllowed) {
            go(TRAINING_ROUTE.MY_TRAINING)
        } else if (!trainingSettings.IsMandatoryAllowed && trainingSettings.IsVoluntaryAllowed) {
            go(TRAINING_ROUTE.VOLUNTARY)
        } else {
            go(TRAINING_ROUTE.MY_PROGRESS)
        }

        function go(route) {
            Page.stateGo(route, $stateParams.token, null, trainingSettings)
        }
    })
}

function createStateConfig(url) {
    return {
        parent: 'trainingSettings',
        url: url,
        params: {
            data: {},
            backState: null,
        },
        template: `<training training-settings="ctrl.trainingSettings" state="ctrl.state" class="view-page"></training>`,
        controller: 'TrainingController as ctrl'
    }
}

function resolveEntityLink($stateParams, $http, TrainingService) {
    return $http
        .get(`/TrainingV2/Training/EntityLink/${$stateParams.token}`)
        .then(TrainingService.goByEntityLink)
}