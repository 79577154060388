(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('myTraining', {
            template: require('./MyTrainingComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$scope', '$timeout', '$element', '$filter', 'ToastFactory', 'Page', 'TrainingService',
                MyyTrainingController],
            bindings: {
                isVoluntaryEnabled: '<',
                phasesOverviewEnabled: '<',
                showTranslated: '<',
            }
        });

    function MyyTrainingController($stateParams, $scope, $timeout, $element, $filter, ToastFactory, Page, TrainingService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.getCourses = getCourses;
        ctrl.openVoluntary = openVoluntary;
        ctrl.openPhase = openPhase;

        function init() {
            ctrl.isLoading = true;
            ctrl.getStatus = TrainingService.getItemStatus;
            ctrl.getStatusClass = TrainingService.getItemStatusClass;

            TrainingService
                .getMyTrainingData($stateParams.token)
                .then(({Phases, Courses}) => {
                    ctrl.isLoading = false;
                    ctrl.courses = Courses;
                    ctrl.phases = Phases;

                    $timeout(() => {
                        TrainingService.scrollToTrainingItem($element);
                    })
                })
        }

        function getCourses(phaseId) {
            if (phaseId) {
                return ctrl.courses
                    .filter(course => course.TrainingPhaseId === phaseId)
                    .map(course => {
                        course.TranslatableEntity.PreferTranslatedVersion = ctrl.showTranslated;
                        return course;
                    });
            }

            return ctrl.courses
                .map(course => {
                    course.TranslatableEntity.PreferTranslatedVersion = ctrl.showTranslated;
                    return course;
                });
        }

        function openVoluntary() {
            Page.stateGo('trainingVoluntary', $stateParams.token);
        }

        function openPhase(phase) {
            if (phase.CanBeTaken) {
                Page.stateGo('trainingPhase', phase.TrainingPhaseToken)
            } else {
                ToastFactory.warning($filter('translate')('TRAINING.COURSE.NOT_ALLOWED'));
            }
        }
    }
})();