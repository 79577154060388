(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettingsAccessibility', {
                        parent: 'base',
                        url: '/profileSettings/preferences/accessibility',
                        template: '<div class="profile-settings-edit-page">' +
                            '<profile-settings-accessibility class="view-page narrow">' +
                            '</profile-settings-accessibility></div>',
                        resolve: {},
                        params: {
                            data: null
                        },
                        controller: 'ProfileSettingsAccessibilityController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsAccessibilityController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('PROFILE.SETTINGS.ACCESSIBILITY.PAGE_TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });

                Page.showBackButton();
            }]);
})();