(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('content', {
                    parent: 'base',
                    url: '/content/:token/:extra',
                    template: require('./ContentController.html'),
                    params: {
                        data: null,
                    },
                    resolve: {
                        contentPage: ['$http', '$stateParams', function ($http, $stateParams) {
                            if ($stateParams.data?.isDataPreloaded && hasMatchingCategoryToken()) {
                                return $stateParams.data
                            } else {
                                const categoryToken = $stateParams.extra ? '?categoryToken=' + $stateParams.extra : '';
                                return $http
                                    .get('/ContentModule/GetSettings/' + $stateParams.token + categoryToken)
                                    .then(resp => ({
                                            ...$stateParams.data,
                                            ...resp.data
                                        })
                                    )
                            }

                            function hasMatchingCategoryToken() {
                                return $stateParams.data?.CategoryToken === $stateParams.extra;
                            }
                        }]
                    },
                    controller: 'ContentController as ctrl'
                })
                .state('contentItem', {
                    parent: 'base',
                    url: '/contentItem/:token/:extra',
                    params: {
                        data: null,
                    },
                    template: require('./ContentController.html'),
                    resolve: {
                        contentPage: ['$http', '$stateParams', ($http, $stateParams) => {
                            return $http.get(`/ContentModule/GetContent/${$stateParams.token + $stateParams.extra}`)
                                .then(resp => ({
                                    ...resp.data,
                                    IsSingleView: true
                                }))
                        }]
                    },
                    controller: 'ContentController as ctrl'
                });
        }])
        .controller("ContentController", ['$scope', '$state', '$http', 'Page', 'contentPage', '$stateParams', 'events', 'NotificationFactory',
            function ($scope, $state, $http, Page, contentPage, $stateParams, events, NotificationFactory) {
                const ctrl = this,
                    urlSearchParams = new URLSearchParams($stateParams.extra);

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, () => {
                    Page.stateReload();
                });

                // Set Page Title
                const subtitle = contentPage.PageSubTitle || ((contentPage.Settings.Categories && !contentPage.IsSingleView) ? 'CONTENT.ALL' : '')
                Page.setTitle(contentPage.PageTitle, subtitle);

                if ($stateParams.data) {
                    $scope.openCreatePopup = $stateParams.data.openCreatePopup;
                }

                if (urlSearchParams.get('CommentId')) {
                    $scope.$watch('ctrl.openComments', (isLoaded) => {
                        if (isLoaded) {
                            ctrl.openComments(urlSearchParams.get('CommentId'));
                        }
                    })
                }

                // Show Content
                ctrl.contentData = contentPage;
                ctrl.contentData.PageSubTitle = subtitle;


                if ($stateParams.data && $stateParams.data.contentToken) {
                    ctrl.contentData.contentToken = $stateParams.data.contentToken;
                }

                // Update Notification status
                NotificationFactory.markNotifications(contentPage.NotificationStatus);

                if ($state.current.name === 'contentItem') {
                    ctrl.contentData.Settings.DisableReadMore = true;
                    $stateParams.AccountModuleToken = ctrl.contentData.AccountModuleToken;

                    if (!Page.isLandingPage()) {
                        const customClasses = `module-content-${$stateParams.AccountModuleToken} ${Page.getCustomCssClassesForAccountModule('content', $stateParams.AccountModuleToken)}`;
                        Page.setCustomCssClass($stateParams.AccountModuleToken, customClasses);
                        $scope.$on('$destroy', () => Page.removeCustomCssClass($stateParams.AccountModuleToken, customClasses));
                    }

                    Page.showBackButton();
                }

            }
        ]);
})();
