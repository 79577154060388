(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('TrainingManagerTabService', ['$q', '$http', '$stateParams', '$filter', 'TrainingService',
            'ConfirmPopupService', TrainingManagerTabService])

    function TrainingManagerTabService($q, $http, $stateParams, $filter, TrainingService, ConfirmPopupService) {
        const PERFORMANCE = {
            LOW: 'low',
            MEDIUM: 'medium',
            HIGH: 'high'
        };
        let performanceValues,
            TrainingOverviewController;

        return {
            setTrainingOverviewController: setTrainingOverviewController,
            openTrainingOverviewPopup: openTrainingOverviewPopup,
            getTrainingTabData: getTrainingTabData,
            getManagerStatistics: getManagerStatistics,
            setPerformanceClass: setPerformanceClass,
            askForRelaunch: askForRelaunch,
            relaunch: relaunch
        }

        function setTrainingOverviewController(ctrl) {
            TrainingOverviewController = ctrl;
        }

        function openTrainingOverviewPopup(user) {
            let req;

            if (TrainingService.isNewTraining()) {
                req = $http.get(`/TrainingV2/Training/GetIndividualUserStatistics/${$stateParams.token}?userId=${user.UserId}`)
            } else {
                req = $http.post('/Training/Training/GetIndividualUserStatistics', {
                    token: $stateParams.token,
                    userId: user.UserId
                })
            }

            return req.then(resp => {
                const data = {
                    User: user,
                    Settings: performanceValues,
                    ScormData: resp.data.ScormData,
                    ...resp.data.Stats
                }

                TrainingOverviewController.openPopup(data);
            })
        }

        function getStatisticsReq(opts) {
            if (TrainingService.isNewTraining()) {
                const restParams = new URLSearchParams(opts);
                const restParamsString = restParams.size ? ('?' + restParams.toString()) : '';

                return $http.get(`/TrainingV2/Training/GetManagerStatistics/${$stateParams.token}${restParamsString}`)
                    .then(resp => resp.data)
            }

            return $http.post('/Training/Training/GetManagerStatistics', {
                token: $stateParams.token,
                departmentId: opts?.departmentId,
                roleId: opts?.roleId,
                courseId: opts?.courseId,
                moduleId: opts?.moduleId,
            }).then(resp => resp.data)
        }

        function getManagePageReq() {
            if (TrainingService.isNewTraining()) {
                return $http.get('/TrainingV2/Training/GetManagerPage/' + $stateParams.token).then(resp => resp.data)
            }
            return $http.post('/Training/Training/GetManagerPage/' + $stateParams.token).then(resp => resp.data)
        }

        function getTrainingTabData() {
            let courses = [], departments = [], roles = [];

            return $q.all({
                statistics: getStatisticsReq(),
                page: getManagePageReq()
            }).then(({statistics, page}) => {
                performanceValues = page.Settings;
                courses = page.Courses;

                if (courses.length > 0 && courses[0].CourseId !== null) {
                    courses.unshift({
                        Title: $filter('translate')('ALL'),
                        CourseId: null
                    });
                }

                departments = page.Departments;

                if (departments.length > 0 && departments[0].DepartmentId !== null) {
                    departments.unshift({
                        Name: $filter('translate')('ALL'),
                        DepartmentId: null
                    });
                }

                page.Roles.unshift({
                    Name: $filter('translate')('ALL'),
                    RoleId: null
                });

                page.Modules.unshift({
                    Title: $filter('translate')('ALL'),
                    CourseModuleId: null
                });

                return {
                    Courses: page.Courses,
                    Modules: page.Modules,
                    Departments: page.Departments,
                    Roles: page.Roles,
                    Participants: statistics.Participants,
                    Counts: {
                        HighCount: statistics.HighCount,
                        LowCount: statistics.LowCount,
                        MediumCount: statistics.MediumCount
                    }
                }
            })
        }

        function getManagerStatistics(departmentId, roleId, courseId, moduleId) {
            return getStatisticsReq({departmentId, roleId, courseId, moduleId})
                .then(resp => ({
                    Participants: resp.Participants,
                    Counts: {
                        HighCount: resp.HighCount,
                        LowCount: resp.LowCount,
                        MediumCount: resp.MediumCount
                    }
                }))
        }

        function setPerformanceClass(val) {
            if (val > performanceValues.PerformanceColorRed && val <= performanceValues.PerformanceColorYellow) {
                return PERFORMANCE.LOW
            }
            if (val > performanceValues.PerformanceColorYellow && val <= performanceValues.PerformanceColorGreen) {
                return PERFORMANCE.MEDIUM
            }
            if (val > performanceValues.PerformanceColorGreen) {
                return PERFORMANCE.HIGH
            }
        }

        function askForRelaunch(typeId, name, isScormCloudEnabled) {
            let type = ''

            switch (typeId) {
                case 1: {
                    type = 'course ';
                    break;
                }
                case 2: {
                    type = 'module ';
                    break;
                }
                case 3: {
                    type = 'lesson ';
                    break;
                }
                case 5: {
                    type = 'phase ';
                    break;
                }
            }

            const scormAdditionalMessage = isScormCloudEnabled ? $filter('translate')('TRAINING.RELAUNCH.MESSAGE_SCORM') : '';

            const opts = {
                title: $filter('translate')('TRAINING.RELAUNCH.TITLE'),
                message: $filter('translate')('TRAINING.RELAUNCH.MESSAGE', {
                    type: type,
                    name: name,
                    scorm: scormAdditionalMessage
                }),
                yesText: $filter('translate')('TRAINING.RELAUNCH.RELAUNCH'),
                noText: $filter('translate')('TRAINING.RELAUNCH.CANCEL'),
            };

            return ConfirmPopupService.open(opts);
        }

        function relaunch(itemTypeId, itemId, userId) {
            return $http.post(`/${TrainingService.getBaseUrl()}/Training/Relaunch`, {
                token: $stateParams.token,
                userId: userId,
                itemTypeId: itemTypeId,
                itemId: itemId
            })
        }
    }
})();
