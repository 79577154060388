(function () {
  'use strict';
  var app = angular.module('App');

  app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
    function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
      $stateProvider
        .state('form', {
          parent: 'base',
          url: '/form/:token',
          templateUrl: '/Scripts/Controllers/Modules/Form/Templates/Form.tpl.html',
          resolve: {
            formData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
              if (base) {
                return $http.get('/Form/GetForm/' + $stateParams.token);
              } return;
            }]
          },
          controller: 'FormController as FormCtrl'
        });
    }
  ]);

  app.controller('FormController', ['$rootScope', '$scope', '$http', 'Page', 'formData', '$state', 'toastr', '$translate', '$timeout', 'localStorageService', '$stateParams', 'events',
    function ($rootScope, $scope, $http, Page, formData, $state, toastr, $translate, $timeout, localStorageService, $stateParams, events) {
      var ctrl = this;

      // Init Controller
      InitController();
      function InitController() {
        // Setup Page
        Page.setTitle(formData.data.PageTitle);
        $scope.settings = formData.data.Settings;
        Page.showBackButton();

        // Setup default values
        $scope.savingAnswers = false;
        $scope.FormState = 'noform';

        // Only continue if we have some data
        if (formData.data.Form != null) {

          // Checked if the allow number of answers have been excited?
          if (formData.data.Settings.MaxAnswers > 0 && formData.data.Form.AnswersByUser >= formData.data.Settings.MaxAnswers) {
            $scope.FormState = 'alreadyanswered';
          }
          else {

            // Load Answers
            $scope.form = formData.data.Form;

            var formAnswers = localStorageService.get('formAnswers');
            if (formAnswers != null) {
              // TODO
              // Check if previous answers exists
              StartForm();
              LoadAnswers(formAnswers);
            } else {
              localStorageService.set('formAnswers', []);
              StartForm();
              ShowStep($scope.form.Steps[0]);
            }

            $scope.FormState = 'startform';

          }

        }

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, function () {
          Page.stateReload();
        });
      }

      // Methods
      function StartForm() {
        $scope.answer = new FormAnswer($state.params.token, $scope.form.FormId, formData.data.AnswerToken);

        // Set Tracking Variables
        $scope.currentStepId = 0;
        $scope.currentStepIndex = 0;

        // Auto Save Answers
        UpdateAnswers();
      }

      function DeleteAnswers() {
        var formAnswers = localStorageService.get('formAnswers');
        var indexes = [];

        // Find indexes
        for (var i = 0; i < formAnswers.length; i++) {
          var answer = formAnswers[i];

          // Delete if from the same module, or if they are more than 2 days old
          var now = new Date();
          var answerDate = new Date(answer.Date);
          var tooOld = ((now.getTime() - answerDate.getTime()) < (2 * 24 * 60 * 60 * 1000));
          if (answer.AccountModuleToken == $state.params.token || tooOld) {
            indexes.push(i);
          }
        }

        // Delete indexes
        for (var i = 0; i < indexes.length; i++) {
          formAnswers.splice((indexes[i] - i), 1);
        }

        return formAnswers;
      }

      function LoadAnswers(formAnswers) {
        // TODO
        //console.log(formAnswers);
        ShowStep($scope.form.Steps[0]);
      }

      function StopUpdateTimer() {
        if ($scope.updateTimer != undefined) {
          $timeout.cancel($scope.updateTimer);
        }
      }

      function UpdateAnswers() {
        // Stop Timer
        StopUpdateTimer();

        // Delete old answers
        var formAnswers = DeleteAnswers();

        // Gather new answers
        var answers = [];

        for (var i = 0; i < $scope.form.Steps.length; i++) {
          var step = $scope.form.Steps[i];
          for (var a = 0; a < step.Questions.length; a++) {
            var question = step.Questions[a];
            if (question.answers != undefined) {
              answers.push.apply(answers, question.answers);
            }
          }
        }

        // Cache answers
        $scope.answer.QuestionAnswers = answers;
        formAnswers.push($scope.answer);
        localStorageService.set('formAnswers', formAnswers);

        $scope.updateTimer = $timeout(function () {
          UpdateAnswers();
        }, 2000);
      }

      function AdjustNavigation() {
        var activeIndex = 0;
        for (var i = 0; i < $scope.form.Steps.length; i++) {
          var step = $scope.form.Steps[i];

          // Set Active
          step.inactive = !(step.active || step.FormStepId == $scope.currentStepId);
          step.active = (step.FormStepId == $scope.currentStepId);
          if (step.active) { activeIndex = i; }
        }

        $scope.currentStepIndex = activeIndex;

        var $elem = $('#form_step_' + $scope.currentStepId);
        if ($elem.length > 0) {
          var $formNav = $('#form_nav');
          var offset = $elem[0].offsetLeft - ($formNav.width() / 2) + ($elem.eq(0).width() / 2);
          $('#form_nav').animate({ scrollLeft: offset });
        }

        UpdateAnswers();
      }

      function IsComplete() {
        var isComplete = ($scope.currentStepIndex == ($scope.form.Steps.length - 1));
        if (isComplete) {
          for (var i = 0; i < $scope.form.Steps.length; i++) {
            if (!$scope.form.Steps[i].answered) {
              isComplete = false;
            }
          }
        }

        return isComplete;
      }

      function ShowNextStep() {
        $scope.currentStepId = $scope.form.Steps[$scope.currentStepIndex + 1].FormStepId;
        AdjustNavigation();
      }

      function ShowPreviousStep() {
        $scope.currentStepId = $scope.form.Steps[$scope.currentStepIndex - 1].FormStepId;
        AdjustNavigation();
      }

      function ShowStep(step) {
        $scope.currentStepId = step.FormStepId;
        AdjustNavigation();
      }

      function GoToStep(step) {
        ShowStep(step);
      }

      function CompleteForm() {
        if (!$scope.savingAnswers) {
          $scope.savingAnswers = true;
          UpdateAnswers();
          // Stop Timer
          StopUpdateTimer();

          // Send Answers
          Page.startLoading();
          $http.post('/Form/SaveAnswer', $scope.answer).then(function (response) {
            Page.stopLoading();
            if (response.data.Saved) {
              DeleteAnswers();

              // Show Completion Screen
              $translate('FORM.THANK_YOU').then(function (translation) {
                Page.showMessagePopup({
                  headline: translation,
                  icon: 'mdi mdi-check-circle',
                  cssClass: 'primary-color'
                }, function () {
                  // Send to Reports if they are enabled
                  if ($scope.settings.ShowReports) {
                    Page.stateGo('formReportList', $stateParams.token);
                  } else {
                    Page.stateGo('home');
                  }
                });
              });
            } else {
              $translate('ERROR.GENERAL').then(function (translation) {
                toastr.error(translation);
              });

              // Start timer
              UpdateAnswers();
              $scope.savingAnswers = false;
            }
          }, function (err) {
            $translate('ERROR.GENERAL').then(function (translation) {
              toastr.error(translation);
            });

            // Start timer
            UpdateAnswers();
            Page.stopLoading();
            $scope.savingAnswers = false;
          });
        }
      }

      // Objects
      function FormAnswer(accountModuleToken, formId, answerToken) {
        this.AccountModuleToken = accountModuleToken;
        this.FormId = formId;
        this.AnswerToken = answerToken;
        this.QuestionAnswers = [];
      }

      // Bind Scope
      $scope.showStep = ShowStep;
      $scope.showNextStep = ShowNextStep;
      $scope.showPreviousStep = ShowPreviousStep;
      $scope.isComplete = IsComplete;
      $scope.complete = CompleteForm;
      $scope.goToStep = GoToStep;
    }
  ]);

})();