(function () {
    'use strict';

    angular.module('App')
        .component('campaignSlot', {
            template: require('./CampaignSlotComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$element', 'Page', 'TranslatableEntityService', 'ToastFactory', 'CampaignService', CampaignSlotController],
            bindings: {
                slot: '<',
                campaign: '<',
                onPopupRegistered: '<',
                onClose: '<'
            }
        });

    function CampaignSlotController($rootScope, $element, Page, TranslatableEntityService, ToastFactory, CampaignService) {
        const ctrl = this;
        let popup;

        ctrl.popupId = 'CampaignSlotPopupId';
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        
        ctrl.openSurvey = openSurvey;
        ctrl.cover = null;
        ctrl.headerBackground = null;
        
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.isTranslationLoading = false;

        ctrl.getCustomClass = Page.getCustomCssClassesForAccountModule;

        function toggleTranslation() {
            if (ctrl.isTranslationLoading) {
                return;
            }

            const translateRequest = () => {
                ctrl.isTranslationLoading = true;
                return CampaignService.translateCampaignSlotRequest(ctrl.campaign, ctrl.slot);
            };

            TranslatableEntityService.switchCurrentLocale(ctrl.slot.TranslatableEntity, translateRequest).then(() => {
                if (!ctrl.campaign.TranslatableEntity?.LocalizedAlternativeLocale && ctrl.campaign.TranslatableEntity?.AllowAutoTranslation 
                    || !ctrl.campaign.TranslatableEntity?.LocalizedLocale) {
                    $rootScope.$emit('slot-translation-changed');
                }
                ctrl.isTranslationLoading = false;
            }).catch(() => {
                ctrl.isTranslationLoading = false;
                ToastFactory.errorTranslated('ERROR.GENERAL');
            });
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;

            initSlot();

            // Register activity as the slot is shown
            CampaignService.registerSlotActivity(ctrl.campaign.AccountModuleToken, ctrl.slot.CampaignSlotToken);
        }

        function initSlot() {
            // Determine if we should make a cover
            if (ctrl.slot.CoverMedia && ctrl.slot.CoverMedia.MediaType !== 1) {
                ctrl.cover = ctrl.slot.CoverMedia;
            } else {
                ctrl.cover = null;
            }

            // Determine background 
            var backgroundMedia = null;
            if (ctrl.slot.CoverMedia && ctrl.slot.CoverMedia.MediaType === 1) {
                backgroundMedia = ctrl.slot.CoverMedia;
            } else if (ctrl.slot.IconMedia) {
                backgroundMedia = ctrl.slot.IconMedia;
            }

            if (backgroundMedia) {
                ctrl.headerBackground = {
                    backgroundImage: 'url(' + backgroundMedia.ImageFormats['W' + $rootScope.imageWidth] + ')'
                };
            } else {
                ctrl.headerBackground = null;
            }
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
        }

        function openSurvey() {
            Page.stateGo('survey', ctrl.slot.SurveyModuleToken, ctrl.slot.SurveyToken);
            close();
        }
    }
})();
