(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('trainingModuleItem', {
            template: require('./TrainingModuleItemComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', '$stateParams', '$element', 'toastr', 'Page', 'TrainingService', TrainingModuleItemController],
            bindings: {
                module: '<',
                learningPathToken: '<',
                onInitDone: '<',
                isVoluntary: '<',
                courseToken: '<'
            }
        });

    function TrainingModuleItemController($filter, $stateParams, $element, toastr, Page, TrainingService) {
        var ctrl = this;

        ctrl.getModuleStatus = TrainingService.getItemStatus;
        ctrl.getModuleClass = TrainingService.getItemStatusClass;
        ctrl.getDurationString = TrainingService.getDurationString;
        ctrl.$onInit = init;

        function init() {
            $element.click(openModule);
            $element.addClass(`training-item module training-module-${ctrl.module.CourseModuleId}`);
            $element.addClass(ctrl.getModuleClass(ctrl.module));
            ctrl.onInitDone && ctrl.onInitDone();
        }

        function openModule() {
            if (ctrl.module.IsScormModule && ctrl.module.CanBeTaken) {
                Page.stateGo('trainingScormCloudCourse',
                    ctrl.module.CourseModuleToken,
                    ctrl.module.CourseModuleId,
                    {
                        courseToken: ctrl.courseToken,
                        isVoluntary: ctrl.isVoluntary
                    });
            } else {
                toastr.clear();
                const state = ctrl.isVoluntary ? 'trainingCourseModuleVoluntary' : 'trainingCourseModuleMandatory';

                if (ctrl.module.CanBeTaken) {
                    Page.stateGo(state, ctrl.module.CourseModuleToken, ctrl.courseToken, null, true, {
                        learningPathToken: ctrl.learningPathToken
                    });
                } else {
                    toastr.warning($filter('translate')('TRAINING.MODULE.NOT_ALLOWED'));
                }
            }
        }
    }
})();