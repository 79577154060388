(() => {
    'use strict';

    angular
        .module('App.Common')
        .factory('ToastFactory', ['$translate', '$q', 'toastr', function ($translate, $q, toastr) {
            return {
                error: error,
                errorTranslated: errorTranslated,
                errorTranslatedReplace: errorTranslatedReplace,
                success: success,
                successTranslated: successTranslated,
                successTranslatedReplace: successTranslatedReplace,
                warning: warning,
                warningTranslated: warningTranslated,
                warningTranslatedReplace: warningTranslatedReplace,
                info: info,
                infoTranslated: infoTranslated,
                translatedWithButton: translatedWithButton,
                infoTranslatedReplace: infoTranslatedReplace,
                modelStateWarning: modelStateWarning,
                clear: clear
            }

            function error(message) {
                toastr.error(message);
            }

            function errorTranslated(message) {
                translate(message, 'error');
            }

            function errorTranslatedReplace(message, replaceSearch, replaceText) {
                translateReplace(message, 'error', replaceSearch, replaceText);
            }

            function success(message) {
                toastr.success(message);
            }

            function successTranslated(message) {
                translate(message, 'success');
            }

            function successTranslatedReplace(message, replaceSearch, replaceText) {
                translateReplace(message, 'success', replaceSearch, replaceText);
            }

            function warning(message) {
                toastr.warning(message);
            }

            function warningTranslated(message) {
                translate(message, 'warning');
            }

            function warningTranslatedReplace(message, replaceSearch, replaceText) {
                translateReplace(message, 'warning', replaceSearch, replaceText);
            }

            function translatedWithButton(type, message, toastClass, buttonText, buttonFunction) {
                const messageTranslationPromise = $translate(message || 'ERROR.GENERAL');
                const buttonTranslationPromise = $translate(buttonText || 'CLOSE');

                $q.all([messageTranslationPromise, buttonTranslationPromise])
                    .then(translations => {
                        const messageTranslation = translations[0];
                        const buttonTranslation = translations[1];

                        const buttonHtml = `<button type='button'>${buttonTranslation}</button>`;
                        const fullMessage = `<div class="${toastClass}"><p>${messageTranslation}</p> ${buttonHtml}</div>`;

                        toastr[type](fullMessage,
                            {
                                ...toastr.options,
                                allowHtml: true,
                                onShown: toast => {
                                    toast.el[0].querySelector('button').onclick = buttonFunction;
                                },
                            });
                    })
                    .catch(() => {
                        toastr[type](message);
                    });
            }

            function info(message) {
                toastr.info(message);
            }

            function infoTranslated(message) {
                translate(message, 'info');
            }

            function infoTranslatedReplace(message, replaceSearch, replaceText) {
                translateReplace(message, 'info', replaceSearch, replaceText);
            }

            function modelStateWarning(response, onEmptyMessage) {
                if (response.status === 400 && _.isArray(response.data) && !_.isEmpty(response.data)) {
                    const modelErr = _.first(response.data);
                    warningTranslated(modelErr.Message);
                } else if (onEmptyMessage) {
                    warningTranslated(onEmptyMessage);
                }
            }

            function clear() {
                toastr.clear();
            }

            function translate(message, type) {
                $translate(message || 'ERROR.GENERAL')
                    .then(translation => toastr[type](translation))
                    .catch(() => toastr[type](message));
            }

            function translateReplace(message, type, replaceSearch, replaceText) {
                $translate(message || 'ERROR.GENERAL')
                    .then(translation => {
                        translation = translation.replace(replaceSearch, replaceText);
                        toastr[type](translation);
                    })
                    .catch(() => toastr[type](message));
            }
        }]);
})();