(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('DepartmentViewerService', ['$rootScope', '$compile', 'Page',
        function ($rootScope, $compile, PageService) {
            var viewerOpen = false;

            var f = {
                showDepartmentCard: function (token) {
                    ShowDepartmentCard(token);
                }
            };

            function ShowDepartmentCard(token) {
                if (!viewerOpen) {
                    viewerOpen = true;
                    var newScope = $rootScope.$new(true);

                    // Add reference to id
                    newScope.DepartmentToken = token;

                    var $viewer = $compile("<department-viewer></department-viewer>")(newScope);
                    $('body').append($viewer);

                    newScope.$on('$destroy', function () {
                        viewerOpen = false;
                    });
                }
            }

            return f;
        }
    ]);
})();