(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('skillstown', {
                    parent: 'base',
                    url: '/skillstown/:token',
                    templateUrl: '/Scripts/Controllers/Modules/SkillsTown/Templates/SkillsTown.tpl.html',
                    resolve: {
                        data: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/SkillsTown/GetModule/?token=' + $stateParams.token);
                            }
                            return;
                        }]
                    },
                    controller: 'SkillsTownController as SkillsTownCtrl'
                });
        }
    ]);


    app.controller('SkillsTownController', ['Page', '$scope', 'data', '$http', '$stateParams', 'events', '$interval',
        function (Page, $scope, data, $http, $stateParams, events, $interval) {
            var ctrl = this, geiUrlInterval;
            ctrl.Page = Page;

            Page.showBackButton();
            Page.setTitle(data.data.Title);

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateReload();
            });

            $scope.loading = true;

            init();

            geiUrlInterval = $interval(init, 5000);

            function init() {
                $http.get('/SkillsTown/Get/?token=' + $stateParams.token)
                    .then(function (response) {
                        $scope.loading = false;
                        $scope.url = response.data.Url;
                        $scope.error = response.data.ErrorMessage;
                    }, function (response) {
                        $scope.loading = false;
                        $scope.url = response.data.Url;
                        $scope.error = "SKILLSTOWN.ERROR.UNKNOWN";
                    })
            }

            $scope.openSSO = function () {
                $interval.cancel(geiUrlInterval);
                window.open($scope.url, '_blank');
            };

            $scope.$on('$destroy', function () {
                $interval.cancel(geiUrlInterval);
            });
        }
    ]);
})();