(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogCategory', {
            template: require('./CatalogCategoryComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', '$rootScope', 'Page', 'CatalogSearchService', 'ENTITY_TYPES', CatalogCategoryController],
            bindings: {
                settings: '<',
                items: '<',
                searchString: '<',
                category: '<',
                subcategories: '<',
                breadcrumbs: '<',
                accountModule: '<',
                searchPlaceholder: '<',
            }
        });

    function CatalogCategoryController($stateParams, $rootScope, Page, CatalogSearchService, ENTITY_TYPES) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.goToCategory = goToCategory;
        ctrl.goToCatalog = goToCatalog;

        function init() {
            ctrl.itemsSource = _.cloneDeep(ctrl.items);

            ctrl.categoryToken = ctrl.category.CatalogCategoryToken;
            ctrl.moduleToken = ctrl.accountModule.AccountModuleToken;

            ctrl.searchSettings = {
                accountModuleId: ctrl.settings.AccountModuleId,
                entityType: ENTITY_TYPES.CATALOG.CATEGORY,
                entityId: ctrl.categoryToken,
                viewName: 'CategoryView'
            };

            Page.showBackButtonFunction(() => {
                if (ctrl.isSearchActive) {
                    ctrl.isSearchActive = false;
                    $rootScope.$broadcast('CATALOG.RESET_SEARCH');
                } else {
                    Page.stateGoBack();
                }
            });
        }

        function goToCategory(category) {
            Page.stateGo(
                'catalogCategory',
                $stateParams.token,
                category.CatalogCategoryToken
            );
        }

        function goToCatalog() {
            Page.stateGo(
                'catalog',
                ctrl.accountModule.AccountModuleToken
            );
        }

        function destroy() {
            if (ctrl.moduleToken !== $stateParams.token) {
                CatalogSearchService.cleanSearchState();
            }
        }
    }
})();