(() => {
    'use strict';

    angular.module('App.Training')
        .component('training', {
            template: require('./TrainingComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'TrainingService', 'TRAINING_ROUTE', TrainingController],
            bindings: {
                trainingSettings: '<',
                state: '<'
            }
        });

    function TrainingController($stateParams, TrainingService, TRAINING_ROUTE) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.switchTranslatedVersion = switchTranslatedVersion;

        function init() {
            ctrl.ROUTE = TRAINING_ROUTE;
            ctrl.isInformationComponentEnabled = isInformationComponentEnabled();
            ctrl.isNewTraining = TrainingService.isNewTraining();
            
            if (ctrl.state === TRAINING_ROUTE.VOLUNTARY) {
                ctrl.searchString = $stateParams.data.searchString || $stateParams.options?.searchString;
                ctrl.voluntaryTab = $stateParams.data.currentTab || $stateParams.options?.currentTab;
            }
        }

        function switchTranslatedVersion() {
            TrainingService
                .selectTranslation(!ctrl.trainingSettings.PreferTranslatedVersion)
                .then(() =>
                    ctrl.trainingSettings.PreferTranslatedVersion = !ctrl.trainingSettings.PreferTranslatedVersion
                );
        }

        function isInformationComponentEnabled() {
            if (ctrl.state === ctrl.ROUTE.MY_TRAINING && ctrl.trainingSettings.AllowAutomaticTranslation) {
                if (ctrl.trainingSettings.Phases?.length > 0) {
                    return ctrl.trainingSettings.PhasesOverviewEnabled || ctrl.trainingSettings.IsMandatoryAllowed;
                }

                return ctrl.trainingSettings.IsMandatoryAllowed;
            }

            if (ctrl.state === TRAINING_ROUTE.VOLUNTARY) {
                return ctrl.trainingSettings.AllowAutomaticTranslation && ctrl.trainingSettings.IsVoluntaryAllowed && !ctrl.trainingSettings.IsMandatoryAllowed;
            }
        }
    }
})();