(() => {
    'use strict';

    angular
        .module('App')
        .component('taskManagement', {
            template: require('./TaskManagementComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', '$stateParams', '$rootScope', '$scope', 'TaskManagementRenderService',
                'TaskDetailsService', 'TemplateFactory', 'HeaderButtonsFactory', 'CreateUpdateTaskService',
                'FilterTasksService', 'Page', 'ModuleSearchService', 'ResponsiveService', 'TaskManagementService', TaskManagementController],
            bindings: {
                issues: '<',
                filteredIssues: '<',
                nextOffset: '<',
                categories: '<',
                overdueIssuesToShow: '<',
                feedLink: '<',
                moderateAllowed: '<',
                onSwitchView: '<',
                allowManagerView: '<',
                selectedDepartmentName: '@',
                enableHowToGuide: '<',
                showOverdueViewInMyTasks: '<',
                daysCountedInBadge: '<',
                setDefaultBackButton: '<'
            }
        });

    function TaskManagementController($filter, $stateParams, $rootScope, $scope, TaskManagementRenderService, TaskDetailsService,
                                      TemplateFactory, HeaderButtonsFactory, CreateUpdateTaskService, FilterTasksService, Page,
                                      ModuleSearchService, ResponsiveService, TaskManagementService) {
        const VIEW = {
            CALENDAR: 1,
            TASKS: 2,
            MY_TASKS: 3,
            SEARCH: 4,
            OVERDUE: 5,
            MANAGER: 6,
            OVERSEEING: 7
        };

        const ctrl = this;

        let taskUpdateListener, taskCreateListener, taskDeleteListener, taskFilterListener,
            taskManagementStateChangeListener, taskSeriesDeleteListener;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onScrollNext = onScrollNext;
        ctrl.onScrollPrev = onScrollPrev;
        ctrl.onScrollNextFilter = onScrollNextFilter;
        ctrl.onScrollPrevFilter = onScrollPrevFilter;
        ctrl.changeView = changeView;
        ctrl.openCreateTask = openCreateTask;
        ctrl.openOverdue = openOverdue;
        ctrl.openFilter = openFilter;
        ctrl.removeFilters = removeFilters;
        ctrl.openCalendar = openCalendar;
        ctrl.openTasksList = openTasksList;
        ctrl.openOverlooking = openOverlooking;
        ctrl.getCurrentDate = getCurrentDate;
        ctrl.onSearch = onSearch;

        function init() {
            ctrl.appliedFiltersCount = 0;
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.VIEW = VIEW;
            ctrl.initialModel = {
                Token: $stateParams.token,
                StartTime: moment().subtract(1, 'months').startOf('month'),
                EndTime: moment().add(1, 'month').endOf('month'),
            };
            ctrl.processing = true;
            ctrl.processingCalendarTab = true;
            ctrl.isCalendarProcessed = false;
            ctrl.isOverseeingCalendarProcessed = false;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.currentView = ctrl.VIEW.MY_TASKS;
            ctrl.serviceFormPopupId = TaskDetailsService.serviceFormPopupId;
            ctrl.isNative = TemplateFactory.isNative();
            ctrl.calendarData = {};

            initListeners();
            initScopeListeners();

            loadAllTasks()
                .then(() => {
                    prepareCalendarTab();
                    ctrl.formattedDate = moment().format('DD MMM YYYY');
                    !ctrl.isDesktop && HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                })
                .finally(() => ctrl.processing = false);
        }


        function getCurrentDate() {
            const prefix = ctrl.selectedDay && moment(ctrl.selectedDay.fullDate).isSame(moment(), 'day') ||
            moment(ctrl.formattedDate, 'DD MMM YYYY').isSame(moment(), 'day') ?
                `${$filter('translate')('CALENDAR.TODAY.TITLE')}, ` : '';
            return prefix + ctrl.formattedDate;
        }

        function onSearch(string) {
            ctrl.searchString = string;
        }

        function initListeners() {
            taskUpdateListener = $rootScope.$on('TaskManagement:taskUpdated', (ev, issue, resort) => {
                taskUpdated(issue, resort);
            });
            taskCreateListener = $rootScope.$on('TaskManagement:taskCreated', () => {
                Page.stateReload();
            });
            taskFilterListener = $rootScope.$on('TaskManagement:tasksFiltered', (ev, response, model, filtersCount, isReset) => {
                handleFiltering(response, model, filtersCount, isReset);
            });
            taskDeleteListener = $rootScope.$on('TaskManagement:taskDeleted', (ev, issue) => {
                taskDeleted(issue);
            });
            taskSeriesDeleteListener = $rootScope.$on('TaskManagement:taskSeriesDeleted', (ev, issue) => {
                taskSeriesDeleted(issue);
            });
            taskManagementStateChangeListener = $scope.$on('TaskManagement:stateChanged', () => {
                if (ctrl.currentView === ctrl.VIEW.OVERDUE || ctrl.currentView === ctrl.VIEW.SEARCH) {
                    Page.showBackButtonFunction(() => {
                        ctrl.changeView(ctrl.VIEW.MY_TASKS);
                    });
                } else {
                    ctrl.setDefaultBackButton && ctrl.setDefaultBackButton();
                }
            });
        }

        function initScopeListeners() {
            $scope.$watch('ctrl.selectedDay', day => {
                if (!ctrl.hasFilter) {
                    day?.issues?.sort((a, b) => {
                        return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                            new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                    });
                }
                showCalendarDay(day);
            }, true)

            $scope.$watch('ctrl.currentView', view => {
                if (ctrl.selectedDay) {
                    ctrl.selectedDay = false;
                }
            }, true)

            if (ctrl.isDesktop) {
                $scope.$watchGroup([
                    'ctrl.processing',
                    'ctrl.processingCalendarTab',
                    'ctrl.isLoadingFilteredTasks'
                ], newValues => {
                    if (newValues) {
                        ctrl.desktopLoader = newValues.includes(true);
                    }
                }, true)
            }
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ModuleSearchService.disableSearch();
            taskUpdateListener();
            taskCreateListener();
            taskFilterListener();
            taskDeleteListener();
            taskManagementStateChangeListener();
            taskSeriesDeleteListener();
        }

        function loadAllTasks() {
            return TaskManagementRenderService.getAllTasks(ctrl.initialModel).then(resp => {
                const completableOverdueIssues = TaskManagementService.getCompletableOverdueIssues(resp.Tasks);
                ctrl.overdueIssuesToShow = TaskManagementRenderService.mergeIssuesWithCategories(completableOverdueIssues);
                ctrl.issues = resp.Tasks;
                setIssuesToShow(resp.Tasks);
            })
                .catch(() => ToastFactory.errorTranslated('ERROR.GENERAL'))
                .finally(() => {
                    generateCalendar(ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                    ctrl.endTime = ctrl.initialModel.EndTime;
                });
        }

        function generateCalendar(issues) {
            TaskManagementRenderService.generateCalendar(issues, ctrl.categories, true)
                .then(calendarData => {
                    ctrl.calendarData = calendarData;
                    ctrl.calendarData.isUserAllowedToCompleteIssue = ctrl.currentView === ctrl.VIEW.MY_TASKS;
                    ctrl.processing = false;
                })
                .catch(() => {
                    ctrl.processing = false;
                });
        }

        function prepareCalendarTab() {
            if (!ctrl.isCalendarProcessed) {
                TaskManagementRenderService.fillCalendarWithLoadedTasks(ctrl.calendarData, ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                ctrl.isCalendarProcessed = true;
                ctrl.processingCalendarTab = false;
            }
        }

        function prepareOverseeingCalendarTab() {
            if (!ctrl.isCalendarProcessed) {
                TaskManagementRenderService.fillCalendarWithLoadedTasks(ctrl.calendarData, ctrl.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue));
                ctrl.processingCalendarTab = false;
                ctrl.isCalendarProcessed = true;
            }
        }

        function getHeaderButtons() {
            return [
                {
                    icon: 'search',
                    onClick: () => {
                        const previous = ctrl.currentView;
                        ctrl.changeView(ctrl.VIEW.SEARCH);

                        Page.showBackButtonFunction(() => {
                            ctrl.changeView(previous);
                        });
                    },
                },
                {
                    icon: 'filter',
                    onClick: () => {
                        ctrl.openFilter();
                    },
                    badges: ctrl.appliedFiltersCount
                }
            ];
        }

        function openOverlooking(view) {
            changeView(view);
        }

        function prepareCalendars() {
            if (ctrl.currentView === ctrl.VIEW.OVERSEEING) {
                ctrl.calendarData.isUserAllowedToCompleteIssue = false;
                if (ctrl.hasFilter) {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.filteredCalendarData, ctrl.filteredOverlookingIssues);
                    ctrl.formattedDate = `${moment(ctrl.currentFilterModel.StartTime).format('DD MMM YYYY')} - ${moment(ctrl.currentFilterModel.EndTime).format('DD MMM YYYY')}`;
                    ctrl.selectedDay = null;
                } else {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.calendarData, ctrl.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue));
                    ctrl.selectedDay = null;
                }

                prepareOverseeingCalendarTab();
                return;
            }

            if (ctrl.currentView === ctrl.VIEW.MY_TASKS) {
                ctrl.calendarData.isUserAllowedToCompleteIssue = true;
                if (ctrl.hasFilter) {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.filteredCalendarData, ctrl.filteredIssues);
                    ctrl.formattedDate = `${moment(ctrl.currentFilterModel.StartTime).format('DD MMM YYYY')} - ${moment(ctrl.currentFilterModel.EndTime).format('DD MMM YYYY')}`;
                    ctrl.selectedDay = null;
                } else {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.calendarData, ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                    ctrl.selectedDay = null;
                }

                prepareCalendarTab();
            }
        }
        
        function refillCalendar() {
            if (ctrl.currentView === ctrl.VIEW.OVERSEEING) {
                if (ctrl.hasFilter) {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.filteredCalendarData, ctrl.filteredOverlookingIssues);
                } else {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.calendarData, ctrl.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue));
                }
                return;
            }

            if (ctrl.currentView === ctrl.VIEW.MY_TASKS) {
                if (ctrl.hasFilter) {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.filteredCalendarData, ctrl.filteredIssues);
                } else {
                    TaskManagementRenderService.fillCalendarWithTasks(ctrl.calendarData, ctrl.issues.filter(issue => issue.IsUserAllowedToCompleteIssue));
                }
            }
        }

        function changeView(view) {
            ctrl.prevView = ctrl.currentView !== ctrl.VIEW.SEARCH ? ctrl.currentView : ctrl.prevView;
            ctrl.isCalendarActive = false;
            ctrl.formattedDate = moment().format('DD MMM YYYY');
            ctrl.currentView = view;
            ctrl.setDefaultBackButton();
            prepareCalendars();

            let translate = '';
            switch (view) {
                case ctrl.VIEW.MY_TASKS:
                    translate = 'TASK_MANAGEMENT.MENU.MY_TASKS';
                    break;
                case ctrl.VIEW.OVERDUE:
                    translate = 'TASK_MANAGEMENT.MENU.OVERDUE';
                    break;
                case ctrl.VIEW.SEARCH:
                    translate = 'SEARCH.TITLE';
                    break;
                case ctrl.VIEW.OVERSEEING:
                    translate = 'TASK_MANAGEMENT.MENU.OVERLOOKING';
                    break;
                case ctrl.VIEW.MANAGER:
                    translate = 'TASK_MANAGEMENT.MENU.MANAGER_VIEW';
                    break;
            }

            if (!ctrl.isDesktop) {
                HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                ctrl.onSwitchView(translate, view);
            }
        }

        function onScrollPrev(data) {
            return TaskManagementRenderService.getPreviousMonth(data, ctrl.issues);
        }

        function onScrollNext(data) {
            return TaskManagementRenderService.getNextMonth(data, ctrl.issues);
        }

        function onScrollPrevFilter(data) {
            return TaskManagementRenderService.getPreviousMonth(data, false,
                ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues);
        }

        function onScrollNextFilter(data) {
            return TaskManagementRenderService.getNextMonth(data, false,
                ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues);
        }

        function openCreateTask() {
            CreateUpdateTaskService.openCreateUpdatePopup();
        }

        function openFilter() {
            FilterTasksService.openFilterTasksPopup(ctrl.currentFilterModel);
        }

        function handleFiltering(data, model, filtersCount, isReset) {
            if (isReset) {
                removeFilters();
                return;
            }

            ctrl.currentFilterModel = model;
            ctrl.calendarModel = {...model, isUserAllowedToCompleteIssue: ctrl.currentView === ctrl.VIEW.MY_TASKS};
            ctrl.isCalendarActive = false;
            ctrl.filteredIssues = data.Tasks.filter(issue => issue.IsUserAllowedToCompleteIssue);
            ctrl.filteredOverlookingIssues = data.Tasks.filter(issue => !issue.IsUserAllowedToCompleteIssue);
            ctrl.nextOffset = data.NextOffset;
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = true;
            ctrl.appliedFiltersCount = filtersCount;

            if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                ctrl.processingCalendarTab = true;
            }

            const filteredTasks = ctrl.currentView === ctrl.VIEW.MY_TASKS ? ctrl.filteredIssues : ctrl.filteredOverlookingIssues;
            generateFilteredCalendar(model, filteredTasks);
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
        }

        function generateFilteredCalendar(model, filteredTasks) {
            TaskManagementRenderService
                .generateCalendar(filteredTasks, ctrl.categories, true, model.StartTime, model.EndTime)
                .then(calendarData => {
                    ctrl.filteredCalendarData = calendarData;
                    ctrl.formattedDate = `${moment(model.StartTime).format('DD MMM YYYY')} - ${moment(model.EndTime).format('DD MMM YYYY')}`;

                    ctrl.selectedDay = null;

                    if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                })
                .catch(() => {
                    if (ctrl.VIEW.MY_TASKS === ctrl.currentView ? ctrl.isCalendarProcessed : ctrl.isOverseeingCalendarProcessed) {
                        ctrl.processingCalendarTab = false;
                    }
                });
        }

        function removeFilters() {
            ctrl.selectedDayMyTasks = [];
            ctrl.selectedDayOverlookingTasks = [];
            ctrl.hasFilter = false;
            ctrl.currentFilterModel = null;
            ctrl.calendarModel = null;
            ctrl.isCalendarActive = false;
            ctrl.appliedFiltersCount = 0;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);

            if (ctrl.isCalendarActive) {
                // Prepare the calendar as it has not been done before
                ctrl.VIEW.MY_TASKS === ctrl.currentView && !ctrl.isCalendarProcessed && prepareCalendarTab();
                ctrl.VIEW.OVERSEEING === ctrl.currentView && !ctrl.isOverseeingCalendarProcessed && prepareOverseeingCalendarTab();
            } else if (ctrl.isDesktop) {
                ctrl.selectedDay = false;
                ctrl.formattedDate = moment().format('DD MMM YYYY');
            } else {
                showCalendarDay(ctrl.selectedDay);
            }
        }

        function showCalendarDay(day) {
            if (day) {
                ctrl.selectedDayMyTasks = day.issues.filter(issue => issue.IsUserAllowedToCompleteIssue);
                ctrl.selectedDayOverlookingTasks = day.issues.filter(issue => !issue.IsUserAllowedToCompleteIssue);
                ctrl.formattedDate = moment(day.fullDate).format('DD MMM YYYY');
            }
        }

        function openTasksList(view) {
            ctrl.isCalendarActive = false;
            changeView(view);
        }

        function openCalendar() {
            ctrl.isCalendarActive = true;
            prepareCalendars();
        }

        function openOverdue() {
            ctrl.changeView(ctrl.VIEW.OVERDUE);

            Page.showBackButtonFunction(() => {
                ctrl.changeView(ctrl.VIEW.MY_TASKS);
            });
        }

        function taskUpdated(issue, resort) {
            ctrl.issues = TaskManagementService.updateIssue(ctrl.issues, issue);
            setIssuesToShow(ctrl.issues);
            TaskManagementRenderService.updateIssuesToDays(ctrl.calendarData.flatDays, [issue]);

            if (ctrl.hasFilter && ctrl.filteredIssues?.length) {
                ctrl.filteredIssues = TaskManagementService.updateIssue(ctrl.filteredIssues, issue);
                TaskManagementRenderService.updateIssuesToDays(ctrl.filteredCalendarData.flatDays, [issue]);
            }

            if (!issue.IsOverdue) {
                ctrl.overdueIssuesToShow = ctrl.overdueIssuesToShow.filter(i => i.IssueId !== issue.IssueId);
            } else {
                ctrl.overdueIssuesToShow = TaskManagementService.updateIssue(ctrl.overdueIssuesToShow, issue);
            }
            
            if (resort) {
                ctrl.issues.sort((a, b) => {
                    return a.IsCompletedByCurrentUser - b.IsCompletedByCurrentUser ||
                        new Date(a.EndTimeLocal) - new Date(b.EndTimeLocal);
                });
            }

            refillCalendar();
        }

        function taskDeleted(issue) {
            ctrl.issues = ctrl.issues.filter(i => i.IssueId !== issue.IssueId);
            ctrl.selectedDayMyTasks = ctrl.selectedDayMyTasks?.filter(i => i.IssueId !== issue.IssueId);
            ctrl.selectedDayOverlookingTasks = ctrl.selectedDayOverlookingTasks?.filter(i => i.IssueId !== issue.IssueId);
            ctrl.overdueIssuesToShow = ctrl.overdueIssuesToShow?.filter(i => i.IssueId !== issue.IssueId);
            
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], false);
            setIssuesToShow(ctrl.issues);

            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                ctrl.filteredIssues = ctrl.filteredIssues.filter(i => i.IssueId !== issue.IssueId);
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], false);
            }
        }

        function taskSeriesDeleted(issue) {
            ctrl.issues = ctrl.issues.filter(i => i.ParentIssueId !== issue.ParentIssueId);
            ctrl.overdueIssuesToShow = ctrl.overdueIssuesToShow.filter(i => i.ParentIssueId !== issue.ParentIssueId);
            setIssuesToShow(ctrl.issues);
            TaskManagementRenderService.removeIssuesFromDays(ctrl.calendarData.flatDays, [issue], true);
            if (ctrl.hasFilter && ctrl.filteredIssues && ctrl.filteredIssues.length) {
                ctrl.filteredIssues = ctrl.filteredIssues.filter(i => i.ParentIssueId !== issue.ParentIssueId);
                TaskManagementRenderService.removeIssuesFromDays(ctrl.filteredCalendarData.flatDays, [issue], true);
            }
        }

        function setIssuesToShow(issues) {
            const {
                overviewIssues,
                managerIssues,
                overviewOverseeingIssues
            } = TaskManagementService.setIssuesToShow(issues);
            ctrl.overviewIssues = overviewIssues;
            ctrl.managerIssues = managerIssues;
            ctrl.overviewOverseeingIssues = overviewOverseeingIssues;
        }
    }
})();
