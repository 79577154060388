(function () {
    'use strict';

    angular.module('App')
        .component('scormcloudCourseItem', {
            templateUrl: '/Scripts/Components/ScormCloud/CourseItem/ScormCloudCourseItemComponent.html',
            controllerAs: 'ctrl',
            controller: ['$translate', '$timeout', 'ScormCloudService', 'Page', 'toastr', ScormCloudCourseItemController],
            bindings: {
                course: '<',
                onCourseInit: '<'
            }
        })

    function ScormCloudCourseItemController($translate, $timeout, ScormCloudService, Page, toastr) {
        var ctrl = this;

        ctrl.getStatusClass = ScormCloudService.getItemStatusClass;
        ctrl.getStatus = ScormCloudService.getItemStatus;
        ctrl.openCourse = openCourse;
        ctrl.$onInit = init;

        function init() {
            $timeout(function () {
                ctrl.onCourseInit && ctrl.onCourseInit();
            })
        }

        function openCourse(course) {
            if (course.CanBeTaken) {
                toastr.clear();
                Page.stateGo('scormcloudCourse', course.AccountModuleToken, course.CourseToken)
            } else {
                if (course.ProgressState === 2) {
                    $translate('SCORMCLOUD.COURSE.ALREADY_COMPLETED').then(function (translation) {
                        toastr.warning(translation);
                    });
                }
                else {
                    $translate('SCORMCLOUD.COURSE.NOT_ALLOWED').then(function (translation) {
                        toastr.warning(translation);
                    });
                }
            }
        }

    }
})();