(() => {
    'use strict';

    angular
        .module('App.Training')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('trainingLesson', {
                    parent: 'trainingSettings',
                    url: '/lesson/:token/:extra/:learningPathToken',
                    params: {
                        data: {},
                        backState: null,
                    },
                    templateProvider: ['trainingSettings', 'TrainingService', 'Page', (trainingSettings, TrainingService, Page) => {
                        return Page.getSettings().AdditionalQuestionTypes || TrainingService.isNewTraining() ?
                            '<lesson-page-v2 class="view-page"></lesson-page-v2>' :
                            '<lesson-page class="view-page" lesson-data="ctrl.lessonData"></lesson-page>';
                    }],
                    controller: 'LessonPageController as ctrl'
                })
        }])
        .controller('LessonPageController', ['$stateParams', 'LessonPageService', 'Page', LessonPageController]);

    function LessonPageController($stateParams, LessonPageService, Page) {
        const ctrl = this;

        if (Object.keys($stateParams.data).length) {
            const position = $stateParams.data.lessonIndex + 1 + '/' + $stateParams.data.lessons;
            ctrl.lessonData = $stateParams.data;

            Page.setTitle($stateParams.data.lesson.Title, position);
        }
        
        Page.showBackButton();
    }
})();
