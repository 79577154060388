(function () {
    'use strict';

    angular
        .module('App.Social')
        .component('socialLiveTile', {
            template: require('./SocialLiveTileComponent.html'),
            controller: ['$http', 'Page', 'SocialService', SocialLiveTileController],
            controllerAs: 'ctrl',
            bindings: {
                tile: '<'
            }
        });


    function SocialLiveTileController($http, Page, SocialService) {
        const ctrl = this;


        ctrl.isLoaded = false;
        ctrl.isLoading = true;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.readMore = readMore;

        function init() {
            if (ctrl.tile.AppLink.PathToken) {
                const limit = ctrl.tile.Settings.SocialPostCount || 20;
                $http.get('/Social/GetSocialWall/' + ctrl.tile.AppLink.PathToken + '?limit=' + limit + '&isLiveTile=true')
                    .then(resp => {

                        ctrl.title = resp.data.PageTitle;

                        ctrl.posts = resp.data.Posts;
                        ctrl.morePostsToLoad = resp.data.MorePosts;
                        ctrl.postsData = resp.data;
                        ctrl.postsData.AccountModuleToken = resp.data.ModuleToken;
                        ctrl.isLoaded = true;
                        ctrl.isLoading = false;
                        ctrl.ReadMoreText = resp.data.Settings.ReadMoreText;
                    }).catch(resp => {
                        ctrl.isLoading = false;
                        ctrl.isLoaded = false;
                    });
            } else {
                ctrl.isLoading = false;
                ctrl.isLoaded = false;
            }
        }

        function readMore() {
            Page.stateGo(ctrl.tile.AppLink.PathState, ctrl.tile.AppLink.PathToken, ctrl.tile.AppLink.PathExtra);
        }

        function destroy() {
            SocialService.destroyData();
        }
    }
})();


