(() => {
    'use strict';

    angular
        .module('App')
        .component('landingPage', {
            template: require('./Templates/landingPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$rootScope', '$state', '$stateParams', '$compile', '$timeout', '$sce', '$http', 'Page',
                'ToastFactory', 'ResponsiveService', 'CookieService', 'BasicHelper', 'LandingPageService', LandingPageController],
            bindings: {
                page: '='
            }
        });

    function LandingPageController($scope, $rootScope, $state, $stateParams, $compile, $timeout, $sce, $http, Page,
        ToastFactory, ResponsiveService, CookieService, BasicHelper, LandingPageService) {

        const ctrl = this,
            landingPageDesignClasses = {
                2: 'landing-page-design-creative',
                3: 'landing-page-design-creative'
            };

        let tilesHeight = [], responsiveWatcher;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = destroy;

        ctrl.openTileLink = OpenTileLink;
        ctrl.openTileApp = OpenTileApp;
        ctrl.hasBackground = LandingPageService.hasBackground;
        ctrl.getLandingPageBackground = LandingPageService.getLandingPageBackground;
        ctrl.createCustomClassString = LandingPageService.createCustomClassString;

        function onInit() {
            ctrl.imageWidth = $rootScope.imageWidth;

            $timeout(() => {
                window.addEventListener('orientationchange', LandingPageService.onOrientationchange);
                responsiveWatcher = ResponsiveService.watch(init);

                $scope.$watch('ctrl.page.Tiles', (ne, oe) => init(), true);
            })
        }

        function destroy() {
            window.removeEventListener('scroll', LandingPageService.onScroll);
            window.removeEventListener('orientationchange', LandingPageService.onOrientationchange);
            responsiveWatcher && responsiveWatcher();
        }

        function init() {
            tilesHeight = []

            ctrl.landingPageDesign = landingPageDesignClasses[ctrl.page.LandingPageDesignTypeId];
            ctrl.filteredTiles = ctrl.page.Tiles.filter(LandingPageService.getTileForDevice);
            ctrl.filteredTiles.sort(LandingPageService.sortTiles);

            ctrl.filteredTiles.forEach((tile, key) => {
                calculatePosition(tile, key === ctrl.filteredTiles.length - 1);
            })

            if (_.max(tilesHeight) > 100) {
                ctrl.isScrollable = true;

                window.removeEventListener('scroll', LandingPageService.onScroll);
                window.addEventListener('scroll', LandingPageService.onScroll);

                if (!CookieService.get('isScrollablePageVisited')) {
                    ctrl.showTip = true;
                    CookieService.set('isScrollablePageVisited', true);
                }
            }

            LandingPageService.scrollTo();
        }

        function deviceTileParam(tile, param) {
            return LandingPageService.getTileForDevice(tile)[param]
        }

        function OpenTileLink(tile) {
            switch (tile.LandingPageTileTypeId) {
                case 1:
                case 2:
                    if (tile.AppLink && tile.AppLink.ModuleId) {
                        if (tile.AppLink && tile.AppLink.PathState) {
                            var options = {};

                            if (tile.AppLink.ModuleId === 93 || tile.AppLink.ModuleId === 142) {
                                options.openCreateFilling = tile.Settings.OpenCreatePage;
                            }

                            // Set reset state of landing page which will be loaded to `true`
                            if (tile.AppLink.ModuleId === 61) {
                                Page.setClearStoredNavState(true);
                            }

                            if (tile.AppLink.ModuleId === 131) {
                                var isItIos = NativeHelper.isIOS() || BasicHelper.isIOS();
                                var isItAndroid = NativeHelper.isAndroid() || BasicHelper.isAndroid();
                                if (isItIos) {
                                    window.location.href = $sce.trustAsResourceUrl("msteams://l/chat/0/0");
                                } else {
                                    if (isItAndroid) {
                                        window.open("https://teams.microsoft.com/l/chat/0/0");
                                    } else {
                                        window.location.href = $sce.trustAsResourceUrl("msteams://l/chat/0/0");
                                    }
                                }

                                return false;
                            }

                            if (tile.AppLink.PreUrl) {
                                Page.stateGoLink(tile.AppLink);
                                return false;
                            }

                            if (tile.AppLink.ModuleId === 142) {
                                options.badge = tile.Badge;
                            }

                            // Open as normal link
                            Page.stateGo(tile.AppLink.PathState, tile.AppLink.PathToken, tile.AppLink.PathExtra, options);
                        } else {
                            ToastFactory.warningTranslated('ERROR.FORBIDDEN');
                        }
                    } else {
                        ToastFactory.warningTranslated('ERROR.GENERAL');
                    }
                    break;
                case 7:
                    Page.stateGoPrevious();
                    break;
                case 8:
                    window.location.href = "/Native/?redirect=false";
                    break;
                case 9:
                    if (tile.LinkedLandingPageId && tile.ChildToken) {
                        Page.stateGo('childLanding', tile.ChildToken);
                    } else {
                        ToastFactory.warningTranslated('ERROR.FORBIDDEN');
                    }
                    break;
            }
        }

        function OpenTileApp(tile) {
            const isItIos = NativeHelper.isIOS() || BasicHelper.isIOS(),
                isItAndroid = NativeHelper.isAndroid() || BasicHelper.isAndroid();
            let link = tile.AppUrlScheme;

            if (isItAndroid) {
                link = tile.AndroidAppUrlScheme;
            }
            if (isItIos) {
                link = tile.IosAppUrlScheme;
            }
            if (_.isString(link) && !_.isEmpty(link)) {
                const appLinkDirectiveScope = $rootScope.$new(true);

                appLinkDirectiveScope.data = {
                    IosAppStoreUrl: tile.IosAppStoreUrl,
                    AndroidAppStoreUrl: tile.AndroidAppStoreUrl,
                    IsItIos: isItIos,
                    IsItAndroid: isItAndroid
                };
                window.location.href = $sce.trustAsResourceUrl(link);

                const nativeAppOverlay = $compile("<native-app-overlay></native-app-overlay>")(appLinkDirectiveScope);
                angular.element(document.querySelector('body')).append(nativeAppOverlay);
            }
        }

        function getBackTransition() {
            let backOptions = {};
            if (LandingPageService.getScrollPosition() > 0) {
                backOptions = {
                    scrollPosition: LandingPageService.getScrollPosition()
                };
            }
            return new StateTransitionClass($state.current.name, $stateParams.token, $stateParams.extra, backOptions);
        }

        function calculatePosition(tile, isLast) {
            const w = deviceTileParam(tile, 'Width'),
                h = deviceTileParam(tile, 'Height'),
                t = deviceTileParam(tile, 'PosY'),
                l = deviceTileParam(tile, 'PosX'),
                footerHeight = document.querySelector('.base-bottom')?.clientHeight || 0;

            tilesHeight.push((h + t));

            if ($rootScope.isRTL) {
                tile.style = {
                    width: w + '%',
                    height: 'calc((100% - ' + footerHeight + 'px' + ') / 100 * ' + h,
                    top: 'calc((100% - ' + footerHeight + 'px' + ') / 100 * ' + t,
                    right: l + '%',
                    zIndex: tile.ZIndex
                };
            } else {
                tile.style = {
                    width: w + '%',
                    height: 'calc((100% - ' + footerHeight + 'px' + ') / 100 * ' + h,
                    top: 'calc((100% - ' + footerHeight + 'px' + ') / 100 * ' + t,
                    left: l + '%',
                    zIndex: tile.ZIndex
                };
            }


            if (isLast) {
                ctrl.spacer = {
                    top: 'calc((100% - ' + footerHeight + 'px' + ') * ' + (t + h) + ' / 100)'
                }
            }

            tile.contentStyle = {};

            if (!ResponsiveService.isMobile() &&
                tile.DesktopBackgroundMediaId != null &&
                tile.DesktopBackgroundMediaId.MediaId > 0) {
                tile.BackgroundMediaId = tile.DesktopBackgroundMediaId;
            }

            if (tile.BackgroundMediaId != null && tile.BackgroundMediaId.MediaId > 0) {

                tile.contentStyle.backgroundImage = LandingPageService.getTileBackground(tile);

                // Check to see if an overlay on the background should be shown
                switch (tile.LandingPageTileTypeId) {
                    case 1:
                    case 3:
                    case 5:
                    case 6:
                    case 9:
                        tile.showOverlay = true;
                        break;
                    default:
                        tile.showOverlay = false;
                        break;
                }
            } else {
                tile.showOverlay = false;
            }
        }
    }
})();