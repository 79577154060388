(() => {
    'use strict';

    angular.module('App.Training')
        .component('learningPathPage', {
            template: require('./LearningPathPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'TrainingService', 'Page', LearningPathPageController],
            bindings: {
                courses: '<',
                learningPath: '<',
                performanceRanges: '<',
                moduleToken: '<',
                learningPathToken: '<'
            }
        });

    function LearningPathPageController($element, TrainingService, Page) {
        var ctrl = this, initCount = 0;

        ctrl.onInitDone = onInitDone;
        ctrl.$onInit = init;
        ctrl.$onDestroy = onDestroy;

        ctrl.completedCourses = _.filter(ctrl.courses, {State: 3}).length;

        function init() {
            Page.setCustomCssClass(ctrl.moduleToken);
        }

        function onInitDone() {
            initCount++;
            if (initCount === ctrl.courses.length) {
                TrainingService.scrollToTrainingItem($element);
            }
        }

        function onDestroy() {
            Page.removeCustomCssClass(ctrl.moduleToken);
        }
    }
})();