(function () {
    'use strict';
    var app = angular.module('App');

    app.factory('ProfileViewerService', ['Page', 'PopupWrapperService',
        function (PageService, PopupWrapperService) {

            return {
                showProfileCard: function (token) {
                    ShowProfileCard(token);
                }
            };

            function ShowProfileCard(token) {
                PopupWrapperService.createDynamic(
                    '<user-profile-popup token="token"></user-profile-popup>',
                    {
                        token: token
                    }
                )
            }
        }
    ]);
})();