(() => {
    'use srict';

    angular
        .module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('taskitem', {
                        parent: 'base',
                        url: '/taskitem/:token',
                        template: require('./TaskItemPage.html'),
                        resolve: {
                            issue: ['$stateParams', 'base', 'TaskManagementRenderService',
                                function ($stateParams, base, TaskManagementRenderService) {
                                    if (base) {
                                        return TaskManagementRenderService.getTaskPreviewData(null, $stateParams.token);
                                    }
                                }]
                        },
                        controller: 'TaskItemController as ctrl'
                    });
            }
        ])
        .controller('TaskItemController', ['$stateParams', 'BasicHelper', 'Page', 'issue', TaskItemController]);

    function TaskItemController($stateParams, BasicHelper, Page, issue) {
        var ctrl = this;

        Page.showBackButton();
        Page.setTitle(issue.Title);

        ctrl.newTaskCreated = newTaskCreated;

        ctrl.issue = issue;
        ctrl.moderateAllowed = issue.ModerateAllowed;

        function newTaskCreated() {
            Page.stateReload();
        }
    }
})();