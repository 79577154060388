(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonPage', {
            template: require('./LessonPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'ToastFactory', 'LessonPageService', LessonPageComponentController],
            bindings: {
                lessonData: '<',

            }
        });

    function LessonPageComponentController($stateParams, Page, ToastFactory, LessonPageService) {
        const ctrl = this,
            LESSON_FINISH_REDIRECT = {
                LESSONS: 1,
                MODULES: 2,
                COURSES_PHASES: 3,
                PHASE: 4
            },
            VIEW = {
                LESSON: 1,
                FINISH: 2,
                BADGE: 3
            };
        let answers, answerFeedback;

        ctrl.$onInit = init;
        ctrl.next = next;
        ctrl.submit = submit;

        function init() {
            ctrl.loading = true;

            ctrl.VIEW = VIEW;
            ctrl.LESSON_FINISH_REDIRECT = LESSON_FINISH_REDIRECT;

            ctrl.currentView = ctrl.VIEW.LESSON;
            ctrl.CourseToken = $stateParams.extra;
            ctrl.testimonial = {
                rating: 0,
                submitted: false,
                comment: ''
            };

            LessonPageService.getLesson({lessonToken: $stateParams.token}).then(initLesson)
        }

        function initLesson(resp) {
            ctrl.hidePreloaders = true;
            answers = resp.Answers;
            answerFeedback = resp.AnswerFeedback;

            ctrl.lesson = resp.Lesson;
            ctrl.AccountModuleToken = resp.AccountModuleToken;
            ctrl.CourseModuleToken = resp.CourseModuleToken;
            ctrl.NextLessonToken = resp.NextLessonToken;
            ctrl.NextLessonCanBeTaken = resp.NextLessonCanBeTaken
            ctrl.currentLessonIndex = resp.LessonIndex;
            ctrl.totalLessonsLength = resp.CourseLessonsCount;

            ctrl.showAnswers = ctrl.lesson.Action !== 1;
            ctrl.emptyLesson = ctrl.lesson.QuestionList.length === 0;
            ctrl.isValid = ctrl.emptyLesson;

            ctrl.isVoluntary = !ctrl.CourseToken?.length;
            ctrl.dataToSubmit = {
                CourseToken: ctrl.CourseToken,
                CourseLessonToken: ctrl.lesson.CourseLessonToken,
                Questions: []
            };

            updatePageData()

            if (!ctrl.lesson.Expired && ctrl.lesson.Action === 3) {
                LessonPageService.askForRedo(addAnswersAndFeedback).then(() => {
                    ctrl.showAnswers = false;
                }, addAnswersAndFeedback)
            } else if (ctrl.lesson.Action !== 1) {
                addAnswersAndFeedback();
            }

            ctrl.loading = false;
        }

        function updatePageData() {
            Page.setTitle(ctrl.lesson.Title, ctrl.currentLessonIndex + 1 + '/' + ctrl.totalLessonsLength);
            Page.showBackButton();
        }

        function submit() {
            if (!ctrl.loading) {
                ctrl.loading = true;
                LessonPageService.submitAnswer(ctrl.dataToSubmit, ctrl.lesson)
                    .then(submitAnswer)
                    .catch(() => {
                        ctrl.loading = false;
                    });
            }
        }

        function submitAnswer(resp) {
            ctrl.loading = false;
            ctrl.showAnswers = true;
            ctrl.answered = true;

            ctrl.lesson.Action = 4;
            ctrl.lesson = resp.Lesson;

            ctrl.LessonStateId = resp.LessonStateId;
            ctrl.ModuleStateId = resp.ModuleStateId;
            ctrl.CourseStateId = resp.CourseStateId;
            ctrl.PhaseStateId = resp.PhaseStateId;
            ctrl.GiveTestimonial = resp.GiveTestimonial;
            ctrl.trainingBadges = resp.NewUserCourseBadges;
            ctrl.TrainingPhaseToken = resp.TrainingPhaseToken;
            ctrl.PhasesOverviewEnabled = resp.PhasesOverviewEnabled
            ctrl.NextLessonCanBeTaken = resp.NextLessonCanBeTaken

            if (resp.UserName) {
                ctrl.UserName = resp.UserName.split(' ')[0];
            }

            ctrl.TestimonialsCommentRequiredScore = resp.TestimonialsCommentRequiredScore || 0;

            if (ctrl.emptyLesson) {
                next();
            }
        }

        function next() {
            if (ctrl.answered) {
                if (ctrl.trainingBadges.length && !ctrl.badgeShown) {
                    ctrl.currentView = ctrl.VIEW.BADGE;
                    ctrl.badgeShown = true;
                    return false;

                } else if (ctrl.currentView !== ctrl.VIEW.FINISH && ctrl.ModuleStateId === 3) {
                    ctrl.currentView = ctrl.VIEW.FINISH;

                    return false;
                } else if (ctrl.GiveTestimonial) {
                    if (!ctrl.testimonial.submitted) {
                        if (!ctrl.testimonial.rating) {
                            ToastFactory.errorTranslated('TRAINING.LESSON.TESTIMONIAL.RATING_ERROR');

                            return false;
                        } else if (ctrl.testimonial.rating < ctrl.TestimonialsCommentRequiredScore && !ctrl.testimonial.comment) {
                            ToastFactory.errorTranslated('TRAINING.LESSON.TESTIMONIAL.EMPTY');

                            return false;
                        }

                        ctrl.testimonial.CourseToken = ctrl.CourseToken;
                        LessonPageService.submitTestimonial(ctrl.testimonial).then(testimonial => {
                            ToastFactory.successTranslated('TRAINING.LESSON.TESTIMONIAL.THANKS');

                            handleRedirect();
                        });
                        return false;
                    }
                }

                if (handleRedirect()) {
                    return false;
                }
            } else if (ctrl.currentLessonIndex === ctrl.totalLessonsLength - 1) {
                finishLesson(ctrl.LESSON_FINISH_REDIRECT.LESSONS);
                return false;
            }
            if (ctrl.NextLessonCanBeTaken) {
                showNextLesson();
            } else {
                finishLesson(ctrl.LESSON_FINISH_REDIRECT.LESSONS);
            }
        }

        function showNextLesson() {
            if (ctrl.currentLessonIndex + 1 === ctrl.totalLessonsLength) {
                finishLesson(ctrl.LESSON_FINISH_REDIRECT.LESSONS);
            } else {
                Page.stateGo('trainingLesson', ctrl.NextLessonToken, ctrl.CourseToken);
            }
        }

        function addAnswersAndFeedback() {
            ctrl.showAnswers = true;
            ctrl.lesson = LessonPageService.questionsAddAnswersAndFeedback(ctrl.lesson, answers, answerFeedback);
        }

        function handleRedirect() {
            if (ctrl.PhasesOverviewEnabled) {
                if (ctrl.PhaseStateId === 3) {
                    finishLesson(ctrl.LESSON_FINISH_REDIRECT.COURSES_PHASES);

                    return true;
                }
                if (ctrl.CourseStateId === 3) {
                    finishLesson(ctrl.LESSON_FINISH_REDIRECT.PHASE);

                    return true;
                }
                if (ctrl.ModuleStateId === 3) {
                    finishLesson(ctrl.LESSON_FINISH_REDIRECT.MODULES);

                    return true;
                }

                return false;
            }

            if (ctrl.CourseStateId === 3) {
                finishLesson(ctrl.LESSON_FINISH_REDIRECT.COURSES_PHASES);

                return true;
            }
            if (ctrl.ModuleStateId === 3) {
                finishLesson(ctrl.LESSON_FINISH_REDIRECT.MODULES);

                return true;
            }

            return false;
        }

        function finishLesson(location) {
            switch (location) {
                case ctrl.LESSON_FINISH_REDIRECT.LESSONS:
                    Page.stateGoBack('trainingCourseModuleMandatory', ctrl.CourseModuleToken, ctrl.CourseToken);
                    break;
                case ctrl.LESSON_FINISH_REDIRECT.MODULES:
                    if (ctrl.isVoluntary) {
                        Page.stateGoBack('trainingVoluntary', ctrl.AccountModuleToken);
                    } else {
                        Page.stateGoBack('trainingCourse', ctrl.CourseToken);
                    }
                    break;
                case ctrl.LESSON_FINISH_REDIRECT.COURSES_PHASES:
                    Page.stateGoBack(ctrl.isVoluntary ? 'trainingVoluntary' : 'trainingMy', ctrl.AccountModuleToken);
                    break;
                case ctrl.LESSON_FINISH_REDIRECT.PHASE:
                    if (ctrl.isVoluntary) {
                        Page.stateGoBack('trainingVoluntary', ctrl.AccountModuleToken);
                    } else {
                        Page.stateGoBack('trainingPhase', ctrl.TrainingPhaseToken);
                    }
                    break;
            }
        }
    }
})();