(() => {
    'use strict';

    angular.module('App')
        .component('profileSettings', {
            template: require('./ProfileSettingsComponent.tpl.html'),
            controller: ['ProfileSettingsService', 'Page', ProfileSettingsComponentController],
            controllerAs: 'ctrl'
        });

    function ProfileSettingsComponentController(ProfileSettingsService, Page) {
        var ctrl = this,
            departmentSwapWatcher;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.openPersonalInformation = openPersonalInformation;
        ctrl.openSecurity = openSecurity;
        
        ctrl.openLeaveRegistration = openLeaveRegistration;

        ctrl.openSharedInformation = openSharedInformation;
        ctrl.openCommunication = openCommunication;
        ctrl.openCelebrations = openCelebrations;
        ctrl.openBlockedUsers = openBlockedUsers;
        ctrl.openPrivacyPolicy = openPrivacyPolicy;

        ctrl.openNotifications = openNotifications;
        ctrl.openLanguage = openLanguage;
        ctrl.openAccessibility = openAccessibility;

        function onInit() {
            ctrl.hasCelebrations = false;
            ctrl.canChangeLanguage = false;
            ctrl.hasPolicy = false;

            ctrl.isLoadingProfileData = true;
            ProfileSettingsService.getFullProfile().then(res => {
                initData(res);
                ctrl.hasLeaveRegistration = res.LeaveRegistrationEnabled;
                ctrl.isLoadingProfileData = false;
            }).catch(() => {
                ctrl.isLoadingProfileData = false;
            });
        }

        function onDestroy() {
            if (departmentSwapWatcher) {
                departmentSwapWatcher();
            }
        }

        function initData(data) {
            ctrl.profile = data;
            ctrl.canChangeLanguage = ctrl.profile.CanChangeCulture;
            ctrl.hasCelebrations = ctrl.profile.CelebrationsFeatureEnabled;
            ctrl.hasPolicy = ctrl.profile.HasPolicy;
        }

        // Account
        function openPersonalInformation() {
            Page.stateGo('profileSettingsPersonalInformation');
        }

        function openSecurity() {
            Page.stateGo('profileSettingsSecurity');
        }   
        
        // Leave
        function openLeaveRegistration() {
            Page.stateGo('leaveRegistration');
        }

        // Privacy
        function openSharedInformation() {
            Page.stateGo('profileSettingsSharedInformation');
        }

        function openCommunication() {
            Page.stateGo('profileSettingsCommunication');
        }

        function openCelebrations() {
            Page.stateGo('profileSettingsCelebrations');
        }

        function openBlockedUsers() {
            Page.stateGo('blockedUsers');
        }

        function openPrivacyPolicy() {
            Page.stateGo('policyOverview');
        }

        // Preferences
        function openNotifications() {
            Page.stateGo('notificationsSettings');
        }

        function openLanguage() {
            Page.stateGo('profileSettingsLanguage');
        }

        function openAccessibility() {
            Page.stateGo('profileSettingsAccessibility');
        }
    }
})();