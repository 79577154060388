(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
            function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
                $stateProvider
                    .state('serviceForm', {
                        parent: 'base',
                        url: '/serviceForm/:token/:extra',
                        params: {
                            backState: null,
                            data: {}
                        },
                        template: `<div class="service-form-page">
                                    <service-form class="service-form"></service-form>
                                </div>`,
                        controller: 'ServiceFormController as ctrl'
                    })
            }
        ])
        .controller("ServiceFormController", ['$scope', '$stateParams', 'Page', 'events',
            function ($scope, $stateParams, Page, events) {

                Page.showBackButton();

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function (args) {
                    Page.stateReload();
                });
            }
        ]);
})();