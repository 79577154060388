(function () {
    'use strict';

    angular.module('App')
        .component('reminders', {
            templateUrl: '/Scripts/Components/Reminders/RemindersComponent.html',
            controllerAs: 'ctrl',
            controller: ['$filter', 'ActionSheetService', 'RemindersService', 'ConfirmPopupService', 'Page',
                'MODULE_ID', RemindersController],
            bindings: {
                reminders: '<'
            }
        });

    function RemindersController($filter, ActionSheetService, RemindersService, ConfirmPopupService, Page, MODULE_ID) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.openOptions = openOptions;
        ctrl.openItem = openItem;

        function init() {
            ctrl.empty = _.isEmpty(ctrl.reminders)
        }

        function openOptions(reminder, event) {
            var buttons = [
                {
                    text: RemindersService.getSetReminderString(reminder.ReminderDateTimeLocal),
                    icon: 'alarm-clock',
                    onClick: function () {
                        RemindersService.openSetReminders(
                            reminder.AccountModuleToken,
                            reminder.EntityId,
                            reminder.ModuleId,
                            moment(reminder.ReminderDateTimeLocal).toDate(),
                            function () {
                                updateReminders();
                            }
                        );
                    }
                },
                {
                    text: 'REMINDERS.REMOVE_REMINDER',
                    icon: 'delete',
                    onClick: function () {
                        ConfirmPopupService.open({
                            message: $filter('translate')('REMINDERS.DELETE_REMINDER')
                        }).then(function () {
                            RemindersService.deleteReminder(reminder.ReminderToken).then(function () {
                                updateReminders();
                            })
                        })
                    }
                }];
            var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, { name: 'reminder', token: reminder.AccountModuleToken});
            actionSheet.show();
        }

        function openItem(reminder) {
            Page.stateGo(getModuleState(reminder.ModuleId), reminder.EntityToken);
        }

        function getModuleState(moduleId) {
            switch (moduleId) {
                case MODULE_ID.CONTENT:
                    return 'contentItem'
            }
        }

        function updateReminders() {
            RemindersService.getReminders().then(function (reminders) {
                ctrl.reminders = reminders;
                ctrl.empty = _.isEmpty(ctrl.reminders)
            })
        }
    }
})();