(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badgeValidityPopup', {
            template: require('./BadgeValidityPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'TrainingService', '$http', BadgeValidityPopupController],
            bindings: {
                badge: '<',
                onClose: '<',
            }
        });


    function BadgeValidityPopupController($stateParams, Page, TrainingService, $http) {
        const ctrl = this;
        ctrl.goToTraining = goToTraining;
        const validityTypeMap = {
            'Not obtained': 'TRAINING.BADGE.NOT_OBTAINED',
            'Obtained on': 'TRAINING.BADGE.OBTAINED',
            'Expired on': 'TRAINING.BADGE.EXPIRED_ON',
            'Valid until': 'TRAINING.BADGE.VALID_UNTIL',
            'Deactivated': 'TRAINING.BADGE.DEACTIVATED',
        }

        ctrl.badgeClass = '';
        ctrl.complianceExpired = null;
        ctrl.complianceStatus = '';

        ctrl.$onChanges = function () {
            if (ctrl.badge) {
                init();
                const {validityType, date} = mapValidityTypeForBadge(ctrl.badge);
                ctrl.validityType = validityType;
                ctrl.date = date;
                ctrl.complianceExpired = ctrl.badge.CourseRefresherExpirationDate !== null && new Date(ctrl.badge.CourseRefresherExpirationDate) < new Date()
            }
        };

        function init() {
            switch (ctrl.badge?.BadgeStatus) {
                case 1:
                    ctrl.badgeClass = 'active';
                    break;
                case 2:
                    ctrl.badgeClass = 'not-active'
                    break;
                case 3:
                    ctrl.badgeClass = 'not-achieved'
                    break;
                case 4:
                    ctrl.badgeClass = 'not-active'
            }

            switch (ctrl.badge?.CourseRefresherStatus) {
                case 0:
                    ctrl.complianceStatus = 'Compliant';
                    break;
                case 1:
                    ctrl.complianceStatus = 'RenewStart';
                    break;
                case 2:
                    ctrl.complianceStatus = 'Expired';
                    break;
            }
        }

        function mapValidityTypeForBadge(badge) {
            if (badge.DateCreatedLocalString && badge.CourseRefresherExpirationDate === null && badge.BadgeStatus !== 2) {
                return {
                    validityType: `${validityTypeMap['Obtained on']}`,
                    date: badge.DateCreatedLocalString
                };
            }
            if (badge.CourseRefresherExpirationDate !== null && badge.CourseRefresherStatus === 2) {
                return {
                    validityType: `${validityTypeMap['Expired on']}`,
                    date: badge.CourseRefresherExpirationDate
                };
            }
            if (badge.CourseRefresherExpirationDate !== null && new Date(badge.CourseRefresherExpirationDate) > new Date()) {
                return {
                    validityType: `${validityTypeMap['Valid until']}`,
                    date: badge.CourseRefresherExpirationDate
                };
            }
            if (!badge.CourseRefresherExpirationDate && !badge.DateCreatedLocalString) {
                return {
                    validityType: validityTypeMap['Not obtained'],
                    date: null
                };
            }
            if (badge.BadgeStatus === 2) {
                return {
                    validityType: `${validityTypeMap['Deactivated']}`,
                    date: null
                };
            }
        }

        function goToTraining() {
            if (TrainingService.isNewTraining()) {
                $http
                    .get(`/TrainingV2/Training/EntityLinkDirect/${ctrl.badge.EntityToken}?entityType=${ctrl.badge.EntityType}`)
                    .then(TrainingService.goByEntityLink)
            } else {
                goToTrainingOld();
            }
        }

        function goToTrainingOld() {
            if (ctrl.badge.PhaseToken) {
                Page.stateGo('training', $stateParams.token, ctrl.badge.PhaseToken)
            }
            if (ctrl.badge.CourseModuleToken) {
                if (ctrl.badge.CourseModuleHasCourse) {
                    Page.stateGo('trainingCourseModuleMandatory', ctrl.badge.CourseModuleToken, ctrl.badge.CourseToken)
                } else {
                    Page.stateGo('trainingCourseModuleVoluntary', ctrl.badge.CourseModuleToken)
                }
            }
            if (ctrl.badge.CourseToken) {
                Page.stateGo('trainingCourse', ctrl.badge.CourseToken)
            }
        }
    }
})();