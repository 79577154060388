(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badge', {
            template: require('./BadgeComponent.html'),
            controllerAs: 'ctrl',
            controller: [BadgeController],
            bindings: {
                badge: '<',
                badgeClass: '<',
                complianceStatus: '<'
            }
        });

    function BadgeController() {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.badgeClass = '';
        ctrl.complianceStatus = '';

        function init() {
            switch (ctrl.badge?.BadgeStatus) {
                case 1:
                    ctrl.badgeClass = 'active';
                    break;
                case 2:
                    ctrl.badgeClass = 'not-active'
                    break;
                case 3:
                    ctrl.badgeClass = 'not-achieved'
                    break;
                case 4:
                    ctrl.badgeClass = 'not-active'
                    break;
            }
            
            switch(ctrl.badge?.CourseRefresherStatus) {
                case 0:
                    ctrl.complianceStatus = 'Compliant';
                    break;
                case 1:
                    ctrl.complianceStatus = 'RenewStart';
                    break;
                case 2:
                    ctrl.complianceStatus = 'Expired';
                    break;
            }
        }
    }
})();