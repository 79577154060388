(function () {
    'use strict';
    angular
        .module('App')
        .directive('walkthrough', ['$http', 'Profile', '$translate', 'Page', '$timeout', 'toastr',
            'WalkthroughFactory', '$rootScope', walkthroughDirective]);

    function walkthroughDirective($http, Profile, $translate, Page, $timeout, toastr, WalkthroughFactory, $rootScope) {
        return {
            restrict: 'E',
            templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough.tpl.html',
            controller: ['$scope', '$element', function ($scope, $element) {

                // Init
                InitDirective();

                function InitDirective() {
                    // Init variables
                    $scope.WalkthroughFactory = WalkthroughFactory;
                    $scope.data = WalkthroughFactory.get();

                    $scope.loading = false;
                    $scope.globalLoading = false;
                    $scope.slides = [];
                    $scope.slideIndex = 0;
                    $scope.policiesCount = 0;

                    // Only show if needed
                    if (!WalkthroughFactory.isChecked() && $scope.data != null) {
                        // Set as initialized
                        $timeout(function () {
                            WalkthroughFactory.initialized();
                            $timeout(function () {
                                var walkthrough = $element.find('.walkthrough');
                                walkthrough.css({
                                    height: walkthrough.height(),
                                    bottom: 'auto'
                                })
                            });


                        });

                        // Create slides
                        CreateSlides($scope.data);

                        // Only Continue if there are any slides
                        if ($scope.slides.length > 0) {
                            $scope.policiesCount = countPolicySlides($scope.slides);
                            $scope.showProgressBar = ($scope.slides.length > 100);

                            // Set Last Slide property
                            $scope.slides[$scope.slides.length - 1].LastSlide = true;

                            // Init Swiper
                            $scope.swiper = {};
                            $scope.swiperParams = {
                                allowSlidePrev: true,
                                allowSlideNext: true,
                                noSwiping: false,

                                keyboard: {
                                    enabled: true
                                }
                            };
                            if ($scope.slides.length > 1) {
                                $scope.swiperParams.pagination = {
                                    el: '.swiper-pagination',
                                    paginationType: ($scope.showProgressBar) ? 'progress' : 'bullets'
                                };
                            }
                        } else {
                            // Exit the walkthrough as no slides exist
                            Exit();
                        }
                    } else {
                        // Exit the walkthrough if needed
                        Exit();
                    }
                }

                function CreateSlides(data) {
                    for (var i = 0; i < data.Slides.length; i++) {
                        var slide = data.Slides[i];
                        var newSlide = new Slide(i, slide.SlideType);

                        switch (slide.SlideType) {
                            case 1: // DEFAULT
                                newSlide.ID = slide.TypeData.WalkthroughSlideId;
                                newSlide.SlideData = slide.TypeData;
                                break;
                            case 2: // SIGNUP
                                newSlide.ID = 'signup';
                                newSlide.AllowBack = false;
                                break;
                            case 3: // POLICY
                                if (slide.Policy != null) {
                                    newSlide.ID = 'policy';
                                    newSlide.SlideData = {
                                        Policy: slide.Policy
                                    };
                                } else {
                                    newSlide = null;
                                }
                                break;
                            case 4: // APP ACCESS
                                newSlide.ID = 'appaccess';
                                newSlide.AllowBack = false;
                                break;
                            default:
                                newSlide = null;
                                break;
                        }

                        if (newSlide != null) {
                            $scope.slides.push(newSlide);
                        }
                    }
                }

                // Methods
                function countPolicySlides(slides) {
                    return slides.filter(slide => slide.ID === 'policy').length;
                }

                function listenForEvents(swiper) {
                    $scope.swiper = swiper;

                    swiper.on('slideChange', function () {
                        $rootScope.safeApply(function () {
                            NextSlide($scope.swiper.activeIndex);
                        });
                    });

                    // Listen for loading on slides
                    $scope.$watch('slides', function (newVal, oldVal) {
                        var isLoading = false;
                        for (var i = 0; i < newVal.length; i++) {
                            var slide = newVal[i];
                            if (slide.Loading) {
                                isLoading = true;
                            }
                        }

                        $scope.loading = isLoading;
                        AllowSlide($scope.slideIndex);
                    }, true);

                    // Run a start index
                    NextSlide($scope.slideIndex);
                }

                function NextSlide(index) {
                    if (index === undefined || index == null) {
                        index = $scope.slideIndex;
                    }

                    if (index < $scope.slides.length) {
                        // Set slide as Active
                        ActivateSlide(index);
                    } else {
                        // Exit as we were at the last slide
                        Exit();
                    }
                }

                function ActivateSlide(newIndex) {
                    // Only activate if within allowed indexes
                    if (newIndex >= 0 && newIndex < $scope.slides.length) {
                        // Activate/Deactive slides
                        for (var i = 0; i < $scope.slides.length; i++) {
                            $scope.slides[i].Active = (i === newIndex);
                        }

                        $scope.slideIndex = newIndex;
                    }

                    // Check if you are allowed to swipe back or forward
                    AllowSlide($scope.slideIndex);
                }

                function AllowSlide(index) {
                    if (index >= 0 && index < $scope.slides.length) {
                        var slide = $scope.slides[index];

                        // Next
                        if (index !== ($scope.slides.length - 1) && slide.Type === 3) {
                            $scope.swiper.allowSlideNext = slide.Done;
                            $scope.swiper.update();
                        }

                        // Prev
                        if (index > 0) {
                            var prevSlide = $scope.slides[index - 1];
                            $scope.swiper.allowSlidePrev = prevSlide.AllowBack;
                            $scope.swiper.update();
                        }
                    }
                }

                function SlideDone() {
                    // Set slide as done
                    if (($scope.slideIndex + 1) === $scope.slides.length) {
                        // Exit as we are completely done
                        Exit();
                    } else {
                        $scope.slides[$scope.slideIndex].Done = true;

                        $scope.swiper.allowSlideNext = true;
                        $scope.swiper.update();
                        $scope.swiper.slideNext();
                    }
                }

                function Exit() {
                    if (!$scope.globalLoading) {
                        if ($scope.slides.length > 0) {
                            // Save completion
                            $scope.globalLoading = true;

                            // Create data object
                            var requestData = {
                                Slides: []
                            };
                            for (var i = 0; i < $scope.slides.length; i++) {
                                if ($scope.slides[i].Type < 1000) {
                                    requestData.Slides.push($scope.slides[i].ID);
                                }
                            }

                            if (requestData.Slides.length > 0) {
                                // Send data if there is any to send
                                $http.post('/Walkthrough/SaveActivities', requestData).then(function (response) {
                                    $scope.globalLoading = false;
                                    WalkthroughFactory.end($element);

                                    if ($scope.data.AppLink && WalkthroughFactory.isOpenAppLink()) {
                                        Page.appLinkGo($scope.data.AppLink);
                                    }
                                }, function () {
                                    $scope.globalLoading = false;

                                    $translate('ERROR.GENERAL').then(function (translation) {
                                        toastr.error(translation);
                                    });
                                });
                            } else {
                                // Just close immidiately
                                WalkthroughFactory.end($element);
                            }
                        } else {
                            // Just close immidiately
                            WalkthroughFactory.end($element);
                        }
                    }

                }

                function SlideBack() {
                    $scope.swiper.slidePrev();
                }

                // Objects
                function Slide(id, type) {
                    this.ID = id;
                    this.Done = false;
                    this.Active = false;
                    this.Type = type;
                    this.LastSlide = false;
                    this.AllowBack = true;
                    this.Loading = false;
                    this.SlideData = null;
                }

                // Bindings
                $scope.listenForEvents = listenForEvents;
                $scope.Exit = Exit;
                $scope.Done = SlideDone;
                $scope.Back = SlideBack;
            }]
        }
    }
})();