(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('serviceFormOverview', {
                        parent: 'base',
                        url: '/serviceFormOverview/:token',
                        params: {
                            backState: null,
                            data: {}
                        },
                        template: `<forms-overview></forms-overview>`,
                        controller: 'FormsOverviewController as ctrl'
                    })
            }
        ])
        .controller("FormsOverviewController", ['$scope', 'Page', 'events',
            function ($scope, Page, events) {
                Page.showBackButton();
                
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }]);
})();