(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
            function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
                $stateProvider
                    .state('about', {
                        parent: 'base',
                        url: '/about',
                        template: '<div view-page view-page-scrolling="false"><about-relesys></about-relesys></div>',
                        controller: ['$stateParams', '$translate', 'Page', function ($stateParams, $translate, Page) {
                            $translate('ABOUT_RELESYS.TITLE').then(function (translated) {
                                Page.setTitle(translated);
                            });

                            Page.showBackButton();
                        }]
                    });
            }
        ]);
})();