(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('ranking', {
                    parent: 'base',
                    url: '/ranking/:token',
                    templateUrl: '/Ranking',
                    resolve: {
                        rankingPage: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/Ranking/GetRankingPage/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'RankingController as RankingCtrl'
                });
        }]);

    app.controller('RankingController', ['$scope', '$state', 'Page', 'rankingPage', 'events',
        function ($scope, $state, Page, rankingPage, events) {
            var ctrl = this;

            // Init
            InitController();
            function InitController() {
                Page.showBackButton();
                Page.setTitle(rankingPage.data.PageTitle);
                
                $scope.groupList = rankingPage.data.RankingGroupList;
                //console.log($scope.groupList);

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateReload();
                });
            }
        }
    ]);
})();