(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('social', {
            template: require('./SocialComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$state', '$scope', '$filter', '$stateParams', 'Page', 'SocialWallService', 'SocialService',
                'HeaderButtonsFactory', 'ResponsiveService', 'SocialSortingService', 'SocialGroupService', SocialController],
            bindings: {
                postsData: '<',
                openCreatePopup: '<'
            }
        });

    function SocialController($timeout, $state, $scope, $filter, $stateParams, Page, SocialWallService, SocialService,
                              HeaderButtonsFactory, ResponsiveService, SocialSortingService, SocialGroupService) {
        const ctrl = this;
        let destroyHashtagWatch;

        ctrl.VIEW = {
            SOCIAL_WALL: 2,
            MEMBERS: 3
        };
        ctrl.searchQuery = {};
        ctrl.currentView = ctrl.VIEW.SOCIAL_WALL;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.changeTab = changeTab;
        ctrl.showCreatePost = showCreatePost;
        ctrl.onSearch = onSearch;
        ctrl.onDateFilterSelected = onDateFilterSelected;
        ctrl.resetFilters = resetFilters;
        ctrl.searchForTag = searchForTag;
        ctrl.searchPaging = searchPaging;
        ctrl.onContentOpened = onContentOpened;
        ctrl.isFilterSelected = isFilterSelected;
        ctrl.onOldHashtagSearch = onOldHashtagSearch;

        function init() {
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.searchPlaceholder = SocialService.getSearchPlaceholder(ctrl.postsData);
            ctrl.searchResultsOptions = SocialSortingService.getOptions(false);
            ctrl.socialGroupId = $stateParams.extra ||
                ctrl.postsData.SelectedSocialGroupId ||
                SocialGroupService.getSelectedSocialGroupId(ctrl.postsData.AccountModuleToken);
            ctrl.postsData.Settings.SocialGroupId = ctrl.socialGroupId;
            ctrl.allowPosting = ctrl.postsData.AllowPosting || getCurrentGroup()?.AllowPosting;

            if ($stateParams.data.showSearch) {
                ctrl.hideResults = true;
                ctrl.showSearch = true;
                ctrl.searchQuery = $stateParams.data.searchQuery;
                ctrl.showSearchResults = true;

                $timeout(() => {
                    $timeout(() => {
                        document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                        ctrl.hideResults = false;
                    })
                }, 300);
            }

            SocialService
                .getPosts(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId)
                .then(({Posts, MorePosts, Timestamp}) => {
                    ctrl.posts = Posts;
                    ctrl.morePostsToLoad = MorePosts;
                    ctrl.postsData.Timestamp = Timestamp;
                    ctrl.showMembersTab = ctrl.postsData.Settings.GroupMembersEnabled &&
                        (ctrl.socialGroupId || !ctrl.postsData.IsSharedView);

                    ctrl.isPostsLoaded = true;
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                })

            if (ctrl.postsData.Settings.HashtagsEnabled) {
                destroyHashtagWatch = $scope.$watch('ctrl.searchQuery.searchText', (string) => {
                    ctrl.showSuggestions =
                        string?.length &&
                        string[0] === '#' &&
                        string.split(' ').length === 1;
                })
            }

            Page.showBackButtonFunction(() => {
                if (ctrl.showSearchResults || ctrl.showSearch) {
                    ctrl.showSearchResults = false;
                    ctrl.searchQuery = {};
                    ctrl.lastSearchedText = null;
                    ctrl.showSearch = false;
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                    Page.stateGo('social', ctrl.postsData.AccountModuleToken, ctrl.postsData.SelectedSocialGroupId ?? '', {skipBackNavigation: true}, false);
                } else {
                    Page.stateGoBack();
                }
            });
        }

        function getCurrentGroup() {
            return ctrl.socialGroupId ?
                ctrl.postsData.SocialGroups.find(group => (group.SocialGroupId ?? '').toString() === ctrl.socialGroupId.toString()) :
                ctrl.postsData.SocialGroups[0]
        }

        function searchForTag(tag) {
            ctrl.searchQuery.searchText = '#' + tag;
            $timeout(() => {
                onSearch();
            })
        }

        function isFilterSelected() {
            return ctrl.searchQuery.startDate || ctrl.searchQuery.endDate;
        }

        function onSearch() {
            document.querySelector('html').scrollTop = 0;
            ctrl.showSuggestions = false;
            if (ctrl.searchQuery.searchText?.length) {
                ctrl.layzyLoading = true;
                delete ctrl.searchQuery.batchLastEntityId;
                getSearchResults()
                    .then(({BatchLastEntityId, Items}) => {
                        ctrl.searchQuery = {
                            ...ctrl.searchQuery,
                            searchResultsList: Items,
                            batchLastEntityId: BatchLastEntityId,
                        }
                        ctrl.lastSearchedText = ctrl.searchQuery.searchText;
                        ctrl.showSearchResults = true;
                        ctrl.layzyLoading = false;
                    })
                    .catch(() => {
                        ctrl.showSearchResults = false;
                        ctrl.layzyLoading = false;
                    })
                    .finally(() => Page.stateGo('social', ctrl.postsData.AccountModuleToken, ctrl.postsData.SelectedSocialGroupId ?? '', 
                        { showSearch: true, searchQuery: ctrl.searchQuery, skipBackNavigation: true }, false))
            } else {
                ctrl.showSearchResults = false;
                isFilterSelected() && getPosts();
            }
        }

        function getPosts() {
            ctrl.isPostsLoaded = false;
            SocialService
                .getPosts(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId, 0, 20,
                    ctrl.searchQuery.sortType, ctrl.searchQuery.startDate, ctrl.searchQuery.endDate)
                .then(({Posts, MorePosts}) => {
                    ctrl.posts = Posts.map(post => setCurrentCulture(post));
                    ctrl.morePostsToLoad = MorePosts;
                    ctrl.isPostsLoaded = true;
                })
        }

        function setCurrentCulture(item) {
            item.TranslatableEntity._currentCulture = selectCurrentLocale(item.TranslatableEntity);
            if (item.SocialGroupTranslatableEntity) {
                item.SocialGroupTranslatableEntity._currentCulture = selectCurrentLocale(item.SocialGroupTranslatableEntity);
            }

            return item
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function onDateFilterSelected(dateFilterId, {startDate, endDate} = {}) {
            ctrl.searchQuery.selectedDateFilterId = dateFilterId;
            ctrl.searchQuery.startDate = startDate;
            ctrl.searchQuery.endDate = endDate;
            ctrl.showSearchResults ? onSearch() : getPosts();
        }

        function resetFilters() {
            delete ctrl.searchQuery.selectedDateFilterId;
            delete ctrl.searchQuery.startDate;
            delete ctrl.searchQuery.endDate;
            ctrl.resetDateFilter && ctrl.resetDateFilter();
            ctrl.showSearchResults ? onSearch() : getPosts();
        }

        function searchPaging() {
            ctrl.layzyLoading = true;

            getSearchResults()
                .then(({BatchLastEntityId, Items}) => {
                    ctrl.searchQuery = {
                        ...ctrl.searchQuery,
                        searchResultsList: [...ctrl.searchQuery.searchResultsList, ...Items],
                        batchLastEntityId: BatchLastEntityId,
                    }
                    ctrl.layzyLoading = false;
                })
                .catch(() => {
                    ctrl.layzyLoading = false;
                })
        }

        function getSearchResults() {
            return SocialService
                .getSearchResults({
                    accountModuleId: ctrl.postsData.NavigationBadge.AccountModuleId,
                    socialGroupId: ctrl.socialGroupId,
                    searchText: ctrl.searchQuery.searchText,
                    sortType: ctrl.searchQuery.sortType,
                    afterEntityId: ctrl.searchQuery.batchLastEntityId,
                    startDate: ctrl.searchQuery.startDate,
                    endDate: ctrl.searchQuery.endDate
                })
        }

        function onContentOpened(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            Page.stateGoLinkV2(item.EntityLink);
        }

        function getHeaderButtons() {
            const buttons = [],
                hasGroups = ctrl.postsData.Settings.IsGroupViewEnabled && ctrl.postsData.SocialGroups.length > 1;

            if (ctrl.isDesktop) {
                const titleItems = [
                    {
                        title: $filter('translate')('SOCIAL.HEADERS.SOCIAL_WALL'),
                        onClick: () => {
                            ctrl.changeTab(ctrl.VIEW.SOCIAL_WALL);
                        },
                        active: ctrl.currentView === ctrl.VIEW.SOCIAL_WALL
                    },
                ];

                ctrl.showMembersTab && titleItems.push({
                    title: $filter('translate')('SOCIAL.HEADERS.MEMBERS'),
                    onClick: () => {
                        ctrl.changeTab(ctrl.VIEW.MEMBERS);
                    },
                    active: ctrl.currentView === ctrl.VIEW.MEMBERS
                });

                hasGroups && titleItems.push({
                    title: $filter('translate')('SOCIAL.HEADERS.SOCIAL_GROUPS'),
                    onClick: () => {
                        Page.stateGo('socialOverview', $stateParams.token);
                    }
                });
                
                if (ctrl.showMembersTab || hasGroups){
                    buttons.push({
                        icon: 'arrow-bottom',
                        activeIcon: 'arrow-top',
                        place: 'title',
                        items: titleItems
                    });
                }

            } else {
                if (hasGroups) {
                    buttons.push({
                        place: 'right',
                        icon: 'grid-plus',
                        onClick: () => {
                            Page.stateGo('socialOverview', $stateParams.token);
                        }
                    })
                }

                if (ctrl.currentView !== ctrl.VIEW.MEMBERS) {
                    buttons.push({
                        place: 'right',
                        icon: 'search',
                        active: ctrl.showSearch,
                        activeIcon: 'close',
                        onClick: () => {
                            ctrl.showSearch = !ctrl.showSearch;
                        }
                    });
                }
            }

            return buttons;
        }

        function changeTab(newView) {
            ctrl.currentView = newView;
            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
            if (newView === ctrl.VIEW.MEMBERS && !ctrl.usersLoded) {
                ctrl.socialGroupMembers = SocialService.getMembers(ctrl.postsData.AccountModuleToken, ctrl.socialGroupId)
                    .then(users => {
                        ctrl.socialGroupMembers = users;
                        ctrl.usersLoded = true

                        return users
                    });
            }
        }

        function showCreatePost() {
            SocialWallService.showCreatePost({
                settings: ctrl.postsData.Settings,
                postsData: ctrl.postsData
            })
        }

        function onOldHashtagSearch() {
            ctrl.showSuggestions = ctrl.searchQuery.searchText.length > 0
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            SocialService.destroyData();
            destroyHashtagWatch && destroyHashtagWatch()
        }
    }
})();