(function () {
    'use strict';

    angular
        .module('App')
        .directive('profileNavigation', ['$rootScope', '$sce', 'ProfileNavigationFactory', 'Profile', 'BookmarkService',
            'NotificationFactory', 'Page', 'toastr', 'ModalService', 'ResponsiveService', 'ChatConnectionService',
            'events', 'BasicHelper',
            function ($rootScope, $sce, ProfileNavigationFactory, Profile, BookmarkService, NotificationFactory, Page,
                      toastr, ModalService, ResponsiveService, ChatConnectionService, events,
                      BasicHelper) {
                return {
                    restrict: 'E',
                    templateUrl: '/Scripts/Directives/Templates/ProfileNavigation.tpl.html',
                    controller: ['$scope', '$element', '$attrs', '$rootScope', '$state',
                        function ($scope, $element, $attrs, $rootScope, $state) {
                            $scope.ProfileNavigationFactory = ProfileNavigationFactory;

                            var menu,
                                settings = {
                                    navigation: $('#profile-navigation', $element),
                                    overlay: $('#profile-navigation-overlay', $element)
                                };

                            // Init Controller
                            initController();

                            function initController() {
                                $scope.Profile = Profile;
                                $scope.Bookmarks = BookmarkService;
                                $scope.Notifications = NotificationFactory;
                                $scope.getChatBadgesCount = ChatConnectionService.getChatBadge;

                                menu = $element.find('.profile-navigation').get(0);
                                menu.inert = true;
                            }

                            // Bindings
                            $scope.close = close;
                            $scope.openContent = openContent;
                            $scope.openTeams = openTeams;
                            $scope.showBookmarks = showBookmarks;
                            $scope.showNotifications = showNotifications;
                            $scope.showPointLog = showPointLog;
                            $scope.hasPolicy = hasPolicy;
                            $scope.showPolicy = showPolicy;

                            // Functions
                            function openContent(state, token, extra) {
                                close();
                                Page.stateGo(state, token, extra);
                            }

                            function openTeams() {
                                var isItIos = NativeHelper.isIOS() || BasicHelper.isIOS();
                                var isItAndroid = NativeHelper.isAndroid() || BasicHelper.isAndroid();
                                if (isItIos) {
                                    window.location.href = $sce.trustAsResourceUrl("msteams://l/chat/0/0");
                                } else {
                                    if (isItAndroid) {
                                        window.open("https://teams.microsoft.com/l/chat/0/0");
                                    } else {
                                        window.location.href = $sce.trustAsResourceUrl("msteams://l/chat/0/0");
                                    }
                                }
                            };

                            function hasPolicy() {
                                return Profile.getProfile().HasPolicy;
                            }

                            function showPolicy() {
                                close();
                                Page.stateGo('policyOverview');
                            }

                            function showPointLog() {
                                close();
                                Profile.showPointLog();
                            }

                            function showBookmarks() {
                                close();
                                Page.stateGo('bookmarks')
                            }

                            function showNotifications() {
                                Page.stateGo('notifications');
                                close();
                            }

                            function close() {
                                menu.inert = true;
                                Page.setPageContentInert(false);
                                ProfileNavigationFactory.closeNavigation();
                            }

                            function open() {
                                ProfileNavigationFactory.openNavigation();
                            }

                            function enableAnimation() {
                                settings.navigation.addClass('animate');
                                settings.overlay.removeClass('dragging');
                                settings.overlay.addClass('animate');
                            }

                            function disableAnimation() {
                                settings.navigation.removeClass('animate');
                                settings.overlay.addClass('dragging');
                                settings.overlay.removeClass('animate');
                            }

                            function getPercentage(deltaX) {
                                if (deltaX < 0) deltaX = -deltaX;

                                var percentage = (deltaX / settings.navigation.width() * 100);
                                if (percentage > 100 || deltaX > settings.navigation.width()) percentage = 100;
                                if (percentage < 0) percentage = 100;
                                return percentage;
                            }

                            function isOpen(ev) {
                                return (ProfileNavigationFactory.isVisible() && $(ev.target).closest(settings.overlay).length);
                            }

                            // ====================================
                            // Movement starts
                            // ====================================
                            function handlePanEvent(ev) {
                                // Are we handling a close or open pan?
                                if (isOpen(ev)) {

                                    var x = ev.center.x;
                                    var deltaX = ev.deltaX;
                                    var startX = (x + (-deltaX));

                                    var percentage = getPercentage(deltaX);

                                    // Close Event
                                    if (deltaX > 0) {
                                        // Disable animation
                                        disableAnimation();
                                        // Set Style
                                        settings.navigation.css('transform', 'translateX(' + percentage + '%)');
                                        settings.overlay.css('opacity', ((100 - percentage) / 100));
                                    }
                                }
                            }

                            // ====================================
                            // Movement ends
                            // ====================================
                            function handlePanEndEvent(ev) {
                                // Enable animation
                                enableAnimation();

                                settings.navigation.removeAttr('style');
                                settings.overlay.removeAttr('style');

                                // Are we handling a close or open pan?
                                if (isOpen(ev)) {

                                    var x = ev.center.x;
                                    var deltaX = ev.deltaX;
                                    var percentage = getPercentage(deltaX);

                                    // Close Event
                                    if (percentage <= 40) {
                                        // Keep open
                                        open();
                                    } else {
                                        // Close
                                        close();
                                    }
                                }
                            }
                        }]
                }
            }
        ]);
})();