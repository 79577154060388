(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('policyOverview', {
                        parent: 'base',
                        url: '/policies',
                        template: '<div view-page view-page-scrolling="false"><policy-overview></policy-overview></div>',
                        resolve: {},
                        params: {
                            backState: null
                        },
                        controller: 'PolicyOverviewController as ctrl'
                    });
            }
        ])
        .controller('PolicyOverviewController', ['$translate', 'Page',
            function ($translate, Page) {

                $translate('POLICY.POLICIES').then(function (translation) {
                    Page.setTitle(translation);
                });
                Page.showBackButton();
            }]);
})();