(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettingsSecurityPassword', {
                        parent: 'base',
                        url: '/profileSettings/account/security/password',
                        template: '<div class="profile-settings-edit-page">' +
                            '<profile-settings-security-password can-go-back-requester="ctrl.canGoBackRequester" class="view-page narrow">' +
                            '</profile-settings-security-password></div>',
                        resolve: {},
                        params: {
                            data: null
                        },
                        controller: 'ProfileSettingsSecurityPasswordController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsSecurityPasswordController', ['$translate', 'Page',
            function ($translate, Page) {
                $translate('PROFILE.PASSWORD.CHANGE_PASSWORD').then(function (translation) {
                    Page.setTitle(translation);
                });

                var ctrl = this;
                ctrl.backState = Page.getBackHistoryState();
                ctrl.canGoBackRequester = null;

                Page.showBackButtonFunction(() => {
                    if (ctrl.canGoBackRequester) {
                        ctrl.canGoBackRequester().then(() => {
                            goBack();
                        });
                    } else{
                        goBack();
                    }
                });

                function goBack() {
                    if (ctrl.backState) {
                        Page.stateGoBack(ctrl.backState.state, ctrl.backState.token, ctrl.backState.extra)
                    } else {
                        Page.stateGoBack('profileSettingsSecurity');
                    }
                }
            }]);
})();