(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider', '$urlRouterProvider', '$httpProvider', '$locationProvider',
        function ($stateProvider, $urlRouterProvider, $httpProvider, $locationProvider) {
            $stateProvider
                .state('employeeEvaluation', {
                    parent: 'base',
                    url: '/employeeEvaluation/:token/:extra',
                    templateUrl: '/Templates/Modules/EmployeeEvaluation/EmployeeEvaluation.html',
                    resolve: {
                        employeeEvaluationData: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/EmployeeEvaluation/GetListPage/' + $stateParams.token);
                            } return;
                        }]
                    },
                    controller: 'EmployeeEvaluationController as EmployeeEvaluationCtrl'
                });
        }
    ]);

    app.controller('EmployeeEvaluationController', ['Page', '$scope', '$http', '$stateParams', 'toastr', '$translate', '$timeout', 'employeeEvaluationData', 'AttachmentsViewerService', 'BasicHelper', 'ActionSheetService', 'events',
        function (Page, $scope, $http, $stateParams, toastr, $translate, $timeout, employeeEvaluationData, AttachmentsViewerService, BasicHelper, ActionSheetService, events) {
            var ctrl = this;

            // Init
            Init();
            function Init() {
                Page.setTitle(employeeEvaluationData.data.PageTitle);
                SetBackButton();

                // Bind Data
                $scope.hasAccess = true;//employeeEvaluationData.data.HasAccess;
                $scope.isSupervisor = true;//employeeEvaluationData.data.IsSupervisor;
                $scope.isEmployee = employeeEvaluationData.data.IsEmployee;

                $scope.typeSelected = !($scope.isSupervisor && $scope.isEmployee);
                $scope.showSupervisor = $scope.isSupervisor;

                // Force into EmployeeView
                if ($stateParams.extra != undefined && $stateParams.extra != null && $stateParams.extra == 'employee') {
                    $scope.typeSelected = true;
                    $scope.showSupervisor = false;

                    Page.showBackButtonFunction(ShowTypeSelection);
                }

                $scope.settings = employeeEvaluationData.data.Settings;
                $scope.now = new Date();

                $scope.filter = {
                    UserGroup: null,
                    Period: String(new Date($scope.now.getFullYear(), $scope.now.getMonth(), 1).getTime())
                };

                // Create list
                if ($scope.hasAccess) {
                    SortReports(employeeEvaluationData.data.SupervisorReportList, true);
                    SortReports(employeeEvaluationData.data.EmployeeReportList, false);

                    if ($scope.isSupervisor) {
                        $scope.userGroupList = employeeEvaluationData.data.UserGroupList;
                        $scope.periodList = [];

                        // Period List
                        angular.forEach(employeeEvaluationData.data.PeriodList, function (item) {
                            $scope.periodList.push({
                                moment: moment(item),
                                value: String(BasicHelper.getDateTime(item).getTime())
                            });
                        });
                    }
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                  Page.stateReload();
                });
            }

            // Methods
            function SetBackButton(){
                Page.showBackButton();
            }

            function SortReports(data, isSupervisor) {
                var reports = [];
                
                var periodFormat = (isSupervisor) ? 'MMMM YYYY' : 'YYYY';
                var reportFormat = 'LL';
                var currentPeriod = '';

                switch ($scope.settings.EmployeeEvaluationIntervalType) {
                    case 1:
                        reportFormat = 'MMMM';
                        break;
                }

                angular.forEach(data, function (value, key) {
                    var date = moment(value.IntervalDateFrom);
                    var period = date.format(periodFormat);
                    if (currentPeriod != period) {
                        reports.push({
                            Period: period,
                            Items: []
                        });
                        currentPeriod = period;
                    }

                    value.IntervalDateTo = BasicHelper.getDateTime(value.IntervalDateTo);
                    value.ReportDate = date.format(reportFormat);
                    value.Checked = ReportStatus(value, isSupervisor);
                    reports[reports.length - 1].Items.push(value);
                });

                if (isSupervisor) {
                    $scope.supervisorReports = reports;
                } else {
                    $scope.employeeReports = reports;
                }
            }

            function ReportStatus(report, isSupervisor) {
                isSupervisor = (isSupervisor != undefined && isSupervisor != null) ? isSupervisor : $scope.showSupervisor;
                var status = false;
                if (isSupervisor) {
                    status = report.SupervisorConfirmed;
                } else {
                    status = report.EmployeeConfirmed;
                }
                return status;
            }

            function ReportSwipe(report) {
                var status = ReportStatus(report);
                
                $http.post('/EmployeeEvaluation/SwipeEvaluation/' + $stateParams.token, {
                    EmployeeEvaluationIntervalId: report.EmployeeEvaluationIntervalId,
                    Confirm: !status,
                    EmployeeUserId: report.EmployeeUserId
                }).then(function (response) {
                    if (response.data.Report != null) {
                        report.SupervisorConfirmed = response.data.Report.SupervisorConfirmed;
                        report.EmployeeConfirmed = response.data.Report.EmployeeConfirmed;
                        report.EmployeeEvaluationReportStatus = response.data.Report.EmployeeEvaluationReportStatus;
                        report.EmployeeEvaluationReportToken = response.data.Report.EmployeeEvaluationReportToken;
                    }

                    var newStatus = ReportStatus(report);
                    report.Checked = newStatus;

                    if (newStatus == status) {
                        $translate('ERROR.CONTACT').then(function (translation) {
                            toastr.error(translation);
                        });
                    }
                }, function () {
                    $translate('ERROR.CONTACT').then(function (translation) {
                        toastr.error(translation);
                    });
                });
            }

            function ViewReport(report) {
                if (!BasicHelper.isEmptyGuid(report.EmployeeEvaluationReportToken)
                    && report.EmployeeEvaluationReportStatus != 1
                    && report.EmployeeEvaluationReportStatus != 5) {
                    Page.stateGo('employeeEvaluationView', $stateParams.token, report.EmployeeEvaluationReportToken);
                }
            }

            function FilterSelection() {
                var status = null;
                var period = new Date(parseInt($scope.filter.Period));
                period = moment(period).format('YYYY-MM-DD');
                var userGroup = $scope.filter.UserGroup;

                $http.get('/EmployeeEvaluation/GetSupervisorList/' + $stateParams.token + '?status=' + status + '&userGroupId=' + userGroup + '&period=' + period)
                    .then(function (response) {
                        if (response.data != null && response.data.length > 0) {
                            SortReports(response.data, true);
                        } else {
                            SortReports([], true);
                        }
                    });
            }

            function HandleAttachment(event, report) {
                if (report.SupervisorConfirmed) {
                    var buttons = [
                    {
                        text: 'ATTACHMENT.TITLE',
                        label: true
                    }];

                    if (report.HasAttachment) {
                        buttons.push({
                            text: 'ATTACHMENT.OPEN',
                            icon: 'file',
                            onClick: function () {
                                GetAttachment(report);
                            }
                        });
                        buttons.push({
                            text: 'ATTACHMENT.DELETE',
                            icon: 'delete',
                            iconClass: 'red',
                            onClick: function () {
                                RemoveAttachment(report, 'ATTACHMENT.DELETE_DONE');
                            }
                        });
                        buttons.push({
                            text: 'ATTACHMENT.REPLACE',
                            icon: 'upload',
                            disabled: true,
                            mediaUpload: {
                                enabled: true,
                                types: 'all'
                            },
                            onClick: function (data) {
                                if (data.media) {
                                    AddAttachment(report, data.media, 'ATTACHMENT.REPLACE_DONE');
                                }
                            }
                        });
                    } else {
                        buttons.push({
                            text: 'ATTACHMENT.ADD',
                            icon: 'upload',
                            mediaUpload: {
                                enabled: true,
                                types: 'all'
                            },
                            onClick: function (data) {
                                if (data.media) {
                                    AddAttachment(report, data.media, 'ATTACHMENT.ADD_DONE');
                                }
                            }
                        });
                    }

                    // Show actionSheet
                    var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, { name: 'employee-evaluation'});
                    actionSheet.show();
                }
            }

            function GetAttachment(report) {
                var media = report.AttachmentMediaId;
                if (media != null) {
                    if (media.MediaType == 1 || media.MediaType == 2) {
                        // Show in viewer
                        AttachmentsViewerService.openViewerWithMedia([media]);
                    } else {
                        // Download file
                        window.location.href = media.DownloadUrl;
                    }
                }
            }

            function AddAttachment(report, media, message) {
                $http.post('/EmployeeEvaluation/ChangeAttachment/' + report.EmployeeEvaluationReportToken + '?mediaId=' + media.MediaId).then(function () {
                    report.AttachmentMediaId = media;
                    report.HasAttachment = true;

                    $translate(message).then(function (translation) {
                        toastr.success(translation);
                    });
                });
            }

            function RemoveAttachment(report, message) {
                $http.post('/EmployeeEvaluation/ChangeAttachment/' + report.EmployeeEvaluationReportToken + '?mediaId=null').then(function () {
                    report.HasAttachment = false;

                    $translate(message).then(function (translation) {
                        toastr.success(translation);
                    });
                });
            }

            function ShowSupervisorView(value) {
                $timeout(function () {
                    $scope.showSupervisor = value;
                    $scope.typeSelected = true;
                }, 200);

                Page.showBackButtonFunction(ShowTypeSelection);
            }

            function ShowTypeSelection() {
                $scope.typeSelected = false;
                SetBackButton();
            }

            // Bindings
            $scope.BasicHelper = BasicHelper;
            $scope.ReportStatus = ReportStatus;
            $scope.ReportSwipe = ReportSwipe;
            $scope.ViewReport = ViewReport;
            $scope.FilterSelection = FilterSelection;
            $scope.HandleAttachment = HandleAttachment;
            $scope.ShowSupervisorView = ShowSupervisorView;
        }
    ]);
})();