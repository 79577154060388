import "array-flat-polyfill";

(function () {
    'use strict';
    var app = angular.module('App', ['App.Basics', 'App.Filters', 'App.Elements', 'ui.router', 'ngAnimate',
        'ngCookies', 'angular.filter', 'ngTouch', 'infinite-scroll', 'monospaced.elastic', 'ksSwiper', 'ngSanitize',
        'ngAria', 'ngFileUpload', 'afkl.lazyImage', 'cfp.loadingBar', 'pascalprecht.translate', 'angularModalService',
        'LocalStorageModule', 'whimsicalRipple', 'hmTouchEvents', 'zendeskWidget', 'ui.mention', 'Relesys.Ui',
        'rzSlider', 'powerbi', 'ui.scroll', 'App.Common', 'App.GlobalSearch', 'App.Catalog', 'App.Social', 'App.Training', 'App.Survey', 'ng-sortable']);

    app.run(['$rootScope', '$location', '$templateCache', '$translate', '$animate', '$timeout',
        '$injector', 'Page', 'Menu', 'ToastFactory', 'WalkthroughFactory', 'events', 'NativeFactory',
        'BasicHelper', 'TrackingService', 'LazyInitWrapperService', 'GlobalSearchService',
        'CatalogSearchService',
        function ($rootScope, $location, $templateCache, $translate, $animate, $timeout, $injector,
                  Page, Menu, ToastFactory, WalkthroughFactory, events, NativeFactory,
                  BasicHelper, TrackingService, LazyInitWrapperService, GlobalSearchService,
                  CatalogSearchService) {

            const matchAll = require('string.prototype.matchall')
            matchAll.shim();

            // Native Init
            NativeFactory.init(function () {
                NativeFactory.visual.useDefaultStatusBar();
            });

            // For showing pages and forms
            $rootScope.pageLoads = 0;
            $rootScope.ShowFormSubmit = false;

            $rootScope.HaveInfoButton = false;

            $rootScope.isRTL = angular.element('html').attr('dir') === 'rtl';

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                Page.setNextState(toState.name);
                $rootScope.$broadcast('ClosePopups', toParams.data?.skipBackNavigation);

                // Conditional redirect
                if (toState.redirectToIf) {
                    var redirectToIf = $injector.invoke(toState.redirectToIf, this, {
                        toStateParams: toParams,
                        toState: toState
                    })
                }

                if (redirectToIf) {
                    event.preventDefault();
                    Page.stateGo(redirectToIf);
                    $timeout(function () {
                        Page.stopLoading()
                    });
                }

                // Redirect
                if (toState.redirectTo) {
                    event.preventDefault();
                    Page.stateGo(toState.redirectTo, toParams.token, toParams.extra);
                }

                // Reset
                ToastFactory.clear();

                if (Page.getMenuState()) {
                    Page.toggleMenu(false);
                }

                if (WalkthroughFactory.isChecked()) {
                    Page.startLoading();
                }

                // Clear cache
                if (typeof fromState !== 'undefined') {
                    $templateCache.remove(fromState.templateUrl);
                }

                if ($rootScope.pageLoads === 0) {
                    Page.nextDirection('enter');
                }

                // Prevent on first page load and login page transitions
                if (toState.name === 'login') {
                    Page.nextDirection('enter');
                    Page.cleanNavigationHistory();
                }

                $rootScope.pageLoads++;

                // Clear Direction after animation is done
                var $elements = $('.base-view');
                if ($elements.length > 0) {
                    $elements.each(function () {
                        if ($(this).data('transition-tracked') !== true) {
                            $(this).data('transition-tracked', true);
                            var curElement = angular.element(this);
                            $animate.on('leave', angular.element(this), function (element, phase) {
                                if (phase === 'close' && $(element).data('transition-tracked')) {
                                    $animate.off('leave', element);
                                    ClearDirection();
                                }
                            });
                        }
                    });
                }
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                if (toState.name && toState.name !== 'pin') {
                    Page.resetStateAfterPin();
                }
                // determine if it's a new state
                var isNewState = (fromState.name !== toState.name);
                if (!isNewState) {
                    try {
                        // do a deep compare of the state params to see if they are the same or not
                        isNewState = !_.isEqual(fromParams, toParams);
                    } catch (err) {
                        // something went wrong so we just assume a new state regardless to not cause further issues
                        isNewState = true;
                    }
                }

                if (isNewState) { 
                    if (toState.trackPageView !== false) {
                    // track state change event
                    TrackingService.trackUrlPageView($location.path());
                }

                const previousState = {
                    state: fromState.name,
                    token: fromParams.token,
                    extra: fromParams.extra,
                    params: fromParams.data,
                    user: fromParams.user,
                    ...Page.getRestParams(fromParams)
                }

                if (!_.isEqual(previousState, Page.getBackHistoryState()) && fromState.name !== 'link' && !toParams.data?.skipHistory) {
                    Page.addHistoryState(previousState);
                }

                if (toParams.data?.removeHistory || $rootScope.isBrowserBack) {
                    Page.removeLastHistoryState();
                }
            }

            const globalSearchResultsEntities = ['catalogCategory', 'contentLibrary', 'contentItem', 'itemDetails', 'socialPost'];

            if (!globalSearchResultsEntities.includes(fromState.name) && toState.name === 'search' && GlobalSearchService.getSearchState().searchText) {
                GlobalSearchService.cleanSearchState();
            }

                if (['catalog', 'catalogCategory', 'itemDetails'].includes(fromState.name) && !fromParams.data?.showSearch && !toParams.data?.showSearch) {
                CatalogSearchService.cleanSearchState();
            }

                Page.stopLoading();
                $rootScope.prevState = fromState;
                $rootScope.prevStateParams = fromParams;

                // Set current menu item
                Menu.setCurrentMenu(toState.name, toParams.token, toParams.extra);
                Page.clearBodyClasses();
                Page.showMenuButton(true);
                
                if (toState.name === 'home') {
                    Page.clearBackButton();
                }

                angular.element('html, body').scrollTop(0);

                $rootScope.IsFrontPage = false;
                $rootScope.ShowFormSubmit = false;
                $rootScope.$broadcast(events.PAGE_CHANGED);
                $rootScope.HaveInfoButton = false;
                
                checkIfFixedLayout(toState.name);

                bodyScrollLock.clearAllBodyScrollLocks();
            });

            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                var errorName = '';

                Page.showMenuButton(true);
                Page.stopLoading();

                switch (toState.name) {
                    case 'socialPost':
                        errorName = 'ERROR.SOCIAL.POST_NOT_FOUND';
                        break;
                    case 'contentItem':
                        errorName = 'ERROR.CONTENT.ITEM_NOT_FOUND';
                        break;
                    case 'survey':
                        errorName = 'ERROR.SURVEY.ITEM_NOT_FOUND';
                        break;
                    case 'serviceForm':
                        if (toParams.extra) {
                            errorName = 'ERROR.SERVICE_FORM.ITEM_NOT_FOUND';
                        } else {
                            errorName = 'ERROR.SERVICE_FORM.CANT_CREATE_REPORT';
                        }
                        break;
                    case 'taskitem':
                        errorName = 'ERROR.TASK_MANAGEMENT.ITEM_NOT_FOUND';
                        break;
                    case 'chat':
                        errorName = 'CHAT.ACCESS_ERROR';
                        break;
                    default:
                        errorName = 'ERROR.PAGE_NOT_LOADED';
                }

                $translate(errorName).then(function (translation) {
                    const pathStatesReleventToBookmarks = ['contentItem', 'contentLibrary', 'socialPost'];

                    if (pathStatesReleventToBookmarks.includes(toState.name)
                        && toParams.data 
                        && toParams.data.BookmarkId) {
                        return;
                    }
                    ToastFactory.error(translation);
                });

                $rootScope.IsFrontPage = false;
                $rootScope.ShowFormSubmit = false;
                $rootScope.HaveInfoButton = false;

                checkIfFixedLayout(toState.name);
                bodyScrollLock.clearAllBodyScrollLocks();

                console.log("Failed to load state: ", { 
                    toState: toState?.name, 
                    toParams: structuredClone(toParams), 
                    fromState: fromState?.name,
                    fromParams: structuredClone(fromParams),
                    error: error 
                });
            });

            $rootScope.$on('$underMaintenance', function () {
                $translate('ERROR.MAINTENANCE').then(function (translation) {
                    ToastFactory.error(translation);
                });
            });

            // Attempt to reload labels if the loading failed
            var translateLoadErrors = [];
            var translateLoadTimeout = null;
            $rootScope.$on('$translateLoadingError', (ev, data) => {
                $timeout.cancel(translateLoadTimeout);

                var loadError = _.find(translateLoadErrors, o => o.language === data.language);
                if (loadError) {
                    if (loadError.count < 5) {
                        loadError.count++;
                        $timeout($translate.refresh, loadError.count * 200);
                    } else if (loadError.count < 10) {
                        loadError.count++;
                        $timeout($translate.refresh, loadError.count * 2000);
                    }
                } else {
                    translateLoadErrors.push({
                        language: data.language,
                        count: 1
                    });
                    $timeout($translate.refresh, 200);
                }
            });

            // Create SafeApply method
            $rootScope.safeApply = function (fn) {
                var phase = this.$root.$$phase;
                if (phase === '$apply' || phase === '$digest') {
                    if (fn && (typeof (fn) === 'function')) {
                        fn();
                    }
                } else {
                    this.$apply(fn);
                }
            };

            // Start listening for postmessage events
            window.addEventListener("message", function (ev) {
                $rootScope.$broadcast(events.POST_MESSAGE, ev);
            });

            // Initial set ScreenSize
            SetScreenSize();
            $(window).on('resize', SetScreenSize);

            document.addEventListener('visibilitychange', function (ev) {
                if (!$rootScope.disableVisibilitychange) {
                    if (document.hidden === false) {
                        $rootScope.$broadcast(events.APP_UNHIDE);
                    } else {
                        // using try-catch as calling code while hiding can cause strange side effects on some systems
                        try {
                            $rootScope.$broadcast(events.APP_HIDE);
                        } catch (err) {
                            console.error(err);
                        }
                    }
                }
            }, false);

            LazyInitWrapperService.initObserver();

            function checkIfFixedLayout(state) {
                switch (state) {
                    case 'calendar':
                        $rootScope.IsStaticLayout = true;
                        break;
                    default:
                        $rootScope.IsStaticLayout = false;
                }
            }

            function ClearDirection() {
                setTimeout(function () {
                    Page.clearDirection();
                }, 300);
            }

            function SetScreenSize() {
                var h = $(document).height();
                var w = $(document).width();

                var stops = [4000, 3000, 2000, 1200, 800, 600, 400, 200, 100];

                var finalHeight = stops[0];
                var finalWidth = stops[0];

                for (var i = 0; i < stops.length; i++) {
                    if (h < stops[i]) {
                        finalHeight = stops[i];
                    }
                    if (w < stops[i]) {
                        finalWidth = stops[i];
                    }
                }

                $rootScope.imageWidth = BasicHelper.getImageVariantBySize(finalWidth);

                $rootScope.screenSize = {
                    height: finalHeight,
                    width: finalWidth
                };
            }
        }
    ]);
})();
