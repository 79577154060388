(() => {
    'use strict';

    angular.module('App.Training')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider
                .state('trainingCourse', {
                    parent: 'trainingSettings',
                    url: '/course/:token/:extra',
                    template: `<training-course-page
                                    modules="ctrl.modules"
                                    course="ctrl.course"
                                    performance-ranges="ctrl.performanceRanges"
                                    module-token="ctrl.moduleToken"
                                    learning-path-token="ctrl.learningPathToken"
                                    class="view-page training-course-{{ctrl.course.CourseToken}}"
                                ></training-course-page>`,
                    resolve: {
                        trainingData: ['$stateParams', 'trainingSettings', 'TrainingService', function ($stateParams, trainingSettings, TrainingService,) {
                            return TrainingService.getCourse({
                                courseToken: $stateParams.token,
                                learningPathToken: $stateParams.extra
                            })
                        }]
                    },
                    params: {
                        backState: null,
                        data: {}
                    },
                    controller: 'TrainingCourseController as ctrl'
                });
        }])
        .controller('TrainingCourseController', ['$scope', '$stateParams', 'trainingData', 'events', 'Page',
            function ($scope, $stateParams, trainingData, events, Page) {
                var ctrl = this;

                ctrl.modules = trainingData.Modules;
                ctrl.course = trainingData.Course;

                ctrl.moduleToken = trainingData.AccountModuleToken;
                ctrl.performanceRanges = trainingData.PerformanceRanges;
                ctrl.learningPathToken = $stateParams.extra;

                Page.setTitle(trainingData.PageTitle);

                Page.showBackButton();

                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateGoBack('training', trainingData.AccountModuleToken);
                });
            }]);
})();