(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('profileSettings', {
                        parent: 'base',
                        url: '/profileSettings',
                        template: '<div class="profile-settings-page">' +
                            '<profile-settings class="view-page narrow">' +
                            '</profile-settings></div>',
                        resolve: {},
                        params: {
                            data: null
                        },
                        controller: 'ProfileSettingsController as ctrl'
                    });
            }
        ])
        .controller('ProfileSettingsController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('PROFILE.SETTINGS.TITLE').then(function (translation) {
                    Page.setTitle(translation);
                });

                Page.showBackButton();
            }]);
})();