(() => {
    'use strict';

    angular
        .module('App.Survey')
        .config(['$stateProvider', ($stateProvider) => {
            $stateProvider
                .state('surveyList', {
                    parent: 'base',
                    url: '/surveys/:token',
                    template: require('./Surveys.html'),
                    controllerAs: 'ctrl',
                    resolve: {
                        surveys: ['$http', '$stateParams', ($http, $stateParams) => {
                            const url = '/Survey/GetSurveys/?accountModuleToken=' + $stateParams.token;
                            return $http.get(url);
                        }]
                    },
                    params: {
                        backState: null
                    },
                    reloadOnSearch: false,
                    controller: 'SurveyListController'
                })
        }])
        .controller('SurveyListController', ['$scope', 'Page', '$stateParams', 'surveys', 'events', 'ResponsiveService', 'SurveyService', 'HeaderButtonsFactory', SurveyListController]);

    function SurveyListController($scope, Page, $stateParams, surveys, events, ResponsiveService, SurveyService, HeaderButtonsFactory) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.changeTab = changeTab;
        ctrl.$onDestroy = destroy;

        ctrl.TABS = {
            'ACTIVE_SURVEYS': 0,
            'EXPIRED_SURVEYS': 1
        }

        ctrl.isDesktop = ResponsiveService.isDesktop();

        function init() {
            ctrl.activeTab = ctrl.TABS.ACTIVE_SURVEYS;
            ctrl.isDesktop && $scope.expiredSurveys.length > 0 && setHeaderButtons(ctrl)
        }

        $scope.activeSurveys = surveys.data.ActiveSurveys;
        $scope.expiredSurveys = surveys.data.ExpiredSurveys;
        $scope.moduleToken = surveys.data.accountModuleToken;
        Page.setTitle($scope.title || surveys.data.PageTitle);

        // Manage the back state
        Page.showBackButton();

        function changeTab(tab) {
            ctrl.activeTab = tab;

            if (ctrl.isDesktop) {
                Page.setSubTitle(SurveyService.TAB_TRANSLATIONS[tab === ctrl.TABS.EXPIRED_SURVEYS ? 'EXPIRED' : 'ACTIVE']);
            }
        }

        function setHeaderButtons(ctrl) {
            Page.setSubTitle(SurveyService.TAB_TRANSLATIONS[$stateParams.tab === ctrl.TABS.EXPIRED_SURVEYS ? 'EXPIRED' : 'ACTIVE']);

            HeaderButtonsFactory.createButtonsList(() => getHeaderButtons(ctrl))

            function getHeaderButtons(ctrl) {
                return [{
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    place: 'title',
                    items: [
                        {
                            title: SurveyService.TAB_TRANSLATIONS.ACTIVE,
                            onClick: () => {
                                changeTab(ctrl.TABS.ACTIVE_SURVEYS)
                            },
                            active: ctrl.activeTab === ctrl.TABS.ACTIVE_SURVEYS
                        },
                        {
                            title: SurveyService.TAB_TRANSLATIONS.EXPIRED,
                            onClick: () => {
                                changeTab(ctrl.TABS.EXPIRED_SURVEYS)
                            },
                            active: ctrl.activeTab === ctrl.TABS.EXPIRED_SURVEYS
                        }
                    ]
                }]
            }
        }

        // Listen for events
        $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());

        function destroy() {
            if (ctrl.isDesktop) {
                HeaderButtonsFactory.resetButtonsList()
            }
        }
    }
})();