(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonPageV2', {
            template: require('./LessonPageV2Component.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$stateParams', 'LESSON_PAGE_VIEW', 'LESSON_STATE', 'Page', 'ToastFactory',
                'LessonPageService', LessonPageV2Controller],
        });

    function LessonPageV2Controller($timeout, $stateParams, LESSON_PAGE_VIEW, LESSON_STATE, Page, ToastFactory,
                                    LessonPageService) {
        const ctrl = this;
        let dataToSubmit;

        ctrl.LESSON_PAGE_VIEW = LESSON_PAGE_VIEW;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.goToQuestions = goToQuestions;
        ctrl.goToQuestionResults = goToQuestionResults
        ctrl.finishLesson = finishLesson;
        ctrl.submitAnswers = submitAnswers;
        ctrl.goToNextLesson = goToNextLesson;
        ctrl.finishEvaluation = finishEvaluation;
        ctrl.goToEvaluation = goToEvaluation;
        ctrl.redo = redo;

        function init() {
            $timeout(() => {
                document.querySelector('.base-view').classList.add('lesson-page-v2');
            })

            ctrl.courseToken = $stateParams.extra;
            ctrl.learningPathToken = $stateParams.learningPathToken;
            ctrl.isVoluntary = !ctrl.courseToken?.length;

            LessonPageService
                .getLesson({
                    lessonToken: $stateParams.token,
                    courseToken: ctrl.courseToken,
                    learningPathToken: ctrl.learningPathToken
                })
                .then(initLesson)
                .catch(() => {
                    ToastFactory.warningTranslated('TRAINING.LESSON.NOT_ALLOWED');
                    Page.stateGoBack();
                });


            function initLesson(resp) {
                ctrl.isDataLoaded = true;
                ctrl.CURRENT_VIEW = LESSON_PAGE_VIEW.INTRODUCTION;

                ctrl.lessonData = resp;
                ctrl.lesson = resp.Lesson;

                ctrl.emptyLesson = ctrl.lesson.QuestionList.length === 0;
                ctrl.isLastLesson = ctrl.lessonData.LessonIndex + 1 === ctrl.lessonData.CourseLessonsCount;

                dataToSubmit = {
                    CourseToken: ctrl.courseToken,
                    CourseLessonToken: ctrl.lesson.CourseLessonToken,
                    LearningPathToken: ctrl.learningPathToken,
                    Questions: []
                };

                Page.setTitle(ctrl.lesson.Title, ctrl.lessonData.LessonIndex + 1 + '/' + ctrl.lessonData.CourseLessonsCount);
                Page.showBackButtonFunction(() => {
                    if (ctrl.CURRENT_VIEW === LESSON_PAGE_VIEW.QUESTIONS) {
                        if (ctrl.questionToPreview) {
                            ctrl.CURRENT_VIEW = LESSON_PAGE_VIEW.EVALUATION;
                        } else {
                            if (!ctrl.lesson.Expired) {
                                LessonPageService.showWarningPopup().then(pageGoBack)
                            } else {
                                pageGoBack();
                            }
                        }
                    } else {
                        pageGoBack();
                    }
                })
            }

            function pageGoBack() {
                if (Page.hasHistory()) {
                    Page.stateGoBack()
                } else {
                    Page.stateGoBack('trainingCourseModuleMandatory', ctrl.lessonData.CourseModuleToken, ctrl.courseToken, null, true, {
                        learningPathToken: ctrl.learningPathToken
                    });
                }
            }
        }

        function goToQuestions() {
            ctrl.CURRENT_VIEW = LESSON_PAGE_VIEW.QUESTIONS;
        }

        function goToQuestionResults(questionId) {
            ctrl.questionToPreview = questionId;
            ctrl.CURRENT_VIEW = LESSON_PAGE_VIEW.QUESTIONS;
        }

        function finishLesson() {
            submitData(dataToSubmit)
        }

        function submitAnswers(answers) {
            dataToSubmit.Questions = answers;
            submitData(dataToSubmit)
        }

        function goToNextLesson() {
            const stateParams = LessonPageService.getRouteToNextLesson(ctrl);
            Page.stateGo(...stateParams);
        }

        function hideTestimonials() {
            ctrl.answerData.GiveTestimonial = false;
            proceedToNextScreen();
        }

        function finishEvaluation() {
            ctrl.answerData ?
                proceedToNextScreen() :
                goToNextLesson();
        }

        function goToEvaluation() {
            if (!ctrl.answerData) {
                ctrl.lesson = LessonPageService.questionsAddAnswersAndFeedback(
                    ctrl.lessonData.Lesson, ctrl.lessonData.Answers, ctrl.lessonData.AnswerFeedback, ctrl.lessonData.QuestionsAreCorrect
                );
            }
            ctrl.CURRENT_VIEW = LESSON_PAGE_VIEW.EVALUATION;
        }

        function redo() {
            ctrl.lesson.Action = 1;
            ctrl.lesson.State = LESSON_STATE.NOT_STARTED;
        }

        function submitData(dataToSubmit) {
            if (!ctrl.loading) {
                ctrl.loading = true;
                return LessonPageService.submitAnswer(dataToSubmit, ctrl.lesson)
                    .then(answerData => {
                        ctrl.answerData = answerData;
                        ctrl.lesson = answerData.Lesson;
                        ctrl.badges = answerData.NewUserCourseBadges;
                        if (ctrl.emptyLesson) {
                            proceedToNextScreen()
                        } else {
                            goToEvaluation();
                        }
                    })
                    .catch(() => {
                        ToastFactory.warningTranslated('TRAINING.LESSON.NOT_ALLOWED');
                        Page.stateGoBack();
                    })
                    .finally(() => {
                        ctrl.loading = false;
                    });
            }
        }

        function proceedToNextScreen() {
            if (ctrl.badges?.length > 0) {
                LessonPageService.openBadgePopup(ctrl.badges[0], closePopup);
            } else if (ctrl.answerData.GiveTestimonial) {
                LessonPageService.openTestimonialsPopup({
                    courseToken: ctrl.courseToken,
                    onHideTestimonials: hideTestimonials,
                    commentRequiredScore: ctrl.answerData.TestimonialsCommentRequiredScore,
                    isLoading: ctrl.loading
                });
            } else {
                const stateParams = LessonPageService.getRedirectRoute(ctrl);

                Page.stateGo(...stateParams);
            }
        }

        function closePopup() {
            ctrl.badges.shift();
            proceedToNextScreen();
        }

        function destroy() {
            document.querySelector('.base-view').classList.remove('lesson-page-v2');
        }
    }
})();