(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('validityBadge', {
            template: require('./ValidityBadgeComponent.html'),
            controllerAs: 'ctrl',
            controller: [ValidityBadgeController],
            bindings: {
                date: '<',
                validityType: '<',
            }
        });
    
    function ValidityBadgeController() {
        const ctrl = this;
        ctrl.$onChanges = function (changes) {
            if (changes.validityType) {
                ctrl.validityType = changes.validityType.currentValue;
            }
            if (changes.date) {
                ctrl.date = changes.date.currentValue;
            }
        };

        ctrl.getIconClass = function () {
            switch (ctrl.validityType) {
                case 'TRAINING.BADGE.NOT_OBTAINED':
                    return 'lock';
                case 'TRAINING.BADGE.OBTAINED_ON':
                    return 'check-circle';
                case 'TRAINING.BADGE.EXPIRED_ON':
                    return 'exclamation-circle';
                case 'TRAINING.BADGE.VALID_UNTIL':
                    return 'check-circle';
                case 'TRAINING.BADGE.DEACTIVATED':
                    return 'exclamation-circle';
                default:
                    return 'check-circle';
            }
        }

        ctrl.getBackgroundColorClass = function () {
            switch (ctrl.validityType) {
                case 'TRAINING.BADGE.NOT_OBTAINED':
                    return 'bg-not-obtained';
                case 'TRAINING.BADGE.OBTAINED':
                    return 'bg-obtained';
                case 'TRAINING.BADGE.EXPIRED_ON':
                    return 'bg-expired';
                case 'TRAINING.BADGE.VALID_UNTIL':
                    return 'bg-valid-until';
                case 'TRAINING.BADGE.DEACTIVATED':
                    return 'bg-deactivated';
            }
        }
    }
    
})();