(() => {
    'use strict';
    const app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('workMood', {
                    parent: 'base',
                    url: '/workmood/:token',
                    templateUrl: '/WorkMood/Index',
                    resolve: {
                        workMoodInfo: ['$http', '$stateParams', 'base', function ($http, $stateParams, base) {
                            if (base) {
                                return $http.get('/WorkMood/GetInfo/' + $stateParams.token);
                            }
                        }]
                    },
                    controller: 'WorkMoodController as WorkMoodCtrl'
                });
        }
    ]);

    app.controller('WorkMoodController', ['Page', '$scope', '$element', '$http', '$state', '$stateParams', 'toastr', '$translate', 'workMoodInfo', 'Menu', 'TimeLocale', 'ChartHelper', 'BasicHelper', 'events', '$timeout',
        function (Page, $scope, $element, $http, $state, $stateParams, toastr, $translate, workMoodInfo, Menu, TimeLocale, ChartHelper, BasicHelper, events, $timeout) {
            const ctrl = this;
            ctrl.Page = Page;

            // Init
            Page.setTitle(workMoodInfo.data.pageTitle);
            Page.showBackButton();
            
            $scope.loading = false;
            $scope.Moods = workMoodInfo.data.settings.moods;
            $scope.color = workMoodInfo.data.settings.chartColor;
            $scope.settings = workMoodInfo.data.settings;

            // Set graph types
            var graphTypes = {
                area: 'areaspline',
                bar: 'column',
                line: 'spline'
            };
            $scope.graphType = graphTypes[workMoodInfo.data.settings.chartType];

            if (!$scope.graphType || !$scope.graphType.length || $scope.graphType === 'undefined') {
                $scope.graphType = graphTypes[0];
            }

            // Translations
            $scope.Translations = {};
            BasicHelper.getSettingString(workMoodInfo.data.settings.introText, 'WORKMOOD.INTRO').then(function (text) {
                $scope.Translations.IntroText = text;
            });
            BasicHelper.getSettingString(workMoodInfo.data.settings.pickedText, 'WORKMOOD.PICKED').then(function (text) {
                $scope.Translations.PickedText = text;
            });
            BasicHelper.getSettingString(workMoodInfo.data.settings.lastWeekText, 'WORKMOOD.LASTWEEK').then(function (text) {
                $scope.Translations.LastWeekText = text;
            });

            function Init() {
                angular.forEach($scope.Moods, function (item) {
                    item.Show = true;
                    item.Selected = false;
                    item.Image = '/Content/Graphics/WorkMood/' + item.workMoodTypeId + '.png'
                });

                if (workMoodInfo.data.workMood === null) {
                    $scope.showIntro = true;
                    $scope.showPicked = false;
                } else {
                    // Set correct as selected
                    angular.forEach($scope.Moods, function (mood) {
                        if (mood.workMoodTypeId === workMoodInfo.data.workMood.workMoodTypeId)
                            $scope.PickMood(mood, true);
                    });

                    $scope.showIntro = false;
                    $scope.showPicked = true;
                }

                // Show Graph
                ShowGraph(workMoodInfo.data.workMoodStats);

                $scope.showInfoText = workMoodInfo.data.workMoodStats.some(stat => stat.averageScore === 0);

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
            }

            function ShowGraph(data) {
                // Create chart
                var colors = Highcharts.getOptions().colors;
                var color = colors[0];
                if ($scope.color && $scope.color.length) {
                    color = $scope.color
                };
                var scoreData = [];
                var labels = [];

                // Get the lowest score to show
                var lowestItem = _.minBy(data, function (o) {
                    return o.averageScore;
                });
                var lowest = 0;
                if (lowestItem) {
                    lowest = _.floor(lowestItem.averageScore);
                }

                for (var i = 0; i < data.length; i++) {
                    var newLabel = TimeLocale.dateTimeFormat(data[i].date, 'dd');
                    var newLongLabel = TimeLocale.dateTimeFormat(data[i].date, 'dddd');

                    newLabel = newLabel.charAt(0).toUpperCase() + newLabel.slice(1)
                    newLongLabel = newLongLabel.charAt(0).toUpperCase() + newLongLabel.slice(1);

                    labels.push(newLabel);
                    scoreData.push({
                        name: newLabel,
                        y: data[i].averageScore,
                        longLabel: newLongLabel
                    });
                }

                var yAxis = {
                    title: {text: null},
                    labels: {
                        enabled: $scope.settings.showYAxis
                    },
                    min: lowest,
                    max: $scope.Moods.length,
                    tickInterval: 1,
                    lineWidth: 0,
                    gridLineWidth: 0,
                    minorGridLineWidth: 0,
                    minPadding: 0,
                    maxPadding: 0,
                    tickWidth: 0
                };

                var xAxis = {
                    title: {
                        text: null
                    },
                    minPadding: 0,
                    maxPadding: 0,
                    labels: {
                        padding: 5,
                        useHTML: true,
                        formatter: function () {
                            var label = labels[this.value];
                            if (label && label.length) {
                                return "&nbsp;" + label + "&nbsp;";
                            }
                            return null;
                        }
                    },
                    lineColor: 'transparent',
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTickLength: 0,
                    tickLength: 0,
                    tickInterval: 1
                };

                if ($scope.settings.showYAxis) {
                    xAxis = _.assign(xAxis, {
                        minPadding: 0.08,
                        maxPadding: 0.08
                    });
                    yAxis = _.assign(yAxis, {
                        offset: -30
                    });
                }

                var chartElement = $element.find('.workmood-chart')[0];
                $timeout(function () {
                    Highcharts.chart(chartElement, {
                        chart: {
                            type: $scope.graphType,
                            height: 200,
                            width: null,
                            marginLeft: 0,
                            marginRight: 0,
                            spacingLeft: 0,
                            spacingRight: 0,
                            backgroundColor: 'transparent'
                        },
                        labels: {},
                        title: {text: ''},
                        credits: {enabled: false},
                        tooltip: {
                            formatter: function () {
                                return this.point.longLabel + '<br ><b>' + this.point.y.toFixed(2) + '</b>';
                            }
                        },
                        xAxis: xAxis,
                        yAxis: yAxis,
                        legend: {
                            enabled: false
                        },
                        plotOptions: {
                            series: {
                                marker: {
                                    enabled: $scope.settings.showPointMarker
                                },
                                dataLabels: {
                                    enabled: $scope.settings.showPointLabel
                                }
                            },
                            column: {
                                borderRadius: 4
                            }
                        },
                        series: [{
                            name: 'Avg. Score',
                            data: scoreData,
                            pointPlacement: 0,
                            pointPadding: -0.2,
                            color: color
                        }]
                    });
                });
            }

            $scope.PickMood = function (mood, noLoading) {
                if (!$scope.showPicked && !$scope.loading) {
                    $scope.loading = true;
                    angular.forEach($scope.Moods, function (item) {
                        if (item.workMoodTypeId !== mood.workMoodTypeId)
                            item.Show = false;
                        else {
                            item.Selected = true;
                            $scope.showPicked = true;
                            $scope.showIntro = false;

                            if (!noLoading) {
                                // Send to server
                                $http.post('/WorkMood/PostMood', {
                                    workMoodTypeId: mood.workMoodTypeId
                                }).then(function (response) {
                                    $scope.loading = false;
                                    Menu.updateActivity(0, $state.current.name, $state.params.token, $state.params.extra);
                                    ShowGraph(response.data.workMoodStats);
                                }, function () {
                                    // Reset
                                    $scope.loading = false;
                                    $translate('ERROR.GENERAL').then(function (translation) {
                                        toastr.error(translation);
                                    });

                                    Init();
                                });
                            } else {
                                $scope.loading = false;
                            }
                        }
                    });
                }
            };

            // Run Init
            Init();
        }
    ]);
})();