(() => {
    'use strict';

    angular
        .module('App.Training')
        .factory('LessonPageService', ['$http', '$stateParams', '$filter', 'Page', 'Menu', 'ToastFactory', 'ConfirmPopupService',
            'ActionSheetCustomService', 'ActionPopupService', 'HeaderButtonsFactory', 'ResponsiveService',
            'TRAINING_QUESTION_TYPE', 'TrainingService', LessonPageService]);

    function LessonPageService($http, $stateParams, $filter, Page, Menu, ToastFactory, ConfirmPopupService, ActionSheetCustomService,
                               ActionPopupService, HeaderButtonsFactory, ResponsiveService, TRAINING_QUESTION_TYPE,
                               TrainingService) {
        return {
            getLesson: getLesson,
            selectAnswer: selectAnswer,
            resetAnswer: resetAnswer,
            isAllAnswered: isAllAnswered,
            questionsAddAnswersAndFeedback: questionsAddAnswersAndFeedback,
            askForRedo: askForRedo,
            submitAnswer: submitAnswer,
            submitTestimonial: submitTestimonial,
            showWarningPopup: showWarningPopup,
            getRedirectRoute: getRedirectRoute,
            getRouteToNextLesson: getRouteToNextLesson,
            openBadgePopup: openBadgePopup,
            openTestimonialsPopup: openTestimonialsPopup,
            setTitleHelp: setTitleHelp,
            removeTitleHelp: removeTitleHelp
        }

        function getLesson({lessonToken, courseToken, learningPathToken}) {
            const restParams = Page.generateRestParamsString({courseToken, learningPathToken});
            const url = `/${TrainingService.getBaseUrl()}/CourseLesson/Get/${lessonToken}/${restParams}`

            return $http
                .get(url)
                .then(resp => resp.data)
        }

        function resetAnswer(question, questionList) {
            question.AnswerOptionList.forEach(answer => {
                answer.selected = false;
            });

            return prepareData(questionList);
        }

        function selectAnswer(answer, question, questionList) {
            if (!question.IsMultiple) {
                question.AnswerOptionList.forEach(answer => {
                    answer.selected = false;
                });
            }
            answer.selected = !answer.selected;

            return prepareData(questionList);
        }

        function prepareData(questionList) {
            const questions = [];

            questionList.forEach(question => {
                const genQuestion = {
                    CourseQuestionId: question.CourseQuestionId
                };
                question.AnswerOptionList.forEach(answer => {
                    if (question.AnswerOptionList.length && !genQuestion.AnswerOptions) {
                        genQuestion.AnswerOptions = [];
                    }
                    if (answer.selected) {
                        genQuestion.AnswerOptions.push({CourseQuestionAnswerOptionId: answer.CourseQuestionAnswerOptionId});
                    }
                });

                questions.push(genQuestion);
            });

            return questions;
        }

        function isAllAnswered(questions) {
            return questions && !questions.some(question => question.AnswerOptions?.length === 0)
        }

        function questionsAddAnswersAndFeedback(lesson, answers, feedbackList, questionsAreCorrect) {
            lesson.QuestionList.forEach(question => {

                if (questionsAreCorrect) {
                    const selectedQuestion = questionsAreCorrect
                        .find(q => q.CourseQuestionId === question.CourseQuestionId);
                    question.IsCorrect = selectedQuestion?.IsCorrect;
                    question.AutoCreatedAfterTimeout = selectedQuestion?.AutoCreatedAfterTimeout;
                }

                question.feedback = feedbackList.find(item => {
                    return item.CourseQuestionId === question.CourseQuestionId &&
                        item.CourseQuestionAnswerOptionId === 0
                });

                answers.forEach(answer => {
                    const option = question.AnswerOptionList.find(answerOption => {
                        return answerOption.CourseQuestionAnswerOptionId === answer.CourseQuestionAnswerOptionId
                    });

                    if (option) {
                        option.selected = true;
                        option.feedback = feedbackList.find(item => {
                            return item.CourseQuestionAnswerOptionId === option.CourseQuestionAnswerOptionId
                        });
                    }
                })
            });

            return lesson;
        }

        function askForRedo(onClose) {
            return ConfirmPopupService.open({
                title: $filter('translate')('TRAINING.LESSON.REDO_CONFIRMATION_TITLE'),
                message: $filter('translate')('TRAINING.LESSON.REDO_CONFIRMATION'),
                yesText: $filter('translate')('TRAINING.LESSON.REDO'),
                noText: $filter('translate')('TRAINING.LESSON.VIEW'),
                onClose: onClose
            })
        }

        function submitAnswer(dataToSubmit, lesson) {
            return $http.post(`/${TrainingService.getBaseUrl()}/CourseLesson/Answer`, dataToSubmit)
                .then(resp => {
                    const data = resp.data;

                    data.Lesson = data.Lesson || {};
                    data.Lesson.QuestionList = lesson.QuestionList;
                    data.Lesson = answersAddFeedback(data.Lesson, data.AnswerFeedback, data.QuestionsAreCorrect)

                    if (data.CourseStateId === 3 && data.SubtractBadge > 0) {
                        Menu.removeActivity(data.SubtractBadge, "training", data.AccountModuleToken, null);
                        Menu.removeLandingBadge(data.SubtractBadge, data.AccountModuleId, 77, null);
                    }
                    return data;
                })
                .catch(resp => {
                    if (resp.status === 403) {
                        ToastFactory.warning($filter('translate')('TRAINING.ERROR.CANT_ANSWER'));
                    } else {
                        ToastFactory.error($filter('translate')('ERROR.GENERAL'));
                    }
                })
        }

        function answersAddFeedback(lesson, feedbackList, questionsAreCorrect) {
            lesson.QuestionList.forEach(question => {
                const answers = question.AnswerOptionList.filter(answerOption => answerOption.selected);

                if (questionsAreCorrect) {
                    const selectedQuestion = questionsAreCorrect
                        .find(q => q.CourseQuestionId === question.CourseQuestionId);
                    question.IsCorrect = selectedQuestion?.IsCorrect;
                    question.AutoCreatedAfterTimeout = selectedQuestion?.AutoCreatedAfterTimeout;
                }

                question.feedback = feedbackList.find(item => {
                    return item.CourseQuestionId === question.CourseQuestionId &&
                        item.CourseQuestionAnswerOptionId === 0
                });

                answers.forEach(option => {
                    option.feedback = feedbackList.find(feedbackItem => {
                        return feedbackItem.CourseQuestionAnswerOptionId === option.CourseQuestionAnswerOptionId
                    });
                })
            });

            return lesson;
        }

        function submitTestimonial(testimonial) {
            return $http.post(`/${TrainingService.getBaseUrl()}/CourseLesson/AnswerTestimonial`, testimonial)
                .then(resp => resp.data)
                .catch(() => {
                    ToastFactory.errorTranslated();
                });
        }

        function showWarningPopup() {
            return ConfirmPopupService.open({
                title: $filter('translate')('TRAINING.LOSE_PROGRESS_TITLE'),
                message: $filter('translate')('TRAINING.LOSE_PROGRESS_CONFIRMATION'),
                yesText: $filter('translate')('YES'),
                noText: $filter('translate')('NO')
            })
        }

        function getRedirectRoute(ctrl) {
            const {AccountModuleToken, CourseModuleToken, NextLessonToken} = ctrl.lessonData;
            const {
                LearningPathStateId, PhaseStateId, CourseStateId, ModuleStateId, LessonStateId,
                NextLessonCanBeTaken, PhasesOverviewEnabled, TrainingPhaseToken
            } = ctrl.answerData;

            switch (true) {
                case NextLessonCanBeTaken:
                    return ['trainingLesson', NextLessonToken, ctrl.courseToken, null, true, {learningPathToken: ctrl.learningPathToken}];
                case LearningPathStateId === 3:
                case PhaseStateId === 3:
                    return [ctrl.isVoluntary ? 'trainingVoluntary' : 'trainingMy', AccountModuleToken];
                case CourseStateId === 3:
                    if (ctrl.isVoluntary) {
                        return ['trainingVoluntary', AccountModuleToken]
                    } else if (PhasesOverviewEnabled) {
                        return TrainingService.isNewTraining() ?
                            ctrl.learningPathToken ?
                                ['learningPath', ctrl.learningPathToken] :
                                ['trainingMy', AccountModuleToken, null, null,  true, {activeTab: 'courses'}] :
                            ['trainingPhase', TrainingPhaseToken]
                    } else {
                        return ctrl.learningPathToken ?
                            ['trainingMy', AccountModuleToken] :
                            ['trainingMy', AccountModuleToken, null, null, true, {activeTab: 'courses'}]
                    }
                case ModuleStateId === 3:
                    return ctrl.isVoluntary ? ['trainingVoluntary', AccountModuleToken] : ['trainingCourse', ctrl.courseToken, ctrl.learningPathToken]
                case LessonStateId === 3:
                    return ['trainingCourseModuleMandatory', CourseModuleToken, ctrl.courseToken, null, true, {learningPathToken: ctrl.learningPathToken}]
            }
        }

        function getRouteToNextLesson(ctrl) {
            const {AccountModuleToken, NextLessonToken, NextLessonCanBeTaken, CourseModuleToken} = ctrl.lessonData;

            if (ctrl.isLastLesson) {
                return ctrl.isVoluntary ?
                    ['trainingVoluntary', AccountModuleToken] :
                    ['trainingCourse', ctrl.courseToken, ctrl.learningPathToken];
            } else if (NextLessonCanBeTaken) {
                return ['trainingLesson', NextLessonToken, ctrl.courseToken, null, true, {learningPathToken: ctrl.learningPathToken}];
            } else {
                return ['trainingCourseModuleMandatory', CourseModuleToken, ctrl.courseToken, null, true, {learningPathToken: ctrl.learningPathToken}]
            }
        }

        function openBadgePopup(badge, closePopup) {
            ActionPopupService.create(`<badge-popup badge="badge" on-close="onClose"></badge-popup>`, {
                badge: {...badge, BadgeStatus: 1},
                onClose: closePopup
            }, 'lesson-evaluation');
        }

        function openTestimonialsPopup(data) {
            ActionSheetCustomService.create(`<lesson-testimonials class="lesson-inner-page" course-token="courseToken"
                    on-hide-testimonials="onHideTestimonials" comment-required-score="commentRequiredScore" is-loading="isLoading"
                    ></lesson-testimonials>`,
                {...data}
            )
        }

        function setTitleHelp(question) {
            HeaderButtonsFactory.createButtonsList(() => ([{
                place: ResponsiveService.isDesktop() ? 'title' : 'right',
                icon: 'info',
                onClick: () => {
                    openInfo(question)
                }
            }]))

            function openInfo(question) {
                const {title, message} = getInfoLabels(question);

                ConfirmPopupService.open({
                    isAlert: true,
                    yesText: 'DISMISS',
                    title, message
                })
            }

            function getInfoLabels(question) {
                switch (question.QuestionType) {
                    case TRAINING_QUESTION_TYPE.SINGLE_AND_MULTI_SELECT:
                        if (question.IsMultiple) {
                            return {
                                title: 'TRAINING.QUESTION.MULTI_SELECT_INFO_TITLE',
                                message: 'TRAINING.QUESTION.MULTI_SELECT_INFO_BODY'
                            }
                        } else {
                            return {
                                title: 'TRAINING.QUESTION.SINGLE_SELECT_INFO_TITLE',
                                message: 'TRAINING.QUESTION.SINGLE_SELECT_INFO_BODY'
                            }
                        }
                    case TRAINING_QUESTION_TYPE.SWIPE_CARD:
                        return {
                            title: 'TRAINING.QUESTION.SWIPE_CARD_INFO_TITLE',
                            message: 'TRAINING.QUESTION.SWIPE_CARD_INFO_BODY'
                        }
                    case TRAINING_QUESTION_TYPE.IMAGE_AS_AN_ANSWER:
                        return {
                            title: 'TRAINING.QUESTION.IMAGE_ANSWER_INFO_TITLE',
                            message: question.IsMultiple ? 'TRAINING.QUESTION.IMAGES_ANSWER_INFO_BODY' : 'TRAINING.QUESTION.IMAGE_ANSWER_INFO_BODY'
                        }
                }
            }
        }

        function removeTitleHelp() {
            HeaderButtonsFactory.resetButtonsList();
        }
    }
})();